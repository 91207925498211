import axios from "axios";

const dmsConfig = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/dms/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data
});

export const profileImagePresignedUrls = async ({ contentType, accessToken }) => {

    const data = JSON.stringify({
        query: `
            query UserPresignedUrls($contentType: ImageContentType!) {
                userPresignedUrls(contentType: $contentType) {
                    profileImage {
                        presignedThumbnailUpload
                        presignedUploadUrl
                        url
                    }
                }
            }
          `,
        variables: {
            "contentType": contentType ?? null,
        }
    })

    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        return res?.data?.data?.userPresignedUrls.profileImage
    } catch (err) {
        return err
    }

}

export const organizationLogoPresignedUrls = async ({ contentType, accessToken, organizationId }) => {


    const data = JSON.stringify({
        query: `query OrganizationPresignedUrls($organizationId: ID!, $contentType: ImageContentType!) {
            organizationPresignedUrls(organizationId: $organizationId, contentType: $contentType) {
              logoImage {
                presignedUploadUrl
                url
              }
            }
          }`,
        variables: {
            "organizationId": organizationId ?? "self-organization",
            "contentType": contentType ?? "jpegImage",
        }
    })

    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        return res?.data?.data?.organizationPresignedUrls?.logoImage;

    } catch (err) {
        console.log(err)
        return err
    }

}

export const backgroundImagePresignedUrls = async ({ contentType, accessToken }) => {

    const data = JSON.stringify({
        query: `
                query UserPresignedUrls($contentType: ImageContentType!) {
                    userPresignedUrls(contentType: $contentType) {
                        backgroundImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
              `,
        variables: {
            "contentType": contentType ?? null,
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        return res?.data?.data?.userPresignedUrls.backgroundImage
    } catch (err) {
        return err
    }

}

export const blogPostPresignedUrls = async ({ contentType, accessToken }) => {

    const data = JSON.stringify({
        query: `
                query BlogPostPresignedUrls($contentType: ImageContentType!) {
                    blogPostPresignedUrls(contentType: $contentType) {
                        contentImage {
                            presignedThumbnailUpload
                            presignedUploadUrl
                            url
                        }
                    }
                }
            `,
        variables: {
            "contentType": contentType ?? null,
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        return res?.data?.data?.blogPostPresignedUrls.contentImage
    } catch (err) {
        return err
    }

}


