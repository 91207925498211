import { Box, Typography, IconButton, TextField, Grid } from '@mui/material';
import { SettingsContainer } from 'components';
import { t} from 'i18next'
import AddIcon from '@mui/icons-material/Add';
import { DialogFormContainerResumeSection } from './_DialogFormContainerResumeSection'

export const ResumeAbout = ({ user, open, setOpen, personalProfile, setPersonalProfile, handleSave }) => {

    return <>
        <SettingsContainer pb={2} mb={3}>

            <Box pl={3} pt={3} pr={3}>
                <Box display={"flex"}  justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5">
                       {t('Personal Profile')}
                    </Typography>
                    <IconButton
                        sx={{ ml: 1 }}
                        aria-label="edit"
                        onClick={() => setOpen(true)}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
                {!user?.personalInformation?.description && <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" color={"grey.600"} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>No content added yet. </Typography>
                    </Grid>
                </Grid>}<Box>
                    <Typography variant="body1" sx={{ mt: 1 }} color={"grey.600"}>
                        {user?.personalInformation?.description}
                    </Typography>
                </Box>
            </Box>

        </SettingsContainer>

        <DialogFormContainerResumeSection open={open} setOpen={setOpen} title={"Personal Profile"} handleSave={handleSave}>
            <TextField sx={{ mt: 1, minWidth: { xs: '100%', md: 550 } }}
                id="personalProfile"
                label="Personal Profile"
                multiline
                rows={5}
                value={personalProfile}
                onChange={(e) => setPersonalProfile(e.target.value)}
            />
        </DialogFormContainerResumeSection>
    </>
}
