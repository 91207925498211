import { Button, Typography, CircularProgress } from '@mui/material'
import { LinkedinAuthIcon } from '../../assets'
import { useMediaQuery } from '@mui/material';

import { useLinkedIn } from 'react-linkedin-login-oauth2';



export const LinkedinButton = ({ onClick, loading, type }) => {

    const downXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID,
        // redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        redirectUri: "https://testapp.internif.com/linkedin",
        scope: ["r_liteprofile", "r_emailaddress"],

        onSuccess: (code) => {
            console.log(code);
            onClick(code);
            // handleSignupLinkedinAuthContext({
            //     token: code,
            //     userType: userType,
            //     navigate,
            // })
        },
        onError: (error) => {
            console.log(error);
        },

    });






    return (
        <Button
            onClick={linkedInLogin}
            sx={{
                width: '100%',
                background: "#0077B5",
                borderRadius: '12px',
                color: '#fff',
                textTransform: 'none',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                height: '48px',
                ':hover': {
                    background: "#0077b5",
                }
            }}
            disabled={loading}
        >
            <img src={LinkedinAuthIcon} alt="" width="32" height="32" />
            <Typography variant='h6' color={'#fff'} textAlign="center">
                {downXs ? "Continue with Linkedin" : 'Continue with Linkedin'}
            </Typography>
            {loading && <CircularProgress size={"1rem"} />}
        </Button>
    )
}