
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box, Typography, Stack, Tabs, Tab, Card, FormGroup, FormControlLabel, Switch, Button,
    TextField, FormControl, InputLabel, Select, MenuItem, Grid, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { BasicInputPassword, AnimatedTitle, SettingsContainer, FormComponent } from 'components';
import { DashboardLayout } from 'layouts';
import PropTypes from 'prop-types';
import { NotificationsNone, Security, Description, InfoOutlined, Save, LanguageOutlined } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import regexPattern from 'minimal/utils/regexPattern';
import {
    resetPasswordUsingOldFunc, get2faFunc, update2faFunc, self_updateUserSettingsFunc, self_user_billingInfoFunc,
    self_updateUserBillingInfo
} from 'context';
import { useSnackbar } from 'notistack';
// import { updateUserBillingInfoFunc, updateCompanyBillingInfoFunc, userBillingInfoFunc } from 'context/common/packages';
import { anonymous_citiesFunc } from 'context';
import PayrollIcon from 'assets/icons/dashboard/payroll.png';
import { useNavigate } from 'react-router-dom';

const info = [
    "Notification eksik./backend", "Policies eksik/business"
]

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pl: 0, ml: 0, pt: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Settings = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { i18n } = useTranslation();
    const tab = searchParams.get('tab');
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken, userType } = useSelector((state) => state.user);
    const { defaultLanguageData } = useSelector(state => state.self_user);
    // const { userBillingInfoData } = useSelector(state => state.packages);
    const { t } = useTranslation()
    const [value, setValue] = useState(tab === "billingInfo" ? 3 : 0);
    const [pushNotification, setPushNotification] = useState(true);
    const [emailNotification, setEmailNotification] = useState(true);
    const [smsNotification, setSmsNotification] = useState(false);
    const [twoFactorEmail, setTwoFactorEmail] = useState(false);
    const [twoFactorSms, setTwoFactorSms] = useState(false);
    const [ip, setIp] = useState('');
    const [openTwoFactor, setOpenTwoFactor] = useState(false);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState('en');
    const { self_internData: user } = useSelector(state => state.self_intern);
    const { self_user_billingInfoData: billingInfo } = useSelector(state => state.self_user_billingInfo);




    const handlePushNotificationChange = () => {
        setPushNotification(!pushNotification);
    };

    const handleEmailNotificationChange = () => {
        setEmailNotification(!emailNotification);
    };

    const handleSmsNotificationChange = () => {
        setSmsNotification(!smsNotification);
    };

    const handleTwoFactorEmailChange = () => {
        setTwoFactorEmail(!twoFactorEmail);
    };

    const handleTwoFactorSmsChange = () => {
        setTwoFactorSms(!twoFactorSms);
    };

    const handleSaveToFactor = () => {
        setOpenTwoFactor(true)
        console.log('Two Factor Email:', twoFactorEmail);
        console.log('Two Factor SMS:', twoFactorSms);
    };

    const handleUpdate2fa = () => {
        dispatch(update2faFunc({ accessToken: accessToken, email: twoFactorEmail })).then((res) => {
            if (res.payload?.isSuccess) {
                twoFactorEmail && enqueueSnackbar('The 2FA with email has been activated!', { variant: 'success' });
                !twoFactorEmail && enqueueSnackbar('The 2FA with email has been deactivated!', { variant: 'success' });
                setOpenTwoFactor(false)
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" });
                setOpenTwoFactor(false)
            }
        })
    }

    const handleSaveSettings = () => {
        console.log('Push Notification:', pushNotification);
        console.log('Email Notification:', emailNotification);
        console.log('SMS Notification:', smsNotification);
    };

    const handleSaveDefaultLanguage = () => {
        self_updateUserSettingsFunc({ accessToken: accessToken, defaultLanguage: selectedDefaultLanguage }).then((res) => {
            if (res?._id) {
                enqueueSnackbar('Default language updated successfully!', { variant: 'success' });
                i18n.changeLanguage(selectedDefaultLanguage);
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validationSchema = yup.object({
        oldPassword: yup
            .string()
            .required('Old Password is required'),

        password: yup
            .string()
            .required('Password is required')
            .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
            .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
            .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
            .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!")
            .min(8, "Password must be at least 8 characters!")
            .required('Password is required!'),

        passwordAgain: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Password confirmation is required'),
    });

    const handleResetPassword = (values) => {
        dispatch(resetPasswordUsingOldFunc({ accessToken, oldPassword: values?.oldPassword, newPassword: values?.password })).then((res) => {
            console.log(res.payload.isSuccess)
            if (res.payload.isSuccess) {
                enqueueSnackbar('Password changed successfully!', { variant: 'success' });
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
        })
    }


    const handleSaveBillingInfo = (values) => {
        console.log(values)
        self_updateUserBillingInfo({ values, accessToken, ip }).then((res) => {
            console.log(res)
            if (res.success) {
                enqueueSnackbar('The billing information has been updated!', { variant: 'success' });
                tab === "billingInfo" && navigate('/premium')
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
        })
    }


    useEffect(() => {
        const fetchIp = async () => {
            const response = await fetch('https://api.ipify.org?format=json')
            const data = await response.json()
            setIp(data.ip)
        }
        accessToken && fetchIp()
    }, [accessToken])

    useEffect(() => {
        accessToken && dispatch(get2faFunc({ accessToken: accessToken })).then((res) => {
            if (res.payload?.isSuccess) {
                setTwoFactorEmail(res.payload.data.email)
                setTwoFactorSms(res.payload.data.sms)
            }
        })

        accessToken && dispatch(self_user_billingInfoFunc({ accessToken: accessToken }));
    }, [accessToken, dispatch])

    useEffect(() => {
        if (defaultLanguageData) {
            setSelectedDefaultLanguage(defaultLanguageData)
        }
    }, [defaultLanguageData])

    console.log(billingInfo)


    return <DashboardLayout info={info}>
        <Grid container sx={{ ml: { xs: 0, sm: 5 }, mt: { xs: 0, sm: 5 }, width: '100%' }}>
            <Box >
                <AnimatedTitle title={t("Settings")} />
            </Box>
        </Grid>
        <Box container sx={{ px: { xs: 0, sm: 5 }, py: 2, width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                    style={{ marginBottom: "-7px" }}
                    TabIndicatorProps={{
                        style: {
                            bottom: 7,
                            backgroundColor: "#FF9737",
                            height: "4px",
                            borderRadius: "10px",
                        },
                    }}>
                    <Tab icon={<NotificationsNone fontSize='small' />} label={t("Notification")} {...a11yProps(0)} />
                    <Tab icon={<LanguageOutlined fontSize='small' />} label={t("Language")} {...a11yProps(1)} />
                    <Tab icon={<Security fontSize='small' />} label={t("Security")} {...a11yProps(2)} />
                    <Tab icon={<InfoOutlined fontSize='small' />} label={t("Billing Info")} {...a11yProps(3)} />
                    <Tab icon={<Description fontSize='small' />} label={t("Policies")} {...a11yProps(4)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 2, mb: 2 }}>
                        <Grid item xs={12} ml={2}>
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can manage your notification preferences below.")}</Typography><br /><br />
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={pushNotification} onChange={handlePushNotificationChange} />}
                                    label={t("Push Notifications")}
                                />
                                <FormControlLabel
                                    control={<Switch checked={emailNotification} onChange={handleEmailNotificationChange} />}
                                    label={t("Email Notifications")}
                                />
                                {/* <FormControlLabel
                                    control={<Switch checked={smsNotification} onChange={handleSmsNotificationChange} />}
                                    label={t("SMS Notifications")}
                                /> */}
                            </FormGroup>
                            <Box height={30} />
                            <Button variant="contained" color="primary" onClick={handleSaveSettings}>
                                {t("Save Settings")}
                            </Button>
                        </Grid>
                    </Grid>
                </SettingsContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 2, mb: 2 }}>
                        <Grid item xs={12} ml={2}>
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can change the application language below.")}</Typography><br /><br />
                            {/* English and Turkish select */}

                            <FormControl sx={{ width: 200 }}>
                                <InputLabel id="demo-simple-select-label">{t("Language")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedDefaultLanguage}
                                    label="Language"
                                    onChange={(e) => setSelectedDefaultLanguage(e.target.value)}
                                >
                                    <MenuItem value={"en"}>{t('English')}</MenuItem>
                                    <MenuItem value={"tr"}>{t('Turkish')}</MenuItem>
                                </Select>
                            </FormControl>
                            <Box height={30} />
                            <Button variant="contained" color="primary" onClick={handleSaveDefaultLanguage}>
                                {t("Save Settings")}
                            </Button>
                        </Grid>
                    </Grid>
                </SettingsContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 1 }}>
                        <Grid item xs={12} ml={2}>

                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can change your password in the field below.")}</Typography><br /><br />

                            <Formik initialValues={{ password: '', oldPassword: '' }} validationSchema={validationSchema}
                                onSubmit={handleResetPassword}>
                                {({ handleSubmit, handleChange, handleBlur, errors, values, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Stack spacing={1} width={{ xs: '100%', md: '50%', lg: '50%' }}>
                                            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={0}>
                                                <BasicInputPassword
                                                    name="oldPassword"
                                                    type="password2"
                                                    label={t("Old Password")}
                                                    placeholder={t("Old password")}
                                                    value={values?.oldPassword}
                                                    error={touched.oldPassword && errors.oldPassword}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                />
                                            </Stack>
                                            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                                                <BasicInputPassword
                                                    name="password"
                                                    type="password2"
                                                    label={t("New Password")}
                                                    placeholder={t("New password")}
                                                    value={values?.password}
                                                    error={touched.password && errors.password}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                />
                                                <BasicInputPassword
                                                    name="passwordAgain"
                                                    type="password2"
                                                    label={t("New Password Again")}
                                                    placeholder={t("New password again")}
                                                    value={values?.passwordAgain}
                                                    error={touched.passwordAgain && errors.passwordAgain}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                />
                                            </Stack>
                                            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={2}>
                                                <Button variant="contained" color="primary" type='submit'>
                                                    {t("Change Password")}
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </SettingsContainer>

                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 1 }}>
                        <Grid item xs={12} ml={2}>
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update the following settings to perform 2-factor authentication.")}</Typography><br /><br />
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={twoFactorEmail} onChange={handleTwoFactorEmailChange} />}
                                    label={t("Two Factor with Email")}
                                />
                                {/* <FormControlLabel
                                    control={<Switch checked={twoFactorSms} onChange={handleTwoFactorSmsChange} />}
                                    label={t("Two Factor with SMS")}
                                /> */}
                            </FormGroup>
                            <Box height={10} />
                            <Button variant="contained" color="primary" onClick={handleSaveToFactor}>
                                {t("Save Settings")}
                            </Button>

                        </Grid>
                    </Grid>
                </SettingsContainer>

            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container p={3} >
                        <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2} width={'100%'} >
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update your billing information below.")}</Typography>
                            <img src={PayrollIcon} alt="Payroll Icon" height={"42px"} style={{ objectFit: 'contain' }} />
                        </Stack>
                        <FormComponent
                            handleSubmitForm={handleSaveBillingInfo}
                            defaultValues={{
                                name: billingInfo?.invoiceInfo?.personal?.name ?? user?.personalInformation?.name ?? '',
                                surname: billingInfo?.invoiceInfo?.personal?.surname ?? user?.personalInformation?.surname ?? '',
                                identityNumber: billingInfo?.invoiceInfo?.personal?.id ?? '',
                                email: billingInfo?.invoiceInfo?.personal?.email ?? user?.contact?.email ?? '',
                                phone: billingInfo?.invoiceInfo?.personal?.phoneNumber ?? user?.personalInformation?.phoneNumber ?? '',
                                country2: billingInfo?.invoiceInfo?.personal?.country ?? user?.personalInformation?.country?.name ?? '',
                                city2: billingInfo?.invoiceInfo?.personal?.city ?? user?.personalInformation?.city?.name ?? '',
                                postcode: billingInfo?.invoiceInfo?.personal?.zip ?? '',
                                address: billingInfo?.invoiceInfo?.personal?.address ?? '',
                            }}
                            name={[1, 12, 12, 6, 6, 6]}
                            surname={[2, 12, 12, 6, 6, 6]}
                            identityNumber={[3, 12, 12, 6, 6, 6]}
                            email={[4, 12, 12, 6, 6, 6]}
                            phone={[5, 12, 12, 6, 6, 6]}
                            country2={[6, 12, 12, 6, 6, 6]}
                            city2={[7, 12, 12, 6, 6, 6]}
                            postcode={[8, 12, 12, 6, 6, 6]}
                            address={[9, 12, 12, 6, 6, 6]}
                            button={{ text: t("Save Changes"), icon: <Save sx={{ mr: 1 }} fontSize='small' /> }}
                        />

                    </Grid>
                </SettingsContainer>

            </CustomTabPanel >

            <CustomTabPanel value={value} index={4}>
                <Typography>{t("Policies")}</Typography>
            </CustomTabPanel>
        </Box >

        <Dialog open={openTwoFactor} onClose={() => setOpenTwoFactor(false)}>
            <DialogTitle>{t("Two Factor Authentication")}</DialogTitle>
            <DialogContent>
                <Typography >{t("Your two factor auth changes will be saved. Do you confirm?")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenTwoFactor(false)} color="primary" variant={"outlined"}>
                    {t("Cancel")}
                </Button>
                <Button onClick={() => handleUpdate2fa()} color="primary" autoFocus variant='contained'>
                    {t("Confirm")}
                </Button>
            </DialogActions>

        </Dialog>
    </DashboardLayout >

}

export default Settings

