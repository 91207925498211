import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../initialConfig";


const initialState = {
    user_userNotificationsData: null,
    user_userNotificationsCount: 0,
    user_userNotificationsLoading: false,
    user_userNotificationsError: null,
};

export const user_userNotificationCountFunc = createAsyncThunk("user_userNotifications/user_userNotificationCountFunc", async (values) => {
    console.log({ values })
    const data1 = JSON.stringify({
        query: `query User_userNotifications($filter: FilterUserNotificationInput, $pagination: Pagination) {
        user_userNotifications(filter: $filter, pagination: $pagination) {
          count
        }
      }`,
        variables: {
            "filter": {
                "isRead": false
            },
            "pagination": {
                "page": null,
                "pageSize": null,
                "sort": null
            }
        }

    });


    const config = initialConfig(process.env.REACT_APP_BASE_URL + '/notification/graphql', data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            return err;
        });

    const data = await response;
    console.log({ data })
    return data;
});

export const user_userNotificationsFunc = createAsyncThunk("user_userNotifications/user_userNotificationsFunc", async (values) => {
    console.log({ values })
    const data1 = JSON.stringify({
        query: `query User_userNotifications($filter: FilterUserNotificationInput, $pagination: Pagination) {
        user_userNotifications(filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            isRead
            createdAt
            notificationPreview {
              content
              data {
                target
                targetId
                uid
              }
              name
              subject
            }
            notificationType
            uid
          }
        }
      }`,
        variables: {
            "filter": {
                "isRead": values.isRead ?? null
            },
            "pagination": {
                "page": values.page ?? 1,
                "pageSize": values.pageSize ?? 10,
                "sort": null
            }
        }

    });


    const config = initialConfig(process.env.REACT_APP_BASE_URL + '/notification/graphql', data1, values?.accessToken);

    const response = axios(config)
        .then((res) => {
            const data = res.data.data;
            return data;
        })
        .catch((err) => {
            return err;
        });

    const data = await response;
    console.log({ data })
    return data;
});


const user_userNotifications = createSlice({
    name: "user_userNotifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(user_userNotificationsFunc.pending, (state) => {
            state.user_userNotificationsLoading = true;
        });
        builder.addCase(user_userNotificationsFunc.fulfilled, (state, action) => {
            state.user_userNotificationsLoading = false;
            state.user_userNotificationsData = action.payload?.user_userNotifications?.data;
            state.user_userNotificationsError = action.payload.message;
        });
        builder.addCase(user_userNotificationsFunc.rejected, (state, action) => {
            state.user_userNotificationsLoading = false;
            state.user_userNotificationsError = action.error.message;
        });
        builder.addCase(user_userNotificationCountFunc.fulfilled, (state, action) => {
            state.user_userNotificationsCount = action.payload?.user_userNotifications?.count;
        });
    }
});


export default user_userNotifications.reducer;
