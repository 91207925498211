import axios from "axios";
import initialConfig from "context/initialConfig";

export const user_createOrderForUserWithStripeCheckoutFormPayment = async ({ accessToken, productId, productCode }) => {

  const data = JSON.stringify({
    query: `mutation User_createOrderForOrganizationWithStripeCheckoutFormPayment($orderInput: CreateOrderWithCheckoutFormInput!) {
      user_createOrderForUserWithIyzicoCheckoutFormPayment(orderInput: $orderInput) {
              order {
                _id
              }
              paymentResult {
                paymentPageUrl
                payWithIyzicoPageUrl
                token
                status
              }
            }
          }`,
    variables: {
      "orderInput": {
        "product": {
          "_id": productId
        },
        "currency": "TRY",
        "productCodeId": productCode
      }
    }
  });



  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    if (res.errors?.length > 0) {
      return {
        errorMessage: res?.errors[0].message,
      };
    }
    return res?.data?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment
  } catch (err) {
    return err
  }
}