import { useState } from "react"
import { useDispatch } from "react-redux"
import { Box } from "@mui/system"
import { GoogleButton, LinkedinButton } from "components"
import { useSnackbar } from "notistack"
import axios from "axios";
import { setAccessToken, setUserRole, setUserId, setEmail } from "context/common/user";
import { useNavigate } from "react-router-dom";


export const AuthSocialLogin = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLinkedinLogin = (token) => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_BASE_URL + '/auth/oauth/linkedin/login',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ token })
        };

        setLoading(true)
        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                const data = response.data;
                if (data.isSuccess) {
                    const userId = data?.data?.user?.userId;
                    const role = data?.data?.user?.role;
                    const token = data?.data?.accessToken;
                    const email = data?.data?.user?.email;

                    if (role === 'companyAdmin' || role === 'intern') {
                        localStorage.setItem('accessToken', token);
                        localStorage.setItem('userRole', role);
                        localStorage.setItem('userId', userId);
                        localStorage.setItem('email', email);
                        dispatch(setAccessToken(token));
                        dispatch(setUserRole(role));
                        dispatch(setUserId(userId));
                        dispatch(setEmail(email));
                        setLoading(false)
                        navigate('/');
                        enqueueSnackbar('Welcome to InternIF!', { variant: 'success' })
                    }
                    if (role === 'b2bSalesRep' || role === 'b2bCompanyRep' || role === 'b2bInternRep' || role === 'b2bClient') {
                        enqueueSnackbar("You need to login from the B2B Website!", { variant: 'warning', autoHideDuration: 2500 })
                        setTimeout(() => { window.location.href = 'https://biz.internif.com' }, 2500)
                        localStorage.clear()
                        setLoading(false)
                    }
                    if (role === 'internIFManager' || role === 'internIFFinance') {
                        enqueueSnackbar("You need to login from the Management Website!", { variant: 'warning', autoHideDuration: 2500 })
                        setTimeout(() => { window.location.href = 'https://manage.internif.com' }, 2500)
                        localStorage.clear()
                        setLoading(false)
                    }
                    if (role === 'universityAdmin') {
                        enqueueSnackbar("You need to login from the University Website!", { variant: 'warning', autoHideDuration: 2500 })
                        setTimeout(() => { window.location.href = 'https://uni.internif.com' }, 2500)
                        localStorage.clear()
                        setLoading(false)
                    }
                } else {
                    enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
                    setLoading(false)
                }

            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }


    return <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">
        <Box flexDirection={"column"} display="flex" gap="10px" width="100%">
            <LinkedinButton onClick={handleLinkedinLogin} loading={loading} />
            <GoogleButton type={"signin"} />
        </Box>
    </Box>
}
