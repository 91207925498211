import React from "react";
import { Route } from "react-router-dom";
import MyAccount from "pages/dashboard/company/MyAccount";
import Dashboard from "pages/dashboard/company/Dashboard";
import Team from "pages/dashboard/company/Team";
import Task from "pages/dashboard/company/Task";
import Notifications from "pages/dashboard/company/Notifications";
import Messages from "pages/dashboard/company/Messages";
import MyCompany from "pages/dashboard/company/MyCompany";
import Onboarding from "pages/onboarding/company/Onboarding";
import Membership from "pages/dashboard/company/Membership";
import {
  CreateInternshipForCompanyAdmin, InternshipsForCompanyAdmin, InternshipDetailForCompanyAdmin, SettingsCompany,
  IFAIForCompany, RemoteTransform, Notepad, PaymentSuccess
} from "pages";

const CompanyDashboardRouter = () => {
  return (
    <>
      {/* <Route path="/" element={<Dashboard />} /> */}
      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      <Route path="/" element={<InternshipsForCompanyAdmin />} />
      <Route path="/dashboard" element={<InternshipsForCompanyAdmin />} />
      <Route path="/internship" element={<InternshipsForCompanyAdmin />} />
      <Route path="/internship/:id" element={<InternshipDetailForCompanyAdmin />} />
      <Route path="/internship/createInternship" element={<CreateInternshipForCompanyAdmin />} />
      <Route path="/team" element={<Team />} />
      <Route path="/task" element={<Task />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/my-account" element={<MyAccount />} />
      <Route path="/my-company" element={<MyCompany />} />
      <Route path="*" element={<InternshipsForCompanyAdmin />} />
      <Route path="/if-credit" element={<Membership />} />
      <Route path="/settings" element={<SettingsCompany />} />
      <Route path="/remote-transform" element={<RemoteTransform />} />
      <Route path="/if-ai" element={<IFAIForCompany />} />
      <Route path="payment/success" element={<PaymentSuccess />} />
      <Route path="/notepad" element={<Notepad />} />
      <Route path="*" element={<InternshipsForCompanyAdmin />} />
    </>
  );
};

const CompanyOnboardingRouter = () => {
  return (
    <>
      <Route path="/" element={<Onboarding />} />
      <Route path="*" element={<Onboarding />} />
      <Route path="*" element={<Onboarding />} />
    </>
  );
};

export { CompanyDashboardRouter, CompanyOnboardingRouter };
