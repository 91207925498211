import { useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton, Grid, Card, Slide, Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '@mui/material';
import { SettingsContainer, Dropdown } from 'components';
import AddIcon from '@mui/icons-material/Add';
import { DeleteOutline, Translate } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useFormik } from "formik";
import { t} from 'i18next'
import { self_internFunc, intern_addForeignLanguageFunc, intern_removeForeignLanguageFunc } from 'context';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ResumeLanguages = ({ user }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { user_parametersData: parameters } = useSelector((state) => state.parameters);

    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [openLanguage, setOpenLanguage] = useState(false)

    const handleClick = (event) => {
        setOpenLanguage(true)
    };


    const userInitialValues = {
        language: "",
        languageLevel: ""
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        onSubmit: null
    })

    const handleAddLanguage = () => {
        intern_addForeignLanguageFunc({ values, accessToken }).then((res) => {
            if (res._id) {
                enqueueSnackbar('The language has been added!', { variant: 'success' });
                setOpenLanguage(false)
                dispatch(self_internFunc({ accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
        })
    }

    const handleRemoveLanguage = (id) => {
        intern_removeForeignLanguageFunc({ id, accessToken }).then((res) => {
            if (res.success) {
                enqueueSnackbar('The language has been removed!', { variant: 'success' });
                dispatch(self_internFunc({ accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
        })
    }

    useEffect(() => {
        if (user?.cv?.foreignLanguages?.length > 0) {
            setSelectedLanguages(user?.cv?.foreignLanguages)
        }
    }, [user])


    return <>
        <SettingsContainer pb={2} mb={3}>

            <Box pl={3} pt={3} pr={3}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5">
                      {t('Foreign Languages')}
                    </Typography>
                    <IconButton
                        disabled={selectedLanguages?.length >= 4}
                        sx={{ ml: 1 }}
                        aria-label="edit"
                        onClick={handleClick}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
                <>
                    {selectedLanguages?.length === 0 && <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" color={"grey.600"} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center', pb:2 }}>   No languages added yet. </Typography>
                        </Grid>
                    </Grid>}
                    {selectedLanguages?.length > 0 && <Grid container sx={{ width: '100%', mt: "10px", gap: { xs: 1, sm: 0 } }} spacing={{ xs: 0, sm: 2 }} >
                        {
                            selectedLanguages?.map((item, index) => (
                            <Grid item xs={12} md={3}>
                                <Card sx={{
                                    display: "flex", height: '100%', flexDirection: "column", justifyContent: "center", alignItems: "center", p: 2,
                                    '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)" }
                                }}>
                                    {/* <img src={`https://flagcdn.com/${parameters?.languages?.data?.filter?.(language => language?._id === item?.language?._id)?.[0]?.metadata?.code.toLowerCase()}.svg`} alt="language" style={{ width: 40, height: 40, borderRadius: 20, objectFit: 'cover' }} /> */}
                                    <Translate color="primary" sx={{ fontSize: 40 }} />
                                    <Typography variant="h7" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {parameters?.languages?.data?.filter?.(language => language?._id === item?.language?._id)?.[0]?.name} </Typography>
                                    <Typography variant="h8" sx={{ fontWeight: 'bold', textAlign: 'center' }}> {parameters?.languageLevels?.data?.filter?.(level => level?._id === item?.level?._id)?.[0]?.name} </Typography>

                                    <IconButton onClick={() => handleRemoveLanguage(item?._id)} sx={{ position: 'absolute', right: 3, top: 3 }}>
                                        <DeleteOutline color="error" />
                                    </IconButton>
                                </Card>
                            </Grid>
                        ))
                        }
                    </Grid>
                    }
            </>


            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 500, borderRadius: 2 } }}
                open={openLanguage}
                keepMounted
                onClose={() => setOpenLanguage(false)}
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}
            >
                <DialogTitle>{'Add Language'}</DialogTitle>
                <DialogContent sx={{ width: '100%', paddingRight: 1 }}>
                    <Grid container spacing={2} mt={0} sx={{ width: '100%' }}>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                setFieldValue={setFieldValue}
                                errors={errors}
                                data={parameters?.languages?.data.filter(item => !selectedLanguages?.map(item => item?.language?._id)?.includes(item?._id))}
                                label={"Language"}
                                labelId={"language-label"}
                                value={values.language}
                                id={"language"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                setFieldValue={setFieldValue}
                                errors={errors}
                                data={parameters?.languageLevels?.data}
                                label={"Level"}
                                labelId={"languageLevel-label"}
                                value={values.languageLevel}
                                id={"languageLevel"}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={() => setOpenLanguage(false)}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={handleAddLanguage}>
                        <Add sx={{ mr: 1 }} fontSize="small" />Add Language</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </SettingsContainer >
    </>
}
