import { Box, Typography, List, ListItem, Grid, ListItemText, Button, Stack, Divider } from '@mui/material'
import PaymentsIcon from '@mui/icons-material/Payments';
import OutboxIcon from '@mui/icons-material/Outbox';
import { useTranslation } from 'react-i18next';
import { TaskAlt } from '@mui/icons-material';

export const PackageCard = ({ medalType, bgColor, packageCost, letterSpace, handleOpenProductCode, features, handleUse, usable, realCost, userRole }) => {

    const { t } = useTranslation();

    return <Box
        sx={{
            transition: "all 0.1s ease",
            backfaceVisibility: "hidden",
            ':hover': {
                transition: "all 0.1s ease",
                boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.25)",
                transformBox: "fill-box",
                transform: "scale(1.03)",
                zIndex: 1,
                cursor: "pointer",
            },
        }}
        // bgcolor={bgColor}
        bgcolor={'#fff'}
        boxShadow={10}
        pb={2}
        height={'100%'}
        display="flex"
        flexDirection={"column"} alignItems="center" justifyContent={"space-evenly"}
        borderRadius={"10px"}
        position="relative"
    >
        <Box bgcolor={"orange"} height={10} width="100%" sx={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }} />
        <Box height={20} />
        <Typography variant="h2" style={{ letterSpacing: letterSpace ? letterSpace : "4.5px" }} color="#000" >
            {t(medalType)} </Typography>
        {medalType === "Standard" && userRole === "intern" && <Typography fontSize={"25px"} fontWeight={"bold"} color='primary.main' sx={{ textDecoration: "line-through" }} mb={-1} >${packageCost}</Typography>}
        <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography fontSize={"42px"} fontWeight={"bold"} color='secondary.main' >${realCost}</Typography>
            <Typography fontSize={"14px"} fontWeight={"bold"} color='secondary.main' mt={2} >+KDV</Typography>
        </Stack>

        {handleUse && <Divider sx={{ width: "50%" }} />}
        <Box>
            <List dense={true}>
                {features.map((feature, index) => (
                    <ListItem key={index} style={{ paddingTop: 2, paddingBottom: 2 }}>
                        {/* <ListItemIcon>
                            <KeyboardArrowRightIcon />
                        </ListItemIcon> */}
                        <ListItemText
                            sx={{
                                textAlign: "center",
                                textDecoration: feature.strikeThrough ? "line-through" : "none",
                                lineHeight: 0.5,
                            }}
                            primary={` ◦ ${feature.title}`}
                            secondary={true ? feature.description : null}
                        />
                    </ListItem>
                ))}
            </List>
            <Box height={10} />
            {handleUse && <Typography variant="h5" textAlign={"center"} mb={2} > {t("Usable")}: {usable ?? 0} </Typography>}
            {!handleUse ?
                <Grid container justifyContent="center">
                    <Button onClick={() => handleOpenProductCode({ packageType: medalType })} sx={{ px: 5 }}
                        variant="contained" color="primary" size="large"> <PaymentsIcon color="white"
                            fontSize="medium" style={{ marginRight: 10 }} />
                        {t("Buy")}
                    </Button>
                </Grid> :
                <Stack display={"flex"} flexDirection={{ xs: 'column', xl: "row" }} gap={1} p={{ xs: 2, xl: 0 }}
                    justifyContent="space-between">
                    <Button onClick={() => handleUse({ packageType: medalType })}
                        sx={{ width: '100%' }}
                        disabled={!usable}
                        variant="outlined" color="primary" size="large"> <TaskAlt color="white"
                            fontSize="small" style={{ marginRight: 5 }} />
                        {t("Use")}
                    </Button>
                    <Button onClick={() => handleOpenProductCode({ packageType: medalType })}
                        sx={{ width: '100%' }}
                        variant="contained" color="primary" size="large"> <PaymentsIcon color="white"
                            fontSize="small" style={{ marginRight: 5 }} />
                        {t("Buy")}
                    </Button>
                </Stack>


            }

        </Box>
    </Box >
}
