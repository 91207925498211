import axios from "axios";
import initialConfig from "context/initialConfig";

export const user_updateOrderAfterStripeCheckoutFormPayment = async ({ orderId, accessToken }) => {

  const data = JSON.stringify({
    query: `mutation User_updateOrderAfterStripeCheckoutFormPayment($orderId: ID!) {
      user_updateOrderAfterStripeCheckoutFormPayment(orderId: $orderId) {
        order {
          _id
        }
        paymentResult {
          paymentStatus
        }
      }
    }`,
    variables: {
      "orderId": orderId
    }
  })

  try {
    const res = await axios(initialConfig(process.env.REACT_APP_BASE_URL + '/payment/graphql', data, accessToken));
    if (res.data?.errors?.length > 0) {
      return {
        errorMessage: res.data?.errors[0].message,
      };
    }
    if (res.errors?.length > 0) {
      return {
        errorMessage: res?.errors[0].message,
      };
    }
    return res?.data?.data?.user_updateOrderAfterStripeCheckoutFormPayment
  } catch (err) {
    return err
  }
}