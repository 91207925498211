import { Grid, Typography, Card, Divider, Box, useMediaQuery, Button } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import Typewriter from 'typewriter-effect';
import RemoteTeam from 'assets/images/dashboard/Remote team-bro.png';
import parse from 'html-react-parser'

const info = [
    "Faz 1 başlamadı."
]

const tipTitle = `How IF-AI Works for You:`;
const tipDescription = `<p><b>Self-Assessment Journey: </b><br/>
Begin your self-assessment journey with IF-AI's intuitive platform. <br/>
Reflect on your skills, accomplishments, and areas for growth. <br/><br/>

<b>Personalized Insights:</b> <br/>
Receive instant insights into your strengths and areas for improvement. <br/>
Explore recommended learning paths to enhance your skills. <br/><br/>

<b>Goal Setting and Achievement: </b><br/>
Set career goals and milestones with IF-AI's goal-setting features. <br/>
Celebrate achievements and monitor your progress effortlessly. <br/><br/>

<b>Your Professional Growth Starts Here!</b> <br/>
IF-AI is not just a tool; it's your ally in professional growth. Whether you're embarking on your first internship or aiming to refine your skills, IF-AI is designed to support and guide you every step of the way. <br/><br/>
<b>Ready to Elevate Your Internship Experience?</b> <br/><br/>
`

const IFAI = () => {

    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return <DashboardLayout info={info}>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} >
                <AnimatedTitle title="IF-AI" />
            </Grid>
            <Grid item xs={12} lg={8} xl={9} >
                <Typography variant="h7" fontWeight={500} component="div" sx={{ color: 'text.secondary' }}>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter.changeDelay(50)
                                .typeString(`<strong>Welcome to IF-AI: Your Personal HR Assistant</strong> <br/><br/>`)
                                .changeDelay(25)
                                .typeString(`<strong>🌐 Empowering Your Journey, Elevating Your Potential!</strong> <br/><br/>`)
                                .typeString(`Interns, meet your new companion in professional development—IF-AI! As you navigate the exciting world of internships, IF-AI is here to make your experience even more enriching, providing personalized assistance and self-evaluation tools tailored just for you. <br/><br/>`)
                                .changeDelay(10)
                                .typeString(`<strong>Why Choose IF-AI as Your HR Guide?</strong> <br/><br/>`)
                                .typeString(`<strong>1.⁠ ⁠Intelligent Self-Evaluation:</strong> <br/>`)
                                .typeString(`Discover your strengths, set goals, and understand your professional journey better. IF-AI guides you through a seamless self-evaluation process, helping you articulate your skills and aspirations. <br/><br/>`)
                                .typeString(`<strong>2.⁠ ⁠Tailored Career Insights:</strong> <br/>`)
                                .typeString(`Receive personalized career insights based on your self-evaluation. IF-AI doesn't just assess; it empowers you with recommendations for growth, suggesting areas to focus on and skills to develop. <br/><br/>`)
                                .typeString(`<strong>3.⁠ ⁠Career Path Guidance:</strong> <br/>`)
                                .typeString(`Not sure about your career path? IF-AI offers guidance based on your self-assessment. Explore potential career paths aligned with your skills and interests. <br/><br/>`)
                                .typeString(`<strong>4.⁠ ⁠Real-Time Progress Tracking:</strong> <br/>`)
                                .typeString(`Track your progress in real-time. IF-AI provides dynamic dashboards showcasing your achievements, helping you stay motivated and focused on your professional goals. <br/><br/>`)



                                .typeString(``)
                                .typeString(``)
                                .callFunction(() => {
                                    console.log('String typed out!');
                                })
                                // .deleteAll()
                                .callFunction(() => {
                                    console.log('All strings were deleted');
                                })
                                .start();
                        }}
                    />
                </Typography>
            </Grid>
            {upLg && <Grid item xs={12} lg={4} xl={3}>
                <Card sx={{
                    padding: 3, backgroundColor: "#F8F8F8", height: '100%', border: '1px solid #E0E0E0',
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                        <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
                            {parse(tipDescription)}
                        </Typography>
                        <Button variant="contained" fullWidth color='secondary' size='large'
                            sx={{
                                borderRadius: 1.5,
                                animation: 'shadowPulse 2s infinite', // 2 saniyede bir tekrarlayan animasyon
                                '@keyframes shadowPulse': {
                                    '0%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                    '50%': {
                                        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.25)',
                                        transform: 'scale(1.02)'
                                    },
                                    '100%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1)'
                                    },
                                },
                                '&:hover': {
                                    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)', // Hover durumunda gölgeyi güncelle
                                    transform: 'translateY(-2px)', // Hover durumunda hafif yukarı zıplama efekti
                                },
                                '&:active': {
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Basıldığında gölgeyi güncelle
                                    transform: 'translateY(2px)', // Basıldığında hafif aşağı zıplama efekti
                                },
                            }}
                        >Join the Waitlist</Button>
                    </Box>

                </Card>
            </Grid>}
            {/* <Grid item xs={6} >
                <img src={RemoteTeam} alt="Remote Team" style={{ width: '60%' }} />
            </Grid> */}
        </Grid>
    </DashboardLayout>
}

export default IFAI