import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
    Grid, Typography, useMediaQuery, Card, Divider, Box, Button, Dialog, DialogTitle, DialogContent,
    DialogActions, CircularProgress, Stack, Accordion, AccordionSummary, AccordionDetails,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, IconButton, TextField,
    Tabs, Tab, Select
} from '@mui/material'

import { DashboardLayout } from 'layouts'
import { AnimatedTitle, FormComponent, PackageCard } from 'components'
import { useTranslation } from 'react-i18next'
import { intern_premiumTips, intern_premium_title, intern_premium_title_tr, intern_premiumTips_tr } from 'utils/tips/intern_premiumTips'
import parse from 'html-react-parser'
import {
    intern_productsFunc, self_user_billingInfoFunc, orderFunc, user_createOrderForUserWithStripeCheckoutFormPayment, hIntern_productsFunc, intern_packagesFunc,
    intern_useInternshipPackage
} from 'context'
import { useSnackbar } from 'notistack'
import PaymentSuccess from "assets/animations/payment_success.json";
import PaymentFailed from "assets/animations/payment_failed.json";
import PremiumAnimation from "assets/animations/premium.json";
import Lottie from "lottie-react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Label from 'minimal/components/label'
import { RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack';
import moment from 'moment'
import i18next from 'i18next'

const info = [
    "Buy işlemleri için backend bekleniyor."
]

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



function createData(name, type, status) {
    return { name, type, status };
}

const rows = [
    createData('Pro', '', 'pending'),
    createData('Standard', '', 'researching'),
];

const rows2 = [
    {
        name: 'Pro',
        type: '12.12.2021',
        status: 'completed'
    },
    {
        name: 'Standard',
        type: '12.12.2021',
        status: 'researching'
    },
]


const Premium = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { accessToken, userRole } = useSelector(state => state.user)
    const { anonymous_countriesData: countries, anonymous_citiesData: cities } = useSelector((state) => state.parameters);
    const { self_user_billingInfoData: billingInfo, self_user_packagesData: packages } = useSelector(state => state.self_user_billingInfo)
    const { intern_packagesData: packagesData } = useSelector(state => state.intern_packages)
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const [openIyzico, setOpenIyzico] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [iyzicoUrl, setIyzicoUrl] = useState(null);
    const [paymentResult, setPaymentResult] = useState(false);
    const [openUsePackage, setOpenUsePackage] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [values, setValues] = useState({});
    const [selectedUsedPackage, setSelectedUsedPackage] = useState(null);
    const [selectedTabSectionValue, setSelectedTabSectionValue] = useState(0);
    const [openProductCode, setOpenProductCode] = useState(false);
    const [selectedPackageType, setSelectedPackageType] = useState(null);
    const [productCode, setProductCode] = useState(null);
    const [loadingBuy, setLoadingBuy] = useState(false);
    const [errorProductCode, setErrorProductCode] = useState(false);
    const [usableStandard, setUsableStandard] = useState(0);
    const [usablePro, setUsablePro] = useState(0);
    const [usableSpecial, setUsableSpecial] = useState(0);
    const [onLoad, setOnLoad] = useState(false)
    const [isHaveProductCode, setIsHaveProductCode] = useState(false)
    const gridIframe = useRef(null);
    const upMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    const [productsPrice, setProductsPrice] = useState({ standard: 0, pro: 0, special: 0 })

    const tipTitle = i18next.language === "tr" ? intern_premium_title_tr : intern_premium_title;
    const tipDescription = i18next.language === "tr" ? intern_premiumTips_tr : intern_premiumTips;

    const standartFeatures = [
        { title: t("Company Selection"), strikeThrough: true },
        { title: t("Certificate of Participation") },
        { title: t("Practical Training Certification") },
        { title: t("Reference Letter") },
        { title: t("Selection from 1 Country") },
        { title: t("General Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Micro Enterprises") },
    ]

    const proFeatures = [
        { title: t("Selection from 3 Companies") },
        { title: t("Certificate of Participation") },
        { title: t("Practical Training Certification") },
        { title: t("Reference Letter") },
        { title: t("Selection from 1 Country") },
        { title: t("Dedicated Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Small Enterprises") },
    ]

    const specialFeatures = [
        { title: t("Company Selection"), strikeThrough: true },
        { title: t("Certificate of Participation") },
        { title: t("Practical Training Certification") },
        { title: t("Reference Letter") },
        { title: t("Selection from 1 Country") },
        { title: t("Dedicated Support") },
        { title: t("Orientation") },
        { title: t("Mentoring") },
        { title: t("Medium Enterprises") },
    ]

    const handleOpenProductCode = ({ packageType }) => {
        setProductCode(null)
        setErrorProductCode(false)
        setIsHaveProductCode(false)
        setOpenProductCode(true)
        setSelectedPackageType(packageType)
    }

    const handleClickOpenBuy = ({ packageType, withoutPcode }) => {
        setLoadingBuy(true)

        billingInfo && userRole === "intern" && dispatch(intern_productsFunc({ accessToken, name: packageType })).then(res => {
            console.log(res.payload.intern_products?.data?.filter(item => item.name === packageType)?.[0]?._id)
            const productId = res.payload.intern_products?.data?.filter(item => item.name === packageType)?.[0]?._id
            user_createOrderForUserWithStripeCheckoutFormPayment({ accessToken, productId, productCode: !withoutPcode ? productCode : null }).then(res => {
                console.log(res)
                if (res?.order?._id) {
                    setOrderId(res?.order?._id)
                    setIyzicoUrl(res?.paymentResult?.paymentPageUrl + `&iframe=true`)
                    setOpenIyzico(true)
                    // window.open(res?.paymentResult?.url, '_blank')
                    setLoadingBuy(false)
                    setOpenProductCode(false)
                } else if (res?.errorMessage?.includes('Argument passed') || res?.errorMessage?.includes('InvalidProductCode')) {
                    setErrorProductCode(res?.errorMessage)
                    setLoadingBuy(false)
                } else {
                    setOpenProductCode(false)
                    enqueueSnackbar(<div>
                        You need to add billing information!
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => navigate('/settings?tab=billingInfo')} >
                            Go to Edit
                        </Button >
                    </div >, { variant: 'warning', autoHideDuration: 5000 });
                    setLoadingBuy(false)
                }
            }).catch(err => {
                console.log(err)
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error', autoHideDuration: 5000 })
            })
        });

        billingInfo && userRole === "highSchoolIntern" && dispatch(hIntern_productsFunc({ accessToken, name: packageType })).then(res => {
            console.log(res.payload.hIntern_products?.data?.filter(item => item.name === packageType)?.[0]?._id)
            const productId = res.payload.hIntern_products?.data?.filter(item => item.name === packageType)?.[0]?._id
            user_createOrderForUserWithStripeCheckoutFormPayment({ accessToken, productId, productCode: !withoutPcode ? productCode : null }).then(res => {
                console.log(res)
                if (res?.order?._id) {
                    setOrderId(res?.order?._id)
                    setIyzicoUrl(res?.paymentResult?.paymentPageUrl + `&iframe=true`)
                    setOpenIyzico(true)
                    // window.open(res?.paymentResult?.url, '_blank')
                    setLoadingBuy(false)
                    setOpenProductCode(false)

                } else if (res?.errorMessage?.includes('Argument passed') || res?.errorMessage?.includes('InvalidProductCode')) {
                    setErrorProductCode(res?.errorMessage)
                    setLoadingBuy(false)
                } else {
                    setOpenProductCode(false)
                    enqueueSnackbar(<div>
                        You need to add billing information!
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => navigate('/settings?tab=billingInfo')} >
                            Go to Edit
                        </Button >
                    </div >, { variant: 'warning', autoHideDuration: 5000 });
                    setLoadingBuy(false)
                }
            }).catch(err => {
                console.log(err)
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error', autoHideDuration: 5000 })
            })
        });
        !billingInfo && enqueueSnackbar(<div>
            You need to add billing information!
            <Button
                color="secondary"
                size="small"
                onClick={() => navigate('/settings?tab=billingInfo')} >
                Go to Edit
            </Button>
        </div >, { variant: 'warning', autoHideDuration: 5000 });

    };


    const handleSubmitForm = (data) => {
        console.log(data)
        setValues(data)
    };

    const handleUsePackage = () => {
        intern_useInternshipPackage({ accessToken, internshipPackageId: selectedPackage?._id, internshipInfo: values }).then(res => {
            console.log(res)
            if (res?.success) {
                enqueueSnackbar('Your package has been successfully used!', { variant: 'success', autoHideDuration: 5000 });
                setOpenUsePackage(false)
                dispatch(intern_packagesFunc({ accessToken })).then(res => {
                    setUsableStandard(res.payload.intern_packages?.data?.filter(item => item.packageType === "standard" && item.status === "notUsed"))
                    setUsablePro(res.payload.intern_packages?.data?.filter(item => item.packageType === "pro" && item.status === "notUsed"))
                    setUsableSpecial(res.payload.intern_packages?.data?.filter(item => item.packageType === "special" && item.status === "notUsed"))
                })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error', autoHideDuration: 5000 })
            }
        }
        ).catch(err => {
            console.log(err)
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error', autoHideDuration: 5000 })
        })
    }

    const handleSetSelectedTabSectionValue = (event, newValue) => {
        setSelectedTabSectionValue(newValue);
    };




    useEffect(() => {
        accessToken && dispatch(self_user_billingInfoFunc({ accessToken }))
        accessToken && dispatch(intern_packagesFunc({ accessToken })).then(res => {
            setUsableStandard(res.payload.intern_packages?.data?.filter(item => item.packageType === "standard" && item.status === "notUsed"))
            setUsablePro(res.payload.intern_packages?.data?.filter(item => item.packageType === "pro" && item.status === "notUsed"))
            setUsableSpecial(res.payload.intern_packages?.data?.filter(item => item.packageType === "special" && item.status === "notUsed"))
        })
        accessToken && userRole === "intern" && dispatch(intern_productsFunc({ accessToken })).then(res => {
            setProductsPrice({
                standard: res.payload.intern_products?.data?.filter(item => item.name === "Standard")?.[0]?.price,
                pro: res.payload.intern_products?.data?.filter(item => item.name === "Pro")?.[0]?.price,
                special: res.payload.intern_products?.data?.filter(item => item.name === "Special")?.[0]?.price
            })
        })
        accessToken && userRole === "highSchoolIntern" && dispatch(hIntern_productsFunc({ accessToken })).then(res => {
            setProductsPrice({
                standard: res.payload.hIntern_products?.data?.filter(item => item.name === "Standard")?.[0]?.price,
                pro: res.payload.hIntern_products?.data?.filter(item => item.name === "Pro")?.[0]?.price,
                special: res.payload.hIntern_products?.data?.filter(item => item.name === "Special")?.[0]?.price
            })
        })
    }, [accessToken, dispatch])

    // useEffect(() => {
    //     const fetchData = async () => {


    //         if (openResult) {


    //             const delay = ms => new Promise(res => setTimeout(res, ms));
    //             await delay(3000);
    //             const res = await dispatch(orderFunc({ accessToken, orderId }));
    //             const orderStatus = res?.payload?.order?.orderStatus;


    //             if (orderStatus === 'completed') {
    //                 setPaymentResult("completed");
    //             } else {
    //                 await delay(4000);
    //                 const res = await dispatch(orderFunc({ accessToken, orderId }));
    //                 const orderStatus = res?.payload?.order?.orderStatus;
    //                 if (orderStatus === 'completed') {
    //                     setPaymentResult("completed");
    //                 } else {
    //                     await delay(5000);
    //                     const res = await dispatch(orderFunc({ accessToken, orderId }));
    //                     const orderStatus = res?.payload?.order?.orderStatus;
    //                     if (orderStatus === 'completed') {
    //                         setPaymentResult("completed");
    //                     } else {
    //                         setPaymentResult("waiting");
    //                     }
    //                 }
    //             }
    //         }
    //     };

    //     fetchData();

    //     return () => setPaymentResult(false);

    // }, [orderId, dispatch, accessToken, openResult]);


    useEffect(() => {
        if (orderId) {

            dispatch(orderFunc({ accessToken, orderId })).then((res) => {
                console.log(res, "res")
            })


            const interval = setInterval(() => {

                dispatch(orderFunc({ accessToken, orderId })).then((res) => {
                    console.log(res, "res")
                    if (res?.payload?.order?.orderStatus === "completed") {
                        setOpenIyzico(false)
                        setOpenResult(true)
                        setPaymentResult("completed");
                        setOrderId("")
                        setIyzicoUrl("")
                        clearInterval(interval);
                    } else if (res?.payload?.order?.orderStatus === "failure") {
                        setPaymentResult("failure");
                        setOrderId("")
                        setIyzicoUrl("")
                        clearInterval(interval);
                    }

                }).catch((err) => {
                    console.log(err, "err")
                })
            }, onLoad ? 3000 : 5000);

            return () => clearInterval(interval);
        }
    }, [orderId]);


    return <DashboardLayout info={info}>
        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} >
                <AnimatedTitle title={`${t("Premium")}`} />
            </Grid>

            <Grid item xs={12} lg={8} xl={9}>
                <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ width: '100%', gap: { xs: 2, sm: 0 } }}>
                    {/* <Grid item xs={12} display={"flex"} alignItems={"center"} >
                        <Typography variant="h5" sx={{ fontWeight: 600, mr: 1 }}>{t('Your Package')}:</Typography>
                        {(!packages?.standard?.usable || !packages?.pro?.usable || !packages?.special?.usable) ?
                            <Typography variant="h5" color={"secondary"}>{t('Free')}</Typography> :
                            <Typography variant="h5" color={"secondary"}>{t('Premium')}</Typography>}
                    </Grid> */}

                    {packagesData?.filter(item => item.status !== "notUsed")?.length > 0 && <Grid item xs={12}  >
                        {/* <Typography variant="h5" fontWeight={"bold"} mb={2} >Kullanımda Olan Paketler</Typography> */}
                        <Typography variant="h5" fontWeight={"bold"} mb={2} >{t("Packages in Use")}</Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: 2, border: "1px solid #E0E0E0" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>{t("Package Type")}</TableCell>
                                        <TableCell>{t("Used Date")}</TableCell>
                                        <TableCell>{t("Status")}</TableCell>
                                        <TableCell>{t("Action")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {packagesData?.filter(item => item.status !== "notUsed")?.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.packageType.toUpperCase()}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {moment(row.createdAt).format("DD.MM.YYYY")}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Label sx={{ width: '80px' }} color={row.status === "workingOn" ? "info" : "warning"}>{
                                                    row.status === "used" ? "pending"
                                                        : row.status === "workingOn" ? "researching"
                                                            : ""}</Label>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton onClick={() => setSelectedUsedPackage(row?._id)} >
                                                    <RemoveRedEye />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>}

                    <Grid item xs={12} alignItems={"center"} >

                        <Box border={"1px solid #E0E0E0"} bgcolor={"#F8F8F8"} borderRadius={2} py={3} pl={{ xs: 2, md: 5 }} pr={{ xs: 2, md: 0 }} mb={2}>
                            <Typography variant='body1' mb={1} sx={{ fontWeight: 600, pr: { xs: 2, md: 5 } }} textAlign={"justify"}>{t("Are you tired of endlessly searching for internship opportunities with no success? Say goodbye to uncertainty and hello to guaranteed international internship placement with InternIF Premium!")}</Typography>

                            <Stack direction="row" alignItems="center" justifyContent="center"
                            >

                                <Box>
                                    <Typography variant="body1" mb={1} textAlign={"justify"} >
                                        {t("To buy a package, you need to complete your billing information. Fill in the information in the Invoice section completely.")}
                                    </Typography>
                                    <Typography variant="body1" mb={1} textAlign={"justify"} >
                                        <strong>{t("Here's how our guaranteed internship placement works:")}&nbsp;</strong>
                                    </Typography>
                                    <Typography variant="body1" mb={1} textAlign={"justify"} >
                                        <strong>{t("Personalized Matching:")}&nbsp;</strong>
                                        {t("We use cutting-edge technology to match you with internship opportunities that align with your skills, interests, and aspirations.")}
                                    </Typography>
                                    <Typography variant="body1" mb={1} textAlign={"justify"} >
                                        <strong>{t("Exclusive Access:")}&nbsp;</strong>
                                        {t("InternIF Premium members get exclusive access to a curated selection of internship opportunities not available to the public. You'll have the first pick of the best internships before they're even advertised elsewhere.")}
                                    </Typography>
                                    <Typography variant="body1" mb={1} textAlign={"justify"} >
                                        <strong>{t("Dedicated Support:")}&nbsp;</strong>
                                        {t("Our team of internship placement specialists is here to support you every step of the way. We'll provide personalized guidance and support to ensure your success.")}
                                    </Typography>
                                    <Typography variant="body1" mb={1} textAlign={"justify"} >
                                        <strong>{t("Peace of Mind:")} &nbsp;</strong>
                                        {t("With our guaranteed internship placement feature, you can rest assured knowing that you'll secure a valuable internship experience to boost your career prospects.")}
                                    </Typography>


                                </Box>
                                {upMd && <Lottie
                                    animationData={PremiumAnimation}
                                    style={{ width: '70%', }}
                                    autoplay={true}
                                    loop={true}
                                />}
                            </Stack>
                            <Typography variant="body1" mb={1} sx={{ fontWeight: 600, pr: { xs: 2, md: 5 } }} textAlign={"justify"}>
                                <strong>{t("Don't leave your internship placement to chance. Upgrade to InternIF Premium today and unlock guaranteed internship placement for a brighter future!")}</strong>
                            </Typography>
                        </Box>
                    </Grid>

                    {/* FOR INTERN  */}
                    {userRole === "intern" && <>
                        <Grid item xs={12} sm={6} md={4}>
                            <PackageCard
                                medalType={"Standard"}
                                letterSpace={1}
                                bgColor="#FFEFE2"
                                features={standartFeatures}
                                handleOpenProductCode={handleOpenProductCode}
                                handleUse={() => { setOpenUsePackage(true); setSelectedPackage(usableStandard?.[0]) }}
                                usable={usableStandard?.length}
                                packageCost={"499"}
                                realCost={productsPrice?.standard}
                                userRole={userRole}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <PackageCard
                                medalType={"Pro"}
                                letterSpace={1}
                                bgColor="#F5F5F5"
                                features={proFeatures}
                                handleOpenProductCode={handleOpenProductCode}
                                handleUse={() => { setOpenUsePackage(true); setSelectedPackage(usablePro?.[0]) }}
                                usable={usablePro?.length}
                                packageCost={"999"}
                                realCost={productsPrice?.pro}
                                userRole={userRole}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <PackageCard
                                medalType={"Special"}
                                letterSpace={1}
                                bgColor="#fff1ba"
                                features={specialFeatures}
                                handleOpenProductCode={handleOpenProductCode}
                                handleUse={() => { setOpenUsePackage(true); setSelectedPackage(usableSpecial?.[0]) }}
                                usable={usableSpecial?.length}
                                packageCost={"1499"}
                                realCost={productsPrice?.special}
                                userRole={userRole}
                            />
                        </Grid>
                    </>}
                    {/* FOR HIGHSCHOOL INTERN  */}
                    {userRole === "highSchoolIntern" && <>
                        <Grid item xs={12} sm={6} md={4}>
                            <PackageCard
                                medalType={"Standard"}
                                letterSpace={1}
                                bgColor="#FFEFE2"
                                features={standartFeatures}
                                handleOpenProductCode={handleOpenProductCode}
                                handleUse={() => { setOpenUsePackage(true); setSelectedPackage(usableStandard?.[0]) }}
                                usable={usableStandard?.length}
                                packageCost={"199"}
                                realCost={productsPrice?.standard}
                                userRole={userRole}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <PackageCard
                                medalType={"Pro"}
                                letterSpace={1}
                                bgColor="#F5F5F5"
                                features={proFeatures}
                                handleOpenProductCode={handleOpenProductCode}
                                handleUse={() => { setOpenUsePackage(true); setSelectedPackage(usablePro?.[0]) }}
                                usable={usablePro?.length}
                                packageCost={"599"}
                                realCost={productsPrice?.pro}
                                userRole={userRole}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <PackageCard
                                medalType={"Special"}
                                letterSpace={1}
                                bgColor="#fff1ba"
                                features={specialFeatures}
                                handleOpenProductCode={handleOpenProductCode}
                                handleUse={() => { setOpenUsePackage(true); setSelectedPackage(usableSpecial?.[0]) }}
                                usable={usableSpecial?.length}
                                packageCost={"999"}
                                realCost={productsPrice?.special}
                                userRole={userRole}
                            />
                        </Grid>
                    </>}
                </Grid>

                <Divider sx={{ mt: 5, mb: 5 }} />

                <Grid item xs={12} mt={5}  >
                    <Typography variant="h5" fontWeight={"bold"} mb={2} >{t("Frequently Asked Questions")}</Typography>
                    <Tabs value={selectedTabSectionValue} onChange={handleSetSelectedTabSectionValue}
                        style={{ marginBottom: "-7px" }}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                bottom: 7,
                                backgroundColor: "#FF9737",
                                height: "4px",
                                borderRadius: "10px",
                            },
                        }}>
                        <Tab label={t("General")} {...a11yProps(0)} />
                        <Tab label={t("Packages")} {...a11yProps(1)} />
                        <Tab label={t("Remote Internship Process")} {...a11yProps(2)} />
                        <Tab label={t("Internship Placement")} {...a11yProps(3)} />
                        <Tab label={t("During Internship")} {...a11yProps(4)} />
                        <Tab label={t("Internship Completion")} {...a11yProps(5)} />
                    </Tabs>

                    <CustomTabPanel value={selectedTabSectionValue} index={0} p={0}>
                        <Box sx={{ border: "1px solid #E0E0E0", borderRadius: 2, padding: 1 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {t("What is InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("InternIF Premium is a specialized internship placement service offered by InternIF, providing students with enhanced support and opportunities for international internships.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    {t("How does InternIF Premium differ from regular internship services?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("InternIF Premium offers additional features such as dedicated support, company selection options, and tailored internship placements, providing a more personalized and comprehensive internship experience.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("Is InternIF Premium available to students from all countries?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Yes, InternIF Premium is available to students worldwide, offering internship opportunities in various countries and industries.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {t("What are the differences in terms of international remote internship experience whether companies are micro-size, middle-size or small-size?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("The Remote Internship Process with InternIF Premium is designed to provide students with a seamless and enriching international internship experience. Here's a breakdown of the process:")}
                                    <ul>
                                        <li>{t("The experience of an international remote internship can vary based on the size of the company. Here are the differences in terms of international remote internship experience between micro-sized, small-sized, and medium-sized companies:")}</li>
                                        <ul>
                                            <li>{t("Scope of Responsibilities:")}</li>
                                            <ul>
                                                <li>{t("In micro-sized companies, interns may have the opportunity to take on a wide range of responsibilities and tasks. Due to the smaller team size, interns may be involved in various aspects of the business operations, including project management, client communication, and research.")}</li>
                                                <li>{t("Small-sized companies also offer interns diverse responsibilities but may provide more focused tasks within specific departments or projects. Interns may work closely with team members and supervisors to contribute to ongoing initiatives and gain hands-on experience in their chosen field.")}</li>
                                                <li>{t("Medium-sized companies typically provide interns with structured roles and responsibilities within designated departments or teams. Interns may work on larger-scale projects or initiatives and collaborate with colleagues from different geographic locations.")}</li>
                                            </ul>
                                            <li>{t("Supervision and Mentorship:")}</li>
                                            <ul>
                                                <li>{t("In micro-sized companies, interns may receive direct supervision and mentorship from senior team members or company founders. This close mentorship can provide valuable guidance and support as interns navigate their responsibilities and learn new skills.")}</li>
                                                <li>{t("Small-sized companies may offer interns mentorship opportunities with experienced professionals within the organization. Interns may have regular check-ins with supervisors or mentors to discuss progress, receive feedback, and set goals for their internship experience.")}</li>
                                                <li>{t("Medium-sized companies often provide interns with access to a broader network of mentors and advisors. Interns may have the opportunity to connect with professionals from different departments or locations for mentorship and guidance on their projects and career development.")}</li>
                                            </ul>
                                            <li>{t("Collaboration and Communication:")}</li>
                                            <ul>
                                                <li>{t("In micro-sized companies, interns may have the opportunity to collaborate closely with team members on projects and initiatives. Due to the smaller team size, interns may play a significant role in decision-making processes and have more direct communication with colleagues.")}</li>
                                                <li>{t("Small-sized companies also emphasize collaboration but may have more structured communication channels and project management systems in place. Interns may use digital collaboration tools and platforms to work with team members and share progress updates on their work.")}</li>
                                                <li>{t("Medium-sized companies typically have established communication protocols and project management processes to facilitate collaboration among remote team members. Interns may participate in team meetings, virtual brainstorming sessions, and collaborative projects with colleagues from different locations.")}</li>
                                            </ul>
                                            <li>{t("Professional Development Opportunities:")}</li>
                                            <ul>
                                                <li>{t("In micro-sized companies, interns may have the opportunity to gain hands-on experience and develop a diverse skill set through their involvement in various aspects of the business. Interns may also have the chance to take on leadership roles and contribute to strategic decision-making processes.")}</li>
                                                <li>{t("Small-sized companies offer interns opportunities for skill development and professional growth within a supportive and collaborative environment. Interns may receive training and mentorship to enhance their capabilities and prepare for future career opportunities.")}</li>
                                                <li>{t("Medium-sized companies provide interns with access to structured training programs, professional development resources, and networking opportunities. Interns may participate in workshops, webinars, and industry events to expand their knowledge and build their professional network.")}</li>
                                            </ul>
                                        </ul>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("In which countries can I do a remote internship with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("You can do a remote internship in Austria, Azerbaijan, Belgium, Canada, Croatia, Czechia, Denmark, England, Estonia, Finland, France, Germany, Greece, Holland, Ireland, Italy, Latvia, Luxembourg, Norway, Poland, Portugal, Spain, Sweden, Switzerland, and the USA.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("In which internship areas can I do a remote internship with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("You can do a remote internship in the field of Software Development, Marketing, Content Writing, Social Media Management, Graphic Design, Web Development, Data Entry, Market Research, Digital Marketing, Copywriting, SEO (Search Engine Optimization), Email Marketing, Sales, Business Development, Virtual Assistance, Video Editing, Project Management, Customer Support, UI/UX Design, Public Relations, Content Creation, Blogging, Research Analysis, Financial Analysis, Human Resources, E-commerce Management, Quality Assurance, Translation and Localization, Mobile App Development, IT Support, Data Analysis, Market Analysis, Financial Analysis, Human Resources, Business Development, Customer Service, Project Management, Quality Assurance, Technical Support, Legal Research, Data Entry, Graphic Design, Video Editing, Audio Production, UX/UI Design, Content Creation, Virtual Reality Development, Augmented Reality Development, Game Development, and Ethical Hacking.")}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTabSectionValue} index={1} p={0}>
                        <Box sx={{ border: "1px solid #E0E0E0", borderRadius: 2, padding: 1 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {t("What packages are available under InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("InternIF Premium offers three packages: Standard, Pro, and Special. Each package provides different features and benefits to enhance the internship experience.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    {t("How do I choose the right InternIF Premium package for me?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("You can choose the right package based on factors such as company selection options, support services, and internship location preferences to ensure it aligns with your goals and requirements.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("Can I customize my internship preferences with InternIF Premium Packages?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Yes, you can specify your preferred internship location and sector when using any of the InternIF Premium Packages. However, the level of customization may vary depending on the package chosen.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("Can I customize my InternIF Premium package?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("While InternIF Premium packages come with predefined features, you can discuss customization options with InternIF support to tailor the package to your specific needs and preferences.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("What is included in the Standard Package?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("The Standard Package includes essential features such as internship placement in micro-scale enterprises, general support, orientation, mentoring, and certificates upon successful completion.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("What additional benefits does the Pro Package offer?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("The Pro Package provides the option to choose from three pre-selected companies, dedicated support from an InternIF member, and placement in small-scale enterprises, in addition to the features included in the Standard Package.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("What makes the Special Package unique?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("The Special Package offers internship placement in medium-scale enterprises and personalized support from a dedicated InternIF member, along with the features included in the Standard Package.")}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTabSectionValue} index={2} p={0}>
                        <Box sx={{ border: "1px solid #E0E0E0", borderRadius: 2, padding: 1 }}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {t("Can you detail the Remote Internship Process with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("The Remote Internship Process with InternIF Premium is designed to provide students with a seamless and enriching international internship experience. Here's a breakdown of the process:")}
                                    <ul>
                                        <li>{t("Initiation")}:</li>
                                        <ul>
                                            <li>{t("Upon purchasing an InternIF Premium package, students initiate the internship process by clicking on the 'Use Package' button in their dashboard.")}</li>
                                        </ul>
                                        <li>{t("Internship Configuration")}:</li>
                                        <ul>
                                            <li>{t("Students specify their desired internship parameters, including the preferred time, country, sector, and weekly working hours (20 to 30 hours). They may also provide additional information relevant to their internship preferences.")}</li>
                                        </ul>
                                        <li>{t("InternIF Review")}:</li>
                                        <ul>
                                            <li>{t("The InternIF team reviews the submitted internship configuration and identifies suitable internship opportunities based on the student's preferences and qualifications.")}</li>
                                        </ul>
                                        <li>{t("Company Matching")}:</li>
                                        <ul>
                                            <li>{t("InternIF matches students with internship opportunities at partner companies, taking into account factors such as the student's profile, company requirements, and available positions.")}</li>
                                        </ul>
                                        <li>{t("Internship Commencement")}:</li>
                                        <ul>
                                            <li>{t("Once the internship details are finalized, students receive confirmation of their internship placement and begin their remote internship at the designated company.")}</li>
                                        </ul>
                                        <li>{t("Task Assignment")}:</li>
                                        <ul>
                                            <li>{t("Interns receive their first task from the company between 09:00-12:00, depending on the time zone of the internship location. Tasks may vary in complexity and duration, spanning implementation, design, review, evaluation, editing, reporting, and research.")}</li>
                                        </ul>
                                        <li>{t("Task Completion")}:</li>
                                        <ul>
                                            <li>{t("Interns complete assigned tasks within the specified timeframe, typically over a period of 20 working days. Tasks may be assigned on a daily, two-day, or three-day basis, depending on the nature of the internship project.")}</li>
                                        </ul>
                                        <li>{t("Evaluation and Feedback")}:</li>
                                        <ul>
                                            <li>{t("Upon task completion, interns may receive feedback and evaluation from the company based on their performance and deliverables. Task evaluations play a crucial role in assessing the intern's overall success and performance during the internship.")}</li>
                                        </ul>
                                        <li>{t("Internship Completion")}:</li>
                                        <ul>
                                            <li>{t("Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates (for Turkish citizens), and reference letters (for high-performing interns).")}</li>
                                        </ul>
                                        <li>{t("Ongoing Support")}:</li>
                                        <ul>
                                            <li>{t("Throughout the internship process, InternIF provides dedicated support to interns, addressing any queries, concerns, or challenges they may encounter during their internship journey.")}</li>
                                        </ul>
                                        <li>{t("The Remote Internship Process with InternIF Premium offers students a structured and supportive framework to gain valuable international work experience and advance their career goals.")}</li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTabSectionValue} index={3} p={0}>
                        <Box sx={{ border: "1px solid #E0E0E0", borderRadius: 2, padding: 1 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    {t("How are internship placements determined in InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Internship placements in InternIF Premium are determined based on factors such as student preferences, qualifications, and the availability of internship opportunities from partner companies.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("Can I choose the location and sector of my internship?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Yes, InternIF Premium allows you to specify your preferred internship location and sector, ensuring that the placement aligns with your interests and career goals.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("What types of companies offer internships through InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("InternIF Premium partners with a diverse range of companies, including micro, small, and medium-scale enterprises, offering internship opportunities across various industries and sectors.")}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTabSectionValue} index={4} p={0}>
                        <Box sx={{ border: "1px solid #E0E0E0", borderRadius: 2, padding: 1 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("What support services are available during the internship with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Interns in InternIF Premium receive comprehensive support services, including orientation sessions, dedicated support from an InternIF member, and mentoring throughout the internship duration.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("What are the expectations during the internship with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Interns are expected to complete assigned tasks diligently, maintain regular communication with their supervisors, and adhere to the company's policies and guidelines throughout the internship period.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("Can I request additional assistance or support during my internship with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Yes, interns enrolled in InternIF Premium can request additional assistance or support from InternIF, as needed. The support team is available to address any internship-related issues or concerns promptly.")}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTabSectionValue} index={5} p={0}>
                        <Box sx={{ border: "1px solid #E0E0E0", borderRadius: 2, padding: 1 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {t("Can you detail the evaluation of internship with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("The evaluation process for internships with InternIF Premium is designed to assess interns' performance and provide recognition for their achievements. Here's a breakdown of the evaluation process:")}
                                    <ul>
                                        <li>{t("Success Criteria")}:</li>
                                        <ul>
                                            <li>{t("InternIF establishes success criteria for interns, typically requiring a minimum success rate of 70% to consider the internship valid.")}</li>
                                        </ul>
                                        <li>{t("Company Evaluation")}:</li>
                                        <ul>
                                            <li>{t("Interns' performance during the internship is evaluated by the company they intern with. The company assesses interns based on their task completion, quality of work, professionalism, and adherence to deadlines.")}</li>
                                        </ul>
                                        <li>{t("Certificate of Completion")}:</li>
                                        <ul>
                                            <li>{t("Interns who achieve the minimum success rate of 70% receive a certificate of completion from the company they intern with. This certificate serves as formal recognition of their successful participation in the internship program.")}</li>
                                        </ul>
                                        <li>{t("Company Matching")}:</li>
                                        <ul>
                                            <li>{t("InternIF matches students with internship opportunities at partner companies, taking into account factors such as the student's profile, company requirements, and available positions.")}</li>
                                        </ul>
                                        <li>{t("Practical Training Certificate")}:</li>
                                        <ul>
                                            <li>{t("For Turkish citizens who achieve a success rate of over 80%, InternIF provides a practical training certificate that can be viewed on their e-government platform. This certificate acknowledges their practical training experience and enhances their professional credentials.")}</li>
                                        </ul>
                                        <li>{t("Reference Letter")}:</li>
                                        <ul>
                                            <li>{t("Exceptional interns who attain a success rate of over 95% are eligible to receive a reference letter from the company where they interned. This personalized letter highlights the intern's outstanding performance and serves as a valuable endorsement for future career opportunities.")}</li>
                                        </ul>
                                        <li>{t("InternIF Evaluation and Support")}:</li>
                                        <ul>
                                            <li>{t("InternIF also evaluates interns' performance and provides ongoing support throughout the internship process. Interns can reach out to InternIF for assistance with any challenges they encounter during their internship.")}</li>
                                        </ul>
                                        <li>{t("The evaluation process with InternIF Premium ensures that interns receive recognition for their efforts and achievements, enhancing their professional development and career prospects.")}</li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("How is internship success evaluated in InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Internship success in InternIF Premium is evaluated based on various factors, including task completion, performance feedback from the company, and overall professionalism demonstrated during the internship.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("What certificates or documents will I receive upon completing my internship with InternIF Premium?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Upon successful completion of the internship, interns may receive certificates of completion, practical training certificates, and reference letters, depending on their performance and achievements during the internship period.")}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{ fontWeight: 600 }}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    {t("Will InternIF Premium assist me with future career opportunities after completing my internship?")}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {t("Yes, InternIF Premium provides ongoing support to successful interns, offering guidance and assistance with future career opportunities, including job placement services, resume building, and networking opportunities within the industry.")}
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </CustomTabPanel>
                </Grid>

            </Grid>

            {upLg && <Grid item xs={12} lg={4} xl={3}>
                <Card sx={{
                    padding: 3, backgroundColor: "#F8F8F8", border: '1px solid #E0E0E0',
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                        <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >{parse(tipTitle)}</Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
                            {parse(tipDescription)}
                        </Typography>
                    </Box>

                </Card>
            </Grid>}
        </Grid>

        <Dialog open={openIyzico} onClose={() => setOpenIyzico(false)} aria-labelledby="form-dialog-title" maxWidth="md" >
            <DialogTitle>
                {t('Payment with Iyzico')}
            </DialogTitle>
            <DialogContent sx={{ width: { xs: '100%', sm: '500px', md: '600px' }, height: '500px', overflowY: 'hidden', padding: 0 }}>
                <div style={{ width: '100%', height: '100%', }}>
                    <iframe
                        allowFullScreen={true}
                        referrerPolicy='origin'
                        ref={gridIframe}
                        // dangerouslySetInnerHTML={{ __html: iyzicoContent }}
                        allow='autoplay *; fullscreen *; encrypted-media *; picture-in-picture *; geolocation *; microphone *; camera *; midi *'
                        width={'100%'}
                        height={'100%'}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        title="iyzico"
                        src={iyzicoUrl}
                        onLoad={() => setOnLoad(true)}
                    />
                </div>
            </DialogContent>
        </Dialog>


        <Dialog open={openResult} onClose={() => {
            dispatch(intern_packagesFunc({ accessToken })).then(res => {
                setUsableStandard(res.payload.intern_packages?.data?.filter(item => item.packageType === "standard" && item.status === "notUsed"))
                setUsablePro(res.payload.intern_packages?.data?.filter(item => item.packageType === "pro" && item.status === "notUsed"))
                setUsableSpecial(res.payload.intern_packages?.data?.filter(item => item.packageType === "special" && item.status === "notUsed"))
            })
            window.location.reload()
            setOpenResult(false)
        }} aria-labelledby="form-dialog-title"  >
            <DialogTitle>
                {t('Payment Result')}
            </DialogTitle>
            <DialogContent sx={{
                width: { xs: '100%', sm: '500px', md: '600px' }, padding: { xs: 5, sm: 5 }, display: "flex", flexDirection: "column",
                justifyContent: "flex-start", alignItems: "center"
            }}>
                {(paymentResult !== "completed" && paymentResult !== "waiting") && <>
                    <Typography variant="h6" textAlign={"center"} mb={5}>Please wait...</Typography>
                    <CircularProgress />
                </>}
                {paymentResult === "completed" && <>
                    <Typography variant="h6" textAlign={"center"} mb={5}>Payment is completed successfully.</Typography>
                    <Lottie
                        animationData={PaymentSuccess}
                        style={{ width: '50%', height: '50%' }}
                        autoplay={true}
                        loop={false}
                    />
                </>}
                {paymentResult === "waiting" && <>
                    <Typography variant="h6" textAlign={"center"} mb={5}>The payment process took longer than usual. Please check your account by refreshing the page..</Typography>
                </>}
                {/* {paymentResult === 'aborted' && paymentResult && <Lottie
                        animationData={PaymentFailed}
                        style={{ width: '60%', height: '60%' }}
                        autoplay={true}
                        loop={false}
                    />} */}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={() => {
                    dispatch(intern_packagesFunc({ accessToken })).then(res => {
                        setUsableStandard(res.payload.intern_packages?.data?.filter(item => item.packageType === "standard" && item.status === "notUsed"))
                        setUsablePro(res.payload.intern_packages?.data?.filter(item => item.packageType === "pro" && item.status === "notUsed"))
                        setUsableSpecial(res.payload.intern_packages?.data?.filter(item => item.packageType === "special" && item.status === "notUsed"))
                    })
                    window.location.reload()
                    setOpenResult(false)
                }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openUsePackage} onClose={() => setOpenUsePackage(false)} aria-labelledby="form-dialog-title" maxWidth="md" >
            <DialogTitle>
                {t('Use Package')}
            </DialogTitle>
            <DialogContent maxWidth="md" sx={{ width: '500px', overflowY: "hidden" }}>

                <Typography mb={2}>{t('Can you give us some information about how you will use the premium package?')}</Typography>
                <Grid >
                    <FormComponent
                        country={[1, 12, 12, 12, 12, 12]}
                        date={[2, 12, 12, 12, 12, 12]}
                        internshipArea2={[3, 12, 12, 12, 12, 12]}
                        weeklyWorkHours={[4, 12, 12, 12, 12, 12]}
                        extraInfo={[5, 12, 12, 12, 12, 12]}
                        parameters={{
                            countries: countries?.filter(country => [
                                'Austria',
                                'Azerbaijan',
                                'Belgium',
                                'Canada',
                                'Croatia',
                                'Czech Republic',
                                'Denmark',
                                'Estonia',
                                'Finland',
                                'France',
                                'Germany',
                                'Greece',
                                'Ireland',
                                'Italy',
                                'Latvia',
                                'Luxembourg',
                                'Netherlands',
                                'Norway',
                                'Poland',
                                'Portugal',
                                'Spain',
                                'Sweden',
                                'Switzerland',
                                'United Kingdom',
                                'United States'
                            ].includes(country.name)),
                            internshipArea2List: i18next.language === "en" ? [
                                { name: "Software Development" },
                                { name: "Marketing" },
                                { name: "Content Writing" },
                                { name: "Social Media Management" },
                                { name: "Graphic Design" },
                                { name: "Web Development" },
                                { name: "Data Entry" },
                                { name: "Market Research" },
                                { name: "Digital Marketing" },
                                { name: "Copywriting" },
                                { name: "SEO (Search Engine Optimization)" },
                                { name: "Email Marketing" },
                                { name: "Sales" },
                                { name: "Business Development" },
                                { name: "Virtual Assistance" },
                                { name: "Video Editing" },
                                { name: "Project Management" },
                                { name: "Customer Support" },
                                { name: "UI/UX Design" },
                                { name: "Public Relations" },
                                { name: "Content Creation" },
                                { name: "Blogging" },
                                { name: "Research Analysis" },
                                { name: "Financial Analysis" },
                                { name: "Human Resources" },
                                { name: "E-commerce Management" },
                                { name: "Quality Assurance" },
                                { name: "Translation and Localization" },
                                { name: "Mobile App Development" },
                                { name: "IT Support" },
                                { name: "Data Analysis" },
                                { name: "Market Analysis" },
                                { name: "Financial Analysis" },
                                { name: "Human Resources" },
                                { name: "Business Development" },
                                { name: "Customer Service" },
                                { name: "Project Management" },
                                { name: "Quality Assurance" },
                                { name: "Technical Support" },
                                { name: "Legal Research" },
                                { name: "Data Entry" },
                                { name: "Graphic Design" },
                                { name: "Video Editing" },
                                { name: "Audio Production" },
                                { name: "UX/UI Design" },
                                { name: "Content Creation" },
                                { name: "Virtual Reality Development" },
                                { name: "Augmented Reality Development" },
                                { name: "Game Development" },
                                { name: "Ethical Hacking" }
                            ] : [
                                { name: "Yazılım Geliştirme" },
                                { name: "Pazarlama" },
                                { name: "İçerik Yazma" },
                                { name: "Sosyal Medya Yönetimi" },
                                { name: "Grafik Tasarım" },
                                { name: "Web Geliştirme" },
                                { name: "Veri Girişi" },
                                { name: "Pazar Araştırması" },
                                { name: "Dijital Pazarlama" },
                                { name: "Metin Yazarlığı" },
                                { name: "Seo (Arama Motoru Optimizasyonu)" },
                                { name: "Eposta Pazarlama" },
                                { name: "Satış" },
                                { name: "İş Geliştirme" },
                                { name: "Sanal Yardım" },
                                { name: "Video Düzenleme" },
                                { name: "Proje Yönetimi" },
                                { name: "Müşteri Desteği" },
                                { name: "Uı/Ux Tasarımı" },
                                { name: "Halkla İlişkiler" },
                                { name: "İçerik Yaratımı" },
                                { name: "Bloglama" },
                                { name: "Araştırma Analizi" },
                                { name: "Finansal Analiz" },
                                { name: "İnsan Kaynakları" },
                                { name: "E-Ticaret Yönetimi" },
                                { name: "Kalite Güvencesi" },
                                { name: "Çeviri Ve Yerelleştirme" },
                                { name: "Mobil Uygulama Geliştirme" },
                                { name: "Bilişim Destek" },
                                { name: "Veri Analizi" },
                                { name: "Pazar Araştırması" },
                                { name: "Finansal Analiz" },
                                { name: "İnsan Kaynakları" },
                                { name: "İş Geliştirme" },
                                { name: "Müşteri Servisi" },
                                { name: "Proje Yönetimi" },
                                { name: "Kalite Güvencesi" },
                                { name: "Teknik Destek" },
                                { name: "Yasal Araştırma" },
                                { name: "Veri Girişi" },
                                { name: "Grafik Tasarım" },
                                { name: "Video Düzenleme" },
                                { name: "Ses Prodüksiyonu" },
                                { name: "Kullanıcı Deneyimi/Uı Tasarımı" },
                                { name: "İçerik Yaratımı" },
                                { name: "Sanal Gerçeklik Geliştirme" },
                                { name: "Artırılmış Gerçeklik Geliştirme" },
                                { name: "Oyun Geliştirme" },
                                { name: "Etik Hackleme" }
                            ]
                        }}
                        setSelectedCountry={setSelectedCountry}
                        handleSubmitForm={handleSubmitForm}
                        setValues={setValues}
                    />
                </Grid>
                <Box height={50} />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary' onClick={() => {
                    setOpenUsePackage(false)
                    dispatch(self_user_billingInfoFunc({ accessToken }))
                }}>
                    {t("Cancel")}
                </Button>
                <Button variant='contained' color='primary'
                    disabled={!values?.country || !values?.date || !values?.internshipArea2 || !values?.weeklyWorkHours}
                    onClick={() => {
                        handleUsePackage(true)
                    }
                    }>
                    {t('Use Package')}
                </Button>
            </DialogActions>


        </Dialog>


        <Dialog open={selectedUsedPackage} onClose={() => setSelectedUsedPackage(null)} aria-labelledby="form-dialog-title" maxWidth="lg" >
            <DialogTitle>
                {t('Premium Package History')}
            </DialogTitle>
            <DialogContent sx={{ width: { xs: '100%', sm: '600px' }, pb: 10 }}>
                <Typography variant="h6" fontWeight={"bold"} mb={2} >{selectedUsedPackage}</Typography>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>{t("Date")}</TableCell>
                                <TableCell>{t("Status")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows2.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.type}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Label sx={{ width: '80px' }} color={row.status === "researching" ? "info" : "warning"}>{row.status}</Label>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TextField
                    sx={{ mt: 5 }}
                    label={t("Additional Information")}
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    placeholder="I am using this package for..."
                />
                <Button variant='contained' color='primary' sx={{ mt: 1 }} >
                    Save
                </Button>


            </DialogContent>
        </Dialog>

        <Dialog open={openProductCode}
            onClose={() => {
                setOpenProductCode(false)
                setProductCode(null)
                setSelectedPackageType(null)
            }}
            aria-labelledby="form-dialog-title" maxWidth="md" >
            <DialogTitle>
                {selectedPackageType} {t('Package')}
            </DialogTitle>
            <DialogContent style={{ width: '500px' }}>
                <Typography variant='body1' mb={5}>
                    You are directed to the standard package purchase page.
                </Typography>
                <Select
                    label="Package Type"
                    variant="outlined"
                    fullWidth
                    value={isHaveProductCode}
                    onChange={(e) => setIsHaveProductCode(e.target.value)}
                >
                    <MenuItem value={false}>I don't have a promotion code</MenuItem>
                    <MenuItem value={true}>I have a promotion code</MenuItem>
                </Select>

                {isHaveProductCode && <>
                    <TextField
                        sx={{ mt: 2 }}
                        label="Promotion Code"
                        variant="outlined"
                        fullWidth
                        type='text'
                        placeholder="Enter Promotion Code"
                        value={productCode}
                        onChange={(e) => setProductCode(e.target.value)}
                    />
                    {errorProductCode && <Stack direction="row" alignItems="center" pl={1} pt={1} gap={1} >

                        <Typography fontSize={"small"} textAlign={"left"} color='error'>{errorProductCode === "Argument passed in must be a string of 12 bytes or a string of 24 hex characters or an integer" ?
                            "You entered the wrong code!" : errorProductCode === "InvalidProductCode" ? "You entered the wrong code!" : ""}</Typography>
                        <Typography fontSize={"small"}
                            sx={{ cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => handleClickOpenBuy({ packageType: selectedPackageType, withoutPcode: true })}
                        >Click to continue purchasing.</Typography>
                    </Stack>
                    }
                </>}
                <Box height={50} />
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant='outlined' color='primary' onClick={() => {
                    setOpenProductCode(false)
                    setProductCode(null)
                    setSelectedPackageType(null)
                }}>
                    Cancel
                </Button>
                <Button variant='contained' color='primary'
                    disabled={loadingBuy}
                    onClick={() => {
                        handleClickOpenBuy({ packageType: selectedPackageType })
                    }}>
                    {loadingBuy ? <CircularProgress size={"1rem"} /> : "Buy Package"}
                </Button>
            </DialogActions>
        </Dialog>


    </DashboardLayout >
}

export default Premium