import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack'
import AuthLayout from 'layouts/AuthLayout';
import { AuthHead, AuthSeperator, AuthSocialLogin, AuthSigninForm } from 'page-sections/auth';
import { signinFunc } from 'context';
import { setAccessToken, setUserRole, setUserId, setEmail, setRemember } from 'context/common/user';
import { Button } from '@mui/material';
import { FileCopy } from '@mui/icons-material';




const Signin = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleSignin = (values) => {
        dispatch(signinFunc(values)).then((res) => {

            const userId = res.payload?.user?.userId;
            const role = res.payload?.user?.role;
            const token = res.payload?.accessToken;
            const status = res.payload?.response?.status;
            const twoFa = res.payload?.code && res.payload?.token;
            localStorage.setItem('remember', values.remember)
            dispatch(setRemember(values.remember))

            if (token) {
                if (role === 'companyAdmin' || role === 'intern' || role === 'highSchoolIntern') {
                    enqueueSnackbar('Welcome to InternIF!', { variant: 'success' })
                    localStorage.setItem('accessToken', token);
                    localStorage.setItem('userRole', role);
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('email', values.email);
                    dispatch(setAccessToken(token));
                    dispatch(setUserRole(role));
                    dispatch(setUserId(userId));
                    dispatch(setEmail(values.email));
                    navigate('/');
                }
                if (role === 'b2bSalesRep' || role === 'b2bCompanyRep' || role === 'b2bInternRep' || role === 'b2bClient') {
                    enqueueSnackbar("You need to login from the B2B Website!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => { window.location.href = 'https://biz.internif.com' }, 2500)
                    localStorage.clear()
                }
                if (role === 'internIFManager' || role === 'internIFFinance') {
                    enqueueSnackbar("You need to login from the Management Website!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => { window.location.href = 'https://manage.internif.com' }, 2500)
                    localStorage.clear()
                }
                if (role === 'universityAdmin') {
                    enqueueSnackbar("You need to login from the University Website!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => { window.location.href = 'https://uni.internif.com' }, 2500)
                    localStorage.clear()
                }
            } else if (twoFa) {
                enqueueSnackbar('The 2FA is required. Please check your mailbox!', { variant: 'warning' })
                console.log(res?.payload?.data)
                navigate('/2fa-signin?token=' + res?.payload?.token)
                process.env.REACT_APP_TEST === "true" && enqueueSnackbar(<div>
                    {res?.payload.code}
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            const textToCopy = `${res?.payload.code}`
                            const textArea = document.createElement('textarea');
                            textArea.value = textToCopy;
                            document.body.appendChild(textArea);
                            textArea.select();
                            document.execCommand('copy');
                            document.body.removeChild(textArea);
                        }}
                    >
                        Copy Code
                        <FileCopy />
                    </Button>
                </div>, { variant: 'warning', autoHideDuration: 6000 });
            }

            else if (status === 401) {
                enqueueSnackbar('Please check your email or password', { variant: 'error' })
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
            }
        }).catch(() => {
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
        })
    }



    return <AuthLayout>

        <AuthHead
            title="Sign In Now"
            description="Let's start to transform the future of work!"
        />

        <AuthSocialLogin
            type="signin"
        />

        <AuthSeperator
            type="sign in"
        />

        <AuthSigninForm
            handleSignin={handleSignin}
        />

    </AuthLayout >;
}

export default Signin;

