import { useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next'
import {
    Box, Typography, IconButton, TextField, Grid, Card, Menu, MenuItem, Slide, Dialog, DialogTitle, DialogContent,
    DialogActions, Button, FormGroup, FormControlLabel, Checkbox, Autocomplete,
} from '@mui/material';
import { SettingsContainer, Dropdown } from 'components';
import EducationIcon from 'assets/icons/onboarding/upper-text-education-detail-icon.png';
import HighSchoolIcon from 'assets/icons/onboarding/high-school-icon.png';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { AccountBalance, DeleteOutline, School, Update } from '@mui/icons-material';
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useFormik } from "formik";
import {
    intern_addUniversityEducationFunc, intern_updateUniversityEducationFunc, intern_removeUniversityEducationFunc,
    self_internFunc, intern_addHighSchoolEducationFunc, intern_updateHighSchoolEducationFunc, intern_removeHighSchoolEducationFunc
} from 'context';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ResumeEducations = ({ user, userRole }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { user_parameters_educationsData: educations } = useSelector((state) => state.parameters);
    const [openHighSchool, setOpenHighSchool] = useState(false);
    const [university, setUniversity] = useState("")
    const [universities, setUniversities] = useState([])
    const [selectedUniversities, setSelectedUniversities] = useState([])
    const [selectedHighSchools, setSelectedHighSchools] = useState([])
    const [openUniversity, setOpenUniversity] = useState(false)
    const [checkStillAttending, setCheckStillAttending] = useState(false)
    const [editableUniversity, setEditableUniversity] = useState(null)
    const [editableHighSchool, setEditableHighSchool] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFieldValue("university", "")
        setFieldValue("department", "")
        setFieldValue("startDate", null)
        setFieldValue("finishDate", null)
        setFieldValue("universityName", "")
        setCheckStillAttending(false);
        setEditableHighSchool(null)
        setEditableUniversity(null)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const userInitialValues = {
        university: "",
        universityName: "",
        department: "",
        startDate: null,
        finishDate: null,
        birthDate: user?.personalInformation?.birthDate ? new Date(user?.personalInformation?.birthDate) : null,
        highSchoolName: "",
        highSchoolDepartment: "",
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        initialValues: userInitialValues,
        onSubmit: null
    })


    const handleAddUniversity = () => {

        intern_addUniversityEducationFunc({
            selectedUniversity: {
                university: {
                    "_id": values.university._id,
                    "name": values.university.name,
                },
                department: {
                    "_id": values.department,
                    "name": educations?.universityDepartments?.data?.filter?.(department => department?._id === values.department)?.[0]?.name
                },
                startDate: values.startDate,
                finishDate: values.finishDate
            },
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                enqueueSnackbar("The university has been added!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })


        setOpenUniversity(false)

        setFieldValue("university", "")
        setFieldValue("department", "")
        setFieldValue("startDate", null)
        setFieldValue("finishDate", null)
        setFieldValue("universityName", "")
        setCheckStillAttending(false)
    }

    const handleUpdateUniversity = () => {

        intern_updateUniversityEducationFunc({
            selectedUniversity: {
                university: {
                    "_id": values.university._id,
                    "name": values.university.name,
                },
                department: {
                    "_id": values.department,
                    "name": educations?.universityDepartments?.data?.filter?.(department => department?._id === values.department)?.[0]?.name
                },
                startDate: values.startDate,
                finishDate: checkStillAttending ? null : values.finishDate
            },
            id: selectedUniversities[editableUniversity.index]?.id,
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                enqueueSnackbar("The university has been updated!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })

        setOpenUniversity(false)
        setFieldValue("university", "")
        setFieldValue("department", "")
        setFieldValue("startDate", null)
        setFieldValue("finishDate", null)
        setFieldValue("universityName", "")
        setCheckStillAttending(false)
    }

    const handleRemoveUniversity = () => {
        setSelectedUniversities(selectedUniversities?.filter((item, index) => index !== editableUniversity.index))

        intern_removeUniversityEducationFunc({
            id: selectedUniversities[editableUniversity.index]?.id,
            accessToken: accessToken
        }).then(res => {
            if (res?.success) {
                enqueueSnackbar("The university has been removed!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })

        setOpenUniversity(false)
        setFieldValue("university", "")
        setFieldValue("department", "")
        setFieldValue("startDate", null)
        setFieldValue("finishDate", null)
        setFieldValue("universityName", "")
        setCheckStillAttending(false)
    }


    const handleAddHighSchool = () => {

        intern_addHighSchoolEducationFunc({
            values: {
                name: values.highSchoolName,
                department: { "_id": values.highSchoolDepartment, },
                startDate: values.startDate,
                finishDate: values.finishDate
            },
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                enqueueSnackbar("The high school has been added!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
                setOpenHighSchool(false)
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })
    }

    const handleUpdateHighSchool = () => {

        intern_updateHighSchoolEducationFunc({
            values: {
                name: values.highSchoolName,
                department: { "_id": values.highSchoolDepartment, },
                startDate: values.startDate,
                finishDate: values.finishDate
            },
            id: selectedHighSchools[editableHighSchool.index]?.id,
            accessToken: accessToken
        }).then(res => {
            if (res?._id) {
                enqueueSnackbar("The high school has been updated!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
                setOpenHighSchool(false)
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })

    }

    const handleRemoveHighSchool = () => {
        intern_removeHighSchoolEducationFunc({
            id: selectedHighSchools[editableHighSchool.index]?.id,
            accessToken: accessToken
        }).then(res => {
            if (res?.success) {
                enqueueSnackbar("The high school has been removed!", { variant: 'success' });
                dispatch(self_internFunc({ accessToken: accessToken }))
                setOpenHighSchool(false)
            } else {
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
            }
        })
    }

    useEffect(() => {
        if (university?.length >= 2) {
            const selected = educations?.universities?.data?.filter?.(item => item.name.toLowerCase().includes(university.toLowerCase()))
            setUniversities(selected)
        } else (
            setUniversities([])
        )
    }, [university, educations?.universities?.data])


    useEffect(() => {
        setSelectedHighSchools(user?.cv?.education?.highSchoolEducations?.map?.(item => {
            return {
                id: item?._id,
                name: item?.name,
                department: {
                    "_id": item?.department?._id,
                    "name": educations?.highSchoolDepartments?.data?.filter?.(department => department?._id === item?.department?._id)?.[0]?.name
                },
                startDate: item?.startDate,
                finishDate: item?.finishDate
            }
        }))
    }, [user?.cv?.education?.highSchoolEducations, educations?.highSchoolDepartments?.data])


    useEffect(() => {
        setSelectedUniversities(user?.cv?.education?.universityEducations?.map?.(item => {
            return {
                id: item?._id,
                university: {
                    "_id": item?.university?._id,
                    "name": item?.university?.name,
                },
                department: {
                    "_id": item?.department?._id,
                    "name": educations?.universityDepartments?.data?.filter?.(department => department?._id === item?.department?._id)?.[0]?.name
                },
                startDate: item?.startDate,
                finishDate: item?.finishDate
            }
        }))
    }, [user?.cv?.education?.universityEducations, educations?.universityDepartments?.data])

    return <>
        <SettingsContainer pb={2} mb={3}>

            <Box pl={3} pt={3} pr={3}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h5">
                        {t('Educations')}
                    </Typography>
                    {userRole !== "highSchoolIntern" && <IconButton
                        sx={{ ml: 1 }}
                        aria-label="edit"
                        onClick={handleClick}
                    >
                        <AddIcon />
                    </IconButton>}
                </Box>
                <>
                    {(selectedUniversities?.length === 0 && selectedHighSchools?.length === 0) && <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" color={"grey.600"} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center', pb: 4 }}>   No university added yet. </Typography>
                        </Grid>
                    </Grid>}
                    <Grid container sx={{ width: '100%', mt: "10px", gap: { xs: 1, sm: 0 } }} spacing={{ xs: 0, sm: 2 }}>
                        {selectedHighSchools?.length > 0 &&
                            selectedHighSchools?.map((item, index) => (
                                <Grid item xs={12} md={6}>
                                    <Card sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2,
                                        '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)", cursor: "pointer" }
                                    }} onClick={() => {
                                        setOpenHighSchool(true)
                                        setEditableHighSchool({ index: index })
                                        setFieldValue("highSchoolName", item?.name)
                                        setFieldValue("highSchoolDepartment", item?.department?._id)
                                        setFieldValue("startDate", new Date(item?.startDate))
                                        setFieldValue("finishDate", item?.finishDate ? new Date(item?.finishDate) : null)
                                    }
                                    }>
                                        <img src={HighSchoolIcon} alt="education" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {item?.name} </Typography>
                                        <Typography variant="h7" sx={{ fontWeight: 'bold', textAlign: 'center' }}> {educations?.highSchoolDepartments?.data?.filter?.(department => department?._id === item?.department?._id)?.[0]?.name} </Typography>
                                        <Typography variant="h8" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {format(new Date(selectedHighSchools[index]?.startDate), 'MMM yyyy')} /
                                            {selectedHighSchools[index]?.finishDate ? format(new Date(selectedHighSchools[index]?.finishDate), ' MMM yyyy') : ' Still Attending'}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))
                        }
                        {selectedUniversities?.length > 0 &&
                            selectedUniversities?.map((item, index) => (
                                <Grid item xs={12} md={6}>
                                    <Card sx={{
                                        width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2,
                                        '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)", cursor: "pointer" }
                                    }} onClick={() => {
                                        setOpenUniversity(true)
                                        setEditableUniversity({ index: index })
                                        setFieldValue("university", item?.university)
                                        setFieldValue("department", item?.department?._id)
                                        setFieldValue("startDate", new Date(item?.startDate))
                                        setFieldValue("finishDate", item?.finishDate ? new Date(item?.finishDate) : null)
                                        setCheckStillAttending(!item?.finishDate)
                                    }}>
                                        <img src={EducationIcon} alt="education" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {item?.university?.name} </Typography>
                                        <Typography variant="h7" sx={{ fontWeight: 'bold', textAlign: 'center' }}> {educations?.universityDepartments?.data?.filter?.(department => department?._id === item?.department?._id)?.[0]?.name} </Typography>
                                        <Typography variant="h8" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {format(new Date(selectedUniversities[index]?.startDate), 'MMM yyyy')} /
                                            {selectedUniversities[index]?.finishDate ? format(new Date(selectedUniversities[index]?.finishDate), ' MMM yyyy') : ' Still Attending'}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))
                        }

                    </Grid>
                </>
            </Box>

        </SettingsContainer>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}

        >
            <MenuItem
                disabled={selectedHighSchools?.length >= 1}
                onClick={() => {
                    handleClose();
                    setOpenHighSchool(true);
                }} disableRipple>
                <School fontSize='small' sx={{ mr: 1 }} />
                Add High School
            </MenuItem>
            <MenuItem
                disabled={selectedUniversities?.length > 3}
                onClick={() => {
                    handleClose();
                    setOpenUniversity(true);
                }} disableRipple>
                <AccountBalance fontSize='small' sx={{ mr: 1 }} />
                Add University
            </MenuItem>
        </Menu>


        <Dialog
            open={openUniversity}
            keepMounted
            onClose={() => setOpenUniversity(false)}
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={Transition}
        >
            <DialogTitle>{editableUniversity ? 'Update University' : 'Add University'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            name="university"
                            noOptionsText={"No university found"}
                            options={universities ?? []}
                            autoHighlight
                            getOptionLabel={(option) => option?.name ?? ""}
                            isOptionEqualToValue={(option, value) => option?._id === value?._id}
                            value={values.university}
                            onChange={(event, newValue) => { setFieldValue("university", newValue) }}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    name="university"
                                    value={university}
                                    onChange={(event, newValue) => { setUniversity(event.target.value) }}
                                    {...params}
                                    label="University"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Dropdown
                            setFieldValue={setFieldValue}
                            errors={errors}
                            data={educations?.universityDepartments?.data}
                            label={"Department"}
                            labelId={"department-label"}
                            value={values.department}
                            id={"department"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDPicker
                            sx={{ width: '100%' }}
                            label="Start Date"
                            value={values.startDate}
                            openTo="month"
                            onChange={(value) => setFieldValue("startDate", value)}
                            name="startDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                            minDate={values.birthDate}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDPicker
                            disabled={checkStillAttending || !values.startDate}
                            sx={{ width: '100%' }}
                            label={!checkStillAttending ? "Finish Date" : "Still attending"}
                            value={values.finishDate}
                            openTo="month"
                            onChange={(value) => setFieldValue("finishDate", value)}
                            name="finishDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                            minDate={values.startDate}
                        />
                        <FormGroup sx={{ mt: 1 }}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={checkStillAttending}
                                    onChange={(e) => {
                                        setCheckStillAttending(e.target.checked)
                                        setFieldValue("finishDate", null)
                                    }}
                                />} label="Still attending" />
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => setOpenUniversity(false)}>Cancel</Button>
                {editableUniversity && <Button color="error" variant="outlined" onClick={handleRemoveUniversity}>
                    <DeleteOutline sx={{ mr: 1 }} fontSize="small" />
                    Remove University</Button>}
                <Button
                    disabled={!(values.university && values.department && values.startDate && (values.finishDate || checkStillAttending))}
                    color="primary" variant="contained" onClick={editableUniversity ? handleUpdateUniversity : handleAddUniversity}><Update sx={{ mr: 1 }} fontSize="small" /> {editableUniversity ? 'Update University' : 'Add University'}</Button>
            </DialogActions>
        </Dialog>


        <Dialog
            open={openHighSchool}
            keepMounted
            onClose={() => setOpenHighSchool(false)}
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={Transition}
        >
            <DialogTitle>{editableHighSchool ? 'Update HighSchool' : 'Add HighSchool'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                    <Grid item xs={12} md={userRole === "highSchoolIntern" ? 12 : 6}>
                        <TextField
                            fullWidth
                            name="highSchoolName"
                            label="High School Name"
                            value={values.highSchoolName}
                            onChange={(e => {
                                const enteredValue = e.target.value;
                                const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                setFieldValue("highSchoolName", capitalizedValue)
                            })}
                            onBlur={handleBlur}
                            error={touched.highSchoolName && Boolean(errors.highSchoolName)}
                            helperText={touched.highSchoolName && errors.highSchoolName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Dropdown
                            setFieldValue={setFieldValue}
                            errors={errors}
                            data={educations?.highSchoolDepartments?.data}
                            label={"Department"}
                            labelId={"highSchoolDepartment-label"}
                            value={values.highSchoolDepartment}
                            id={"highSchoolDepartment"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDPicker
                            sx={{ width: '100%' }}
                            label="Start Date"
                            value={values.startDate}
                            openTo="month"
                            onChange={(value) => setFieldValue("startDate", value)}
                            name="startDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                            minDate={values.birthDate}
                        />
                    </Grid>
                    {userRole !== "highSchoolIntern" && <Grid item xs={12} md={6}>
                        <MDPicker
                            disabled={checkStillAttending || !values.startDate}
                            sx={{ width: '100%' }}
                            label={"Finish Date"}
                            value={values.finishDate}
                            openTo="month"
                            onChange={(value) => setFieldValue("finishDate", value)}
                            name="finishDate"
                            views={['month', 'year']}
                            maxDate={new Date()}
                            minDate={values.startDate}
                        />
                    </Grid>}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => setOpenHighSchool(false)}>Cancel</Button>
                {editableHighSchool && userRole !== "highSchoolIntern" && <Button color="error" variant="outlined" onClick={handleRemoveHighSchool}>
                    <DeleteOutline sx={{ mr: 1 }} fontSize="small" />
                    Remove High School</Button>}
                <Button
                    disabled={!(values.highSchoolName && values.highSchoolDepartment && values.startDate)}
                    color="primary" variant="contained" onClick={editableHighSchool ? handleUpdateHighSchool : handleAddHighSchool}><Update sx={{ mr: 1 }} fontSize="small" /> {editableHighSchool ? 'Update High School' : 'Add High School'}</Button>
            </DialogActions>
        </Dialog>


    </>
}
