import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Grid, Typography, TextField, Button, Box } from "@mui/material";
import { DashboardLayout } from "layouts";
import { AddButtonInternshipSection } from "page-sections/dashboard/company/internship/_AddButtonInternshipSection";
import { AnimatedTitle } from "components";
import { GroupOutlined, AddRounded, WorkHistoryOutlined } from "@mui/icons-material";
import { StaticDatePicker } from '@mui/x-date-pickers';



const info = ["Faz 2 yapılıyor."];


const Task = () => {

    const navigate = useNavigate();
    const handleDateChange = (date) => {
        console.log('Seçilen tarih:', date);
    };

    return (
        <DashboardLayout info={info}>
            <Grid container sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
                <Grid item xs={12} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <AnimatedTitle title="Tasks" />
                    <Button onClick={null} variant="contained" color="primary" size={"large"} sx={{ mt: { xs: 5, lg: 0 } }}>
                        <AddRounded sx={{ mr: 1 }} />
                        Add New Task
                    </Button >
                </Grid>
                <Box height={28} width='100%' />
                <Grid container spacing={{ xs: 0, sm: 3 }} >
                    <Grid item sx={12} sm={6} md={7} lg={7.6} xl={8.7} >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ backgroundColor: '#FF9737dd', padding: 2, alignItems: 'center', height: '100%' }} >
                                    <WorkHistoryOutlined sx={{ color: 'white', fontSize: '35px', }} />
                                    <Typography sx={{ fontSize: "25pt", fontWeight: 'bold' }} color={"white"}>0</Typography>
                                    <Typography variant="h5" color={"white"}>Completed Task</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ backgroundColor: '#248E9Ddd', padding: 2, alignItems: 'center', height: '100%' }} >
                                    <WorkHistoryOutlined sx={{ color: 'white', fontSize: '35px', }} />
                                    <Typography sx={{ fontSize: "25pt", fontWeight: 'bold' }} color={"white"}>0</Typography>
                                    <Typography variant="h5" color={"white"}>Ongoing Task</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card sx={{ backgroundColor: '#3D7CF8', padding: 2, alignItems: 'center', height: '100%' }} >
                                    <GroupOutlined sx={{ color: 'white', fontSize: '35px', }} />
                                    <Typography sx={{ fontSize: "25pt", fontWeight: 'bold' }} color={"white"}>0</Typography>
                                    <Typography variant="h5" color={"white"}>Team Members</Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" textAlign={"center"} mt={5} color="text.secondary">
                                    There is no tasks.
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4.4} xl={3.3} >
                        <Card>
                            <Box
                                sx={{
                                    '& .MuiPickersLayout-root': {
                                        minWidth: '100%',
                                    },
                                    // '& .MuiButtonBase-root': {
                                    //     width: null,
                                    //     px: '10px'
                                    // },
                                    // '& .MuiDayCalendar-weekContainer': {
                                    //     width: null,
                                    //     px: '10px'
                                    // },
                                }}>
                                <StaticDatePicker
                                    sx={{
                                        '.MuiDateCalendar-root': {
                                            borderRadius: 3,
                                            width: '100%',
                                            borderWidth: 0,
                                            borderColor: '#2196f3',
                                            border: '0px solid',
                                        },
                                        '.MuiPickersLayout-actionBar': {
                                            display: 'none'
                                        },

                                    }}
                                    orientation="portrait"
                                    openTo="day"
                                    onChange={handleDateChange}
                                    value={new Date()}
                                    renderInput={(params) => <TextField {...params} variant="standard" />}
                                    showDaysOutsideCurrentMonth={false}
                                />
                            </Box>
                            <Typography variant="h6" m={3} >
                                Recent Activities
                            </Typography>
                            <Typography variant="body2" m={3} mb={10} color={"text.secondary"}>
                                There is no recent activity.
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>

            </Grid>
        </DashboardLayout >
    );
};

export default Task;
