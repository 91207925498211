import React from 'react'
import { Menu, MenuItem, Avatar, Box } from '@mui/material'
import userImage from 'assets/images/common/user.png';
import { useTranslation } from 'react-i18next';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Settings from '@mui/icons-material/Settings';
import ExitToApp from '@mui/icons-material/ExitToApp';
import BusinessIcon from '@mui/icons-material/Business';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ShortTextIcon from '@mui/icons-material/ShortText';
import { NoteOutlined, Stars } from '@mui/icons-material';

const ProfileBox = ({ handleClick, userRole, self_companyUserData, self_internData, anchorEl, open, handleResume,
    handleClose, handleAccount, handleCompany, handleNotepad, handleSettings, handleLogout, handleCoverLetters, handleMembership }) => {
    const { t } = useTranslation();


    return <>
        <Box sx={{ mb: 5, mt: 4, mx: 1, cursor: 'pointer' }} flexDirection="row"
            display="flex" alignItems="center" bgcolor={'#f5f5f544'} p={1} borderRadius={2}
            onClick={handleClick}
        >
            <Avatar sx={{ width: 40, height: 40 }} src={
                userRole === "companyAdmin" && self_companyUserData?.personalInformation?.profileImageUrl ?
                    self_companyUserData?.personalInformation?.profileImageUrl :
                    userRole === 'intern' && self_internData?.personalInformation?.profileImageUrl ?
                        self_internData?.personalInformation?.profileImageUrl :
                        userRole === "highSchoolIntern" && self_internData?.personalInformation?.profileImageUrl ?
                            self_internData?.personalInformation?.profileImageUrl :
                            userImage} />
            <Box sx={{ ml: 2 }}>
                <Box sx={{ fontWeight: 600, color: 'white' }}>{
                    userRole === "companyAdmin" ? self_companyUserData?.personalInformation?.name :
                        userRole === "intern" ? self_internData?.personalInformation?.name :
                            userRole === "highSchoolIntern" ? self_internData?.personalInformation?.name : ""} {
                        userRole === "companyAdmin" ? self_companyUserData?.personalInformation?.surname :
                            userRole === "intern" ? self_internData?.personalInformation?.surname :
                                userRole === "highSchoolIntern" ? self_internData?.personalInformation?.surname : ""
                    }</Box>
                <Box sx={{ fontSize: 12, color: 'white' }}>{
                    userRole === "companyAdmin" && self_companyUserData?.role === 'companyAdmin' ? t("Company Admin") :
                        userRole === "companyAdmin" && self_companyUserData?.role === 'companyUser' ? t('Company User') :
                            userRole === "intern" ? t("Intern") :
                                userRole === "highSchoolIntern" ? t("Intern (High School)") : ""}
                </Box>
            </Box>

        </Box>
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <MenuItem onClick={handleAccount} sx={{ width: 150 }}>
                <AccountCircle sx={{ marginRight: 1 }} />
                {t("My Account")}
            </MenuItem>
            {userRole === "companyAdmin" && <MenuItem onClick={handleCompany}>
                <BusinessIcon sx={{ marginRight: 1 }} />
                {t("My Company")}
            </MenuItem>}
            {userRole === "companyAdmin" && <MenuItem onClick={handleNotepad}>
                <NoteOutlined sx={{ marginRight: 1 }} />
                {t("Notepad")}
            </MenuItem>}
            {(userRole === "intern" || userRole === "highSchoolIntern") && <MenuItem onClick={handleResume}>
                <PostAddIcon sx={{ marginRight: 1 }} />
                {t("My Resume")}
            </MenuItem>}
            {(userRole === "intern" || userRole === "highSchoolIntern") && <MenuItem onClick={handleCoverLetters}>
                <ShortTextIcon sx={{ marginRight: 1 }} />
                {t("My Cover Letters")}
            </MenuItem>}
            <MenuItem onClick={handleSettings}>
                <Settings sx={{ marginRight: 1 }} />
                {t("Settings")}
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <ExitToApp sx={{ marginRight: 1 }} />
                {t("Sign Out")}
            </MenuItem>
        </Menu>
    </>
}

export default ProfileBox