
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box, Typography, Stack, Tabs, Tab, Card, FormGroup, FormControlLabel, Switch, Button
    , TextField, FormControl, InputLabel, Select, MenuItem, Grid, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { BasicInputPassword, AnimatedTitle, SettingsContainer, FormComponent } from 'components';
import { DashboardLayout } from 'layouts';
import PropTypes from 'prop-types';
import { NotificationsNone, Security, Description, InfoOutlined, Save, LanguageOutlined } from '@mui/icons-material';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import regexPattern from 'minimal/utils/regexPattern';
import {
    resetPasswordUsingOldFunc, get2faFunc, update2faFunc, self_user_billingInfoFunc, self_updateUserSettingsFunc,
    self_updateOrganizationInvoiceInfo,
    self_organization_billingInfoFunc
} from 'context';
import { useSnackbar } from 'notistack';
import PayrollIcon from 'assets/icons/dashboard/payroll.png';
import { MuiTelInput } from 'mui-tel-input'
import { useNavigate } from 'react-router-dom'

const info = [
    "Notification eksik./backend", "Policies eksik/business"
]

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pl: 0, ml: 0, pt: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SettingsCompany = (props) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { i18n } = useTranslation();
    const tab = searchParams.get('tab');
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { t } = useTranslation()
    const [value, setValue] = useState(tab === "invoiceInfo" ? 3 : 0);
    const [pushNotification, setPushNotification] = useState(true);
    const [emailNotification, setEmailNotification] = useState(true);
    const [smsNotification, setSmsNotification] = useState(false);
    const [twoFactorEmail, setTwoFactorEmail] = useState(false);
    const [twoFactorSms, setTwoFactorSms] = useState(false);
    const [ip, setIp] = useState('');
    const [openTwoFactor, setOpenTwoFactor] = useState(false);
    const { defaultLanguageData } = useSelector(state => state.self_user);
    const { self_companyUserData: company } = useSelector(state => state.self_companyUser);
    const { self_organization_billingInfoData: billingInfo } = useSelector(state => state.self_organization_billingInfo);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState('en');
    const [invoiceType, setInvoiceType] = useState("");


    const handlePushNotificationChange = () => {
        setPushNotification(!pushNotification);
    };

    const handleEmailNotificationChange = () => {
        setEmailNotification(!emailNotification);
    };

    const handleSmsNotificationChange = () => {
        setSmsNotification(!smsNotification);
    };

    const handleTwoFactorEmailChange = () => {
        setTwoFactorEmail(!twoFactorEmail);
    };

    const handleTwoFactorSmsChange = () => {
        setTwoFactorSms(!twoFactorSms);
    };

    const handleSaveToFactor = () => {
        setOpenTwoFactor(true)
        console.log('Two Factor Email:', twoFactorEmail);
        console.log('Two Factor SMS:', twoFactorSms);
    };

    const handleSaveDefaultLanguage = () => {
        self_updateUserSettingsFunc({ accessToken: accessToken, defaultLanguage: selectedDefaultLanguage }).then((res) => {
            if (res?._id) {
                enqueueSnackbar('Default language updated successfully!', { variant: 'success' });
                i18n.changeLanguage(selectedDefaultLanguage);
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
        })
    }

    const handleUpdate2fa = () => {
        dispatch(update2faFunc({ accessToken: accessToken, email: twoFactorEmail })).then((res) => {
            if (res.payload?.isSuccess) {
                twoFactorEmail && enqueueSnackbar('The 2FA with email has been activated!', { variant: 'success' });
                !twoFactorEmail && enqueueSnackbar('The 2FA with email has been deactivated!', { variant: 'success' });
                setOpenTwoFactor(false)
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
                setOpenTwoFactor(false)
            }
        })
    }

    const handleSaveSettings = () => {
        console.log('Push Notification:', pushNotification);
        console.log('Email Notification:', emailNotification);
        console.log('SMS Notification:', smsNotification);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const validationSchema = yup.object({
        oldPassword: yup
            .string()
            .required('Old Password is required'),

        password: yup
            .string()
            .required('Password is required')
            .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
            .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
            .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
            .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!")
            .min(8, "Password must be at least 8 characters!")
            .required('Password is required!'),

        passwordAgain: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Password confirmation is required'),
    });

    const handleResetPassword = (values) => {
        dispatch(resetPasswordUsingOldFunc({ accessToken, oldPassword: values?.oldPassword, newPassword: values?.password })).then((res) => {
            if (res.payload.isSuccess) {
                enqueueSnackbar('Password changed successfully!', { variant: 'success' });
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
        })
    }

    console.log(company)

    const formikInvoice = useFormik({
        initialValues: {
            id: billingInfo?.invoiceInfo?.personal?.id,
            taxOffice: billingInfo?.invoiceInfo?.business?.taxOffice,
            taxNumber: billingInfo?.invoiceInfo?.business?.taxNumber,
            phone: billingInfo === "business" ? billingInfo?.invoiceInfo?.business?.phoneNumber :
                invoiceType === "personal" ? billingInfo?.invoiceInfo?.personal?.phoneNumber : '',
            country: invoiceType === "business" ? billingInfo?.invoiceInfo?.business?.country :
                invoiceType === "personal" ? billingInfo?.invoiceInfo?.personal?.country : '',
            city: invoiceType === "business" ? billingInfo?.invoiceInfo?.business?.city :
                invoiceType === "personal" ? billingInfo?.invoiceInfo?.personal?.city : '',
            zip: invoiceType === "business" ? billingInfo?.invoiceInfo?.business?.zip :
                invoiceType === "personal" ? billingInfo?.invoiceInfo?.personal?.zip : '',
            address: invoiceType === "business" ? billingInfo?.invoiceInfo?.business?.address :
                invoiceType === "personal" ? billingInfo?.invoiceInfo?.personal?.address : '',
            corporateName: billingInfo?.invoiceInfo?.business?.corporateName,
            name: company?.personalInformation?.name,
            surname: company?.personalInformation?.surname,
            email: company?.contact?.email,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            id: invoiceType === "personal" && yup.string().required('Id is required').min(3, 'Id must be at least 3 characters'),
            corporateName: invoiceType === "business" && yup.string().required('Corporate Name is required'),
            taxOffice: invoiceType === "business" && yup.string().required('Tax Office is required'),
            taxNumber: invoiceType === "business" && yup.string().required('Tax Number is required'),
            phone: yup.string().min(6, 'Phone Number must be at least 6 characters').max(20, 'Phone Number must be at most 20 characters').required('Phone is required'),
            country: yup.string().required('Country is required'),
            city: yup.string().required('City is required'),
            zip: yup.string().required('Zip is required'),
            address: yup.string().required('Address is required'),

        }),
        onSubmit: (values) => {
            handleSubmitInvoice(values)
        },
    });

    const handleSubmitInvoice = (values) => {
        if (invoiceType === "personal") {
            self_updateOrganizationInvoiceInfo({ accessToken: accessToken, values: values, type: "personal" }).then((res) => {
                if (res.success) {
                    enqueueSnackbar('Invoice Info updated successfully!', { variant: 'success' });
                    dispatch(self_organization_billingInfoFunc({ accessToken: accessToken }))
                    tab === "invoiceInfo" && navigate('/if-credit');
                } else {
                    enqueueSnackbar("Invoice Info couldn't updated!", { variant: 'error' });
                }
            })
        }

        if (invoiceType === "business") {
            self_updateOrganizationInvoiceInfo({ accessToken: accessToken, values: values, type: "business" }).then((res) => {
                console.log(res)
                if (res.success) {
                    enqueueSnackbar('Invoice Info updated successfully!', { variant: 'success' });
                    dispatch(self_organization_billingInfoFunc({ accessToken: accessToken }))
                } else {
                    enqueueSnackbar("Invoice Info couldn't updated!", { variant: 'error' });
                }
            })

        }
    }


    useEffect(() => {
        accessToken && dispatch(self_organization_billingInfoFunc({ accessToken: accessToken }))
    }, [accessToken, dispatch])

    useEffect(() => {
        const fetchIp = async () => {
            const response = await fetch('https://api.ipify.org?format=json')
            const data = await response.json()
            setIp(data.ip)
        }
        accessToken && fetchIp()
    }, [accessToken])

    useEffect(() => {
        accessToken && dispatch(get2faFunc({ accessToken: accessToken })).then((res) => {
            if (res.payload?.isSuccess) {
                setTwoFactorEmail(res.payload.data.email)
                setTwoFactorSms(res.payload.data.sms)
            }
        })
        accessToken && dispatch(self_organization_billingInfoFunc({ accessToken: accessToken }));
    }, [accessToken, dispatch])

    useEffect(() => {
        if (defaultLanguageData) {
            setSelectedDefaultLanguage(defaultLanguageData)
        }
    }, [defaultLanguageData])

    useEffect(() => {
        setInvoiceType(billingInfo?.invoiceInfo?.type)
    }, [billingInfo?.invoiceInfo?.type])


    return <DashboardLayout info={info}>
        <Grid container sx={{ ml: { xs: 0, sm: 5 }, mt: { xs: 0, sm: 5 }, width: '100%' }}>
            <Box >
                <AnimatedTitle title="Settings" />
            </Box>
        </Grid>
        <Box container sx={{ px: { xs: 0, sm: 5 }, py: 2, width: { xs: '100%', xl: '80%' } }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}
                    aria-label="basic tabs example"
                    style={{ marginBottom: "-7px" }}
                    TabIndicatorProps={{
                        style: {
                            bottom: 7,
                            backgroundColor: "#FF9737",
                            height: "4px",
                            borderRadius: "10px",
                        },
                    }}
                >
                    <Tab icon={<NotificationsNone fontSize='small' />} label={t("Notification")} {...a11yProps(0)} />
                    <Tab icon={<LanguageOutlined fontSize='small' />} label={t("Language")} {...a11yProps(1)} />
                    <Tab icon={<Security fontSize='small' />} label={t("Security")} {...a11yProps(2)} />
                    <Tab icon={<InfoOutlined fontSize='small' />} label={t("Invoice Info")} {...a11yProps(3)} />
                    <Tab icon={<Description fontSize='small' />} label={t("Policies")} {...a11yProps(4)} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 2, mb: 2 }}>
                        <Grid item xs={12} ml={2}>
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can manage your notification preferences below.")}</Typography><br /><br />
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={pushNotification} onChange={handlePushNotificationChange} />}
                                    label={t("Push Notifications")}
                                />
                                <FormControlLabel
                                    control={<Switch checked={emailNotification} onChange={handleEmailNotificationChange} />}
                                    label={t("Email Notifications")}
                                />
                                {/* <FormControlLabel
                                    control={<Switch checked={smsNotification} onChange={handleSmsNotificationChange} />}
                                    label={t("SMS Notifications")}
                                /> */}
                            </FormGroup>
                            <Box height={30} />
                            <Button variant="contained" color="primary" onClick={handleSaveSettings}>
                                {t("Save Settings")}
                            </Button>
                        </Grid>
                    </Grid>
                </SettingsContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 2, mb: 2 }}>
                        <Grid item xs={12} ml={2}>
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can change the application language below.")}</Typography><br /><br />


                            <FormControl sx={{ width: 200 }}>
                                <InputLabel id="demo-simple-select-label">{t("Language")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedDefaultLanguage}
                                    label="Language"
                                    onChange={(e) => setSelectedDefaultLanguage(e.target.value)}
                                >
                                    <MenuItem value={"en"}>{t('English')}</MenuItem>
                                    <MenuItem value={"tr"}>{t('Turkish')}</MenuItem>
                                </Select>
                            </FormControl>
                            <Box height={30} />
                            <Button variant="contained" color="primary" onClick={handleSaveDefaultLanguage}>
                                {t("Save Settings")}
                            </Button>
                        </Grid>
                    </Grid>
                </SettingsContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 1 }}>
                        <Grid item xs={12} ml={2}>

                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can change your password in the field below.")}</Typography><br /><br />

                            <Formik initialValues={{ password: '', oldPassword: '' }} validationSchema={validationSchema}
                                onSubmit={handleResetPassword}>
                                {({ handleSubmit, handleChange, handleBlur, errors, values, touched }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Stack spacing={1} width={{ xs: '100%', md: '50%', lg: '50%' }}>
                                            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={0}>
                                                <BasicInputPassword
                                                    name="oldPassword"
                                                    type="password2"
                                                    label={t("Old Password")}
                                                    placeholder={t("Old password")}
                                                    value={values?.oldPassword}
                                                    error={touched.oldPassword && errors.oldPassword}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                />
                                            </Stack>
                                            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
                                                <BasicInputPassword
                                                    name="password"
                                                    type="password2"
                                                    label={t("New Password")}
                                                    placeholder={t("New password")}
                                                    value={values?.password}
                                                    error={touched.password && errors.password}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                />
                                                <BasicInputPassword
                                                    name="passwordAgain"
                                                    type="password2"
                                                    label={t("New Password Again")}
                                                    placeholder={t("New password again")}
                                                    value={values?.passwordAgain}
                                                    error={touched.passwordAgain && errors.passwordAgain}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                />
                                            </Stack>
                                            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} mb={2}>
                                                <Button variant="contained" color="primary" type='submit'>
                                                    {t("Change Password")}
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </SettingsContainer>

                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container spacing={2} sx={{ width: '100%', justifyContent: 'center', mt: 1 }}>
                        <Grid item xs={12} ml={2}>
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update the following settings to perform 2-factor authentication.")}</Typography><br /><br />
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={twoFactorEmail} onChange={handleTwoFactorEmailChange} />}
                                    label={t("Two Factor with Email")}
                                />
                                {/* <FormControlLabel
                                    control={<Switch checked={twoFactorSms} onChange={handleTwoFactorSmsChange} />}
                                    label={t("Two Factor with SMS")}
                                /> */}
                            </FormGroup>
                            <Box height={10} />
                            <Button variant="contained" color="primary" onClick={handleSaveToFactor}>
                                {t("Save Settings")}
                            </Button>

                        </Grid>
                    </Grid>
                </SettingsContainer>

            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                <SettingsContainer pb={2} mb={3} ml={0}>
                    <Grid container p={3}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2} width={'100%'} >
                            <Typography variant="h7" textAlign={{ xs: "center", lg: "left" }}>{t("You can update your invoice information below.")}</Typography>
                            <img src={PayrollIcon} alt="Payroll Icon" height={"42px"} style={{ objectFit: 'contain' }} />
                        </Stack>
                        <Box width={{ xs: '100%', lg: '50%' }}>
                            <form onSubmit={formikInvoice.handleSubmit} sx={{ width: '100%' }}>
                                <Stack gap={2} py={2} width={{ xs: '100%' }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">{t("Invoice Type")}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name='invoiceType'
                                            value={invoiceType}
                                            label={t("Invoice Type")}
                                            onChange={(e) => {
                                                formikInvoice.setValues({
                                                    ...formikInvoice.values,
                                                    id: '',
                                                    phone: '',
                                                    country: '',
                                                    city: '',
                                                    zip: '',
                                                    address: '',
                                                })
                                                setInvoiceType(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={"personal"}>Personal</MenuItem>
                                            <MenuItem value={"business"}>Business</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                {invoiceType === "personal" &&
                                    <Stack gap={2} flexDirection={{ xs: "column" }} width={{ xs: '100%' }}>
                                        <TextField
                                            fullWidth
                                            name="id"
                                            label={t("ID")}
                                            variant="outlined"
                                            value={formikInvoice.values.id}
                                            onChange={formikInvoice.handleChange}
                                            onBlur={formikInvoice.handleBlur}
                                            error={formikInvoice.touched.id && Boolean(formikInvoice.errors.id)}
                                            helperText={formikInvoice.touched.id && formikInvoice.errors.id}
                                            inputProps={{ maxLength: 35 }}
                                        />
                                        <Stack flexDirection={"row"} gap={1}>
                                            <MuiTelInput
                                                label={t("Phone")}
                                                name="phone"
                                                value={formikInvoice.values.phone || '+90'}
                                                onChange={(e) => formikInvoice.setFieldValue('phone', e)}
                                                placeholder='Corporate Phone Number'
                                                defaultCountry={'tr'}
                                                variant="outlined"
                                                inputProps={{ maxLength: 20 }}
                                                fullWidth
                                                error={formikInvoice.touched.phone && formikInvoice.errors.phone}
                                                helperText={formikInvoice.touched.phone && formikInvoice.errors.phone}
                                                onBlur={formikInvoice.handleBlur}
                                            />
                                            <TextField
                                                fullWidth
                                                name="zip"
                                                label={t("ZIP")}
                                                variant="outlined"
                                                onBlur={formikInvoice.handleBlur}
                                                placeholder='Corporate ZIP Code'
                                                value={formikInvoice.values.zip}
                                                onChange={formikInvoice.handleChange}
                                                error={formikInvoice.touched.zip && Boolean(formikInvoice.errors.zip)}
                                                helperText={formikInvoice.touched.zip && formikInvoice.errors.zip}
                                                inputProps={{ maxLength: 35 }}
                                            />
                                        </Stack>
                                        <Stack flexDirection={"row"} gap={1}>
                                            <TextField
                                                name="country"
                                                fullWidth
                                                label={t("Country")}
                                                variant="outlined"
                                                onBlur={formikInvoice.handleBlur}
                                                placeholder='Corporate Country'
                                                value={formikInvoice.values.country}
                                                onChange={formikInvoice.handleChange}
                                                error={formikInvoice.touched.country && Boolean(formikInvoice.errors.country)}
                                                helperText={formikInvoice.touched.country && formikInvoice.errors.country}
                                                inputProps={{ maxLength: 35 }}
                                            />
                                            <TextField
                                                fullWidth
                                                name="city"
                                                label={t("City")}
                                                variant="outlined"
                                                onBlur={formikInvoice.handleBlur}
                                                placeholder='Corporate City'
                                                value={formikInvoice.values.city}
                                                onChange={formikInvoice.handleChange}
                                                error={formikInvoice.touched.city && Boolean(formikInvoice.errors.city)}
                                                helperText={formikInvoice.touched.city && formikInvoice.errors.city}
                                                inputProps={{ maxLength: 35 }}
                                            />
                                        </Stack>
                                        <TextField
                                            fullWidth
                                            name="address"
                                            label={t("Address")}
                                            variant="outlined"
                                            onBlur={formikInvoice.handleBlur}
                                            placeholder='Corporate Address'
                                            value={formikInvoice.values.address}
                                            onChange={formikInvoice.handleChange}
                                            error={formikInvoice.touched.address && Boolean(formikInvoice.errors.address)}
                                            helperText={formikInvoice.touched.address && formikInvoice.errors.address}
                                            inputProps={{ maxLength: 35 }}
                                        />
                                    </Stack>}
                                {invoiceType === "business" &&
                                    <Stack gap={2} flexDirection={{ xs: "column" }} width={{ xs: '100%' }}>
                                        <TextField
                                            fullWidth
                                            name="corporateName"
                                            label={t("Corporate Name")}
                                            variant="outlined"
                                            value={formikInvoice.values.corporateName}
                                            onChange={formikInvoice.handleChange}
                                            onBlur={formikInvoice.handleBlur}
                                            error={formikInvoice.touched.corporateName && Boolean(formikInvoice.errors.corporateName)}
                                            helperText={formikInvoice.touched.corporateName && formikInvoice.errors.corporateName}
                                            inputProps={{ maxLength: 35 }}
                                        />
                                        <TextField
                                            fullWidth
                                            name="taxOffice"
                                            label={t("Tax Office")}
                                            placeholder='Corporate Tax Office'
                                            variant="outlined"
                                            value={formikInvoice.values.taxOffice}
                                            onChange={formikInvoice.handleChange}
                                            onBlur={formikInvoice.handleBlur}
                                            error={formikInvoice.touched.taxOffice && Boolean(formikInvoice.errors.taxOffice)}
                                            helperText={formikInvoice.touched.taxOffice && formikInvoice.errors.taxOffice}
                                            inputProps={{ maxLength: 35 }}
                                        />
                                        <TextField
                                            fullWidth
                                            name="taxNumber"
                                            label={t("Tax Number")}
                                            placeholder='Corporate Tax Number'
                                            onBlur={formikInvoice.handleBlur}
                                            variant="outlined"
                                            value={formikInvoice.values.taxNumber}
                                            onChange={formikInvoice.handleChange}
                                            error={formikInvoice.touched.taxNumber && Boolean(formikInvoice.errors.taxNumber)}
                                            helperText={formikInvoice.touched.taxNumber && formikInvoice.errors.taxNumber}
                                            inputProps={{ maxLength: 35 }}
                                        />
                                        <Stack flexDirection={"row"} gap={1}>
                                            <MuiTelInput
                                                label={t("Phone")}
                                                name="phone"
                                                value={formikInvoice.values.phone || '+90'}
                                                onChange={(e) => formikInvoice.setFieldValue('phone', e)}
                                                placeholder='Corporate Phone Number'
                                                defaultCountry={'tr'}
                                                variant="outlined"
                                                inputProps={{ maxLength: 20 }}
                                                fullWidth
                                                error={formikInvoice.touched.phone && formikInvoice.errors.phone}
                                                helperText={formikInvoice.touched.phone && formikInvoice.errors.phone}
                                                onBlur={formikInvoice.handleBlur}
                                            />
                                            <TextField
                                                fullWidth
                                                name="zip"
                                                label={t("ZIP")}
                                                variant="outlined"
                                                onBlur={formikInvoice.handleBlur}
                                                placeholder='Corporate ZIP Code'
                                                value={formikInvoice.values.zip}
                                                onChange={formikInvoice.handleChange}
                                                error={formikInvoice.touched.zip && Boolean(formikInvoice.errors.zip)}
                                                helperText={formikInvoice.touched.zip && formikInvoice.errors.zip}
                                                inputProps={{ maxLength: 35 }}
                                            />
                                        </Stack>
                                        <Stack flexDirection={"row"} gap={1}>
                                            <TextField
                                                name="country"
                                                fullWidth
                                                label={t("Country")}
                                                variant="outlined"
                                                onBlur={formikInvoice.handleBlur}
                                                placeholder='Corporate Country'
                                                value={formikInvoice.values.country}
                                                onChange={formikInvoice.handleChange}
                                                error={formikInvoice.touched.country && Boolean(formikInvoice.errors.country)}
                                                helperText={formikInvoice.touched.country && formikInvoice.errors.country}
                                                inputProps={{ maxLength: 35 }}
                                            />
                                            <TextField
                                                fullWidth
                                                name="city"
                                                label={t("City")}
                                                variant="outlined"
                                                onBlur={formikInvoice.handleBlur}
                                                placeholder='Corporate City'
                                                value={formikInvoice.values.city}
                                                onChange={formikInvoice.handleChange}
                                                error={formikInvoice.touched.city && Boolean(formikInvoice.errors.city)}
                                                helperText={formikInvoice.touched.city && formikInvoice.errors.city}
                                                inputProps={{ maxLength: 35 }}
                                            />
                                        </Stack>
                                        <TextField
                                            fullWidth
                                            name="address"
                                            label={t("Address")}
                                            variant="outlined"
                                            onBlur={formikInvoice.handleBlur}
                                            placeholder='Corporate Address'
                                            value={formikInvoice.values.address}
                                            onChange={formikInvoice.handleChange}
                                            error={formikInvoice.touched.address && Boolean(formikInvoice.errors.address)}
                                            helperText={formikInvoice.touched.address && formikInvoice.errors.address}
                                            inputProps={{ maxLength: 35 }}
                                        />
                                    </Stack>}
                                {invoiceType && <Stack py={2} flexDirection={"row"} width={{ xs: '100%' }}>
                                    <Button size='large' variant="contained" color="primary" sx={{ width: { xs: '160px', lg: 'auto' }, alignSelf: 'end' }} type='submit' >
                                        <Save sx={{ mr: 1 }} />{t("Save Changes")}
                                    </Button>
                                </Stack>}

                            </form>
                        </Box>
                    </Grid>
                </SettingsContainer>
            </CustomTabPanel >

            <CustomTabPanel value={value} index={4}>
                <Typography>{t("Policies")}</Typography>
            </CustomTabPanel>
        </Box >

        <Dialog open={openTwoFactor} onClose={() => setOpenTwoFactor(false)}>
            <DialogTitle>{t("Two Factor Authentication")}</DialogTitle>
            <DialogContent>
                <Typography >{t("Your two factor auth changes will be saved. Do you confirm?")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenTwoFactor(false)} color="primary" variant={"outlined"}>
                    {t("Cancel")}
                </Button>
                <Button onClick={() => handleUpdate2fa()} color="primary" autoFocus variant='contained'>
                    {t("Confirm")}
                </Button>
            </DialogActions>

        </Dialog>
    </DashboardLayout >

}

export default SettingsCompany

