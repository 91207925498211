import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PaymentSuccessAnimation from "assets/animations/payment_success.json";
import { Card, Grid } from '@mui/material';
import { Typography, Button } from '@mui/material';
import Lottie from 'lottie-react';
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import { user_updateOrderAfterStripeCheckoutFormPayment } from 'context';
import { useNavigate } from 'react-router-dom';



const PaymentSuccess = () => {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const orderId = urlSearchParams.get("orderId");
    const { accessToken } = useSelector(state => state.user)

    useEffect(() => {
        user_updateOrderAfterStripeCheckoutFormPayment({ orderId, accessToken })
    }, [orderId, accessToken])




    return <DashboardLayout >
        <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', gap: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} >
                <AnimatedTitle title="Payment Result" />
            </Grid>
            <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', py: 5, px:10, mb: 5 }}>

                <Typography variant="h6" textAlign={"center"}>Payment is completed successfully.</Typography>
                <Lottie
                    animationData={PaymentSuccessAnimation}
                    style={{ width: 200, height: 200, }}
                    autoplay={true}
                    loop={false}
                />


                <Button variant="contained" color="primary" onClick={() => navigate("/if-credit")}>Go to IF Credit</Button>
            </Card>


        </Grid>
    </DashboardLayout>

}


export default PaymentSuccess