
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {
    Grid, Box, Typography, Link, Divider, Dialog, TextField,
    DialogTitle, DialogContentText, DialogContent, DialogActions, Button
} from '@mui/material';
import AuthLayout from 'layouts/AuthLayout';
import { enqueueSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as yup from 'yup';
import { confirm2faFunc, resendRegistrationConfirmationFunc } from 'context';
import { setAccessToken, setUserRole, setUserId, setEmail } from 'context/common/user';
import { MuiOtpInput } from 'mui-one-time-password-input'

import { AuthHead } from 'page-sections'
import { useLocation, useNavigate } from 'react-router-dom';


// const ValidationSchema = yup.object().shape({
//     num1: yup.string().required("Required!"),
//     num2: yup.string().required("Required!"),
//     num3: yup.string().required("Required!"),
//     num4: yup.string().required("Required!"),
//     num5: yup.string().required("Required!"),
//     num6: yup.string().required("Required!"),
// })

const styles = {
    text_link: {
        fontFamily: 'Causten-Bold',
        color: '#3BA1EB',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    inputLabel: {
        textAlign: 'left',
        position: 'absolute',
        top: '-20px',
        backgroundColor: '#000'
    }
}


const TwoFactorSignin = () => {

    const [otp, setOtp] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = localStorage.getItem("registeredUser") ? JSON.parse(localStorage.getItem("registeredUser")) : null
    const [isSendPopup, setIsSendPopup] = useState(false)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");
    const [remainingTime, setRemainingTime] = useState(60);
    const [emailInput, setEmailInput] = useState('')

    const handleChange = (newValue) => {
        setOtp(newValue)
    }



    const {
        setFieldValue,
        handleSubmit
    } = useFormik({
        initialValues: {
            code: ''
        },
        onSubmit: (values) => {
            handleVerifyEmail({ code: otp })
        }
    })

    const handleVerifyEmail = ({ code }) => {


        dispatch(confirm2faFunc({
            token: token,
            code: code
        })).then((res) => {
            const userId = res.payload?.data?.user?.userId;
            const role = res.payload?.data?.user?.role;
            const email = res.payload?.data?.user?.email;
            const token = res.payload?.data?.accessToken;

            if (token) {
                if (role === 'companyAdmin' || role === 'intern' || role === "highSchoolIntern") {
                    enqueueSnackbar('Welcome to InternIF!', { variant: 'success' })
                    localStorage.setItem('accessToken', token);
                    localStorage.setItem('userRole', role);
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('email', email);
                    dispatch(setAccessToken(token));
                    dispatch(setUserRole(role));
                    dispatch(setUserId(userId));
                    dispatch(setEmail(email));
                    navigate('/');
                }
                if (role === 'b2bSalesRep' || role === 'b2bCompanyRep' || role === 'b2bInternRep' || role === 'b2bClient' || role === 'b2bMerchant') {
                    enqueueSnackbar("You need to login from the B2B Website!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => { window.location.href = 'https://biz.internif.com' }, 2500)
                    localStorage.clear()
                }
                if (role === 'internIFManager' || role === 'internIFAdmin' || role === 'internIFFinance') {
                    enqueueSnackbar("You need to login from the Management Website !", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => { window.location.href = 'https://manage.internif.com' }, 2500)
                    localStorage.clear()
                }
                if (role === 'university' || role === 'universityAdmin') {
                    enqueueSnackbar("You need to login from the University Website!", { variant: 'warning', autoHideDuration: 2500 })
                    setTimeout(() => { window.location.href = 'https://uni.internif.com' }, 2500)
                    localStorage.clear()
                }
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
            }

        }).catch(() => {
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
        })

    }


    const handleChangeAndNext = (event) => {

        if (event.target.value.length > 0) {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
            setFieldValue(event.target.name, event.target.value.slice(0, 1));
        }

    }

    const handleKeyDown = (event) => { // for delete and go back input

        if (event.code === "Backspace") {
            if (event.target.value === "") {
                const form = event.target.form;
                const index = [...form].indexOf(event.target);
                if (index !== -0) form.elements[index - 1].focus();
            }
            setFieldValue(event.target.name, event.target.value.slice(0, 0));
        }

    }

    const handleResendCode = () => {
        dispatch(resendRegistrationConfirmationFunc({
            email: emailInput
        })).then((res) => {
            if (res.payload?.isSuccess) {
                enqueueSnackbar('Code sent successfully!', { variant: 'success' })
                setRemainingTime(60)
            } else {
                enqueueSnackbar(res.payload?.response?.data?.errors?.[0]?.msg || 'An error has been detected. Please try again soon!', { variant: 'error' })
            }
        }).catch(() => {
            enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
        })

    }

    // useEffect(() => {
    //     if (!email) {
    //         navigate('/')
    //     }
    // }, [email, navigate])


    useEffect(() => {
        const intervalId = setInterval(() => {
            if (remainingTime > 0) {
                setRemainingTime((prevTime) => prevTime - 1);
            } else {
                clearInterval(intervalId);
            }
        }, 1000); // 1000 milisaniye = 1 saniye

        // Component unmount edildiğinde interval'i temizle
        return () => clearInterval(intervalId);
    }, [remainingTime]);


    return <AuthLayout>
        <Grid item xs={12} justifyContent="center" alignItems="center" flexDirection={"column"}>


            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Check Your Email" description={`We’ve sent a 6-character code to your email. The code expires shortly, so please enter it soon.`} />
            </Box>

            <Dialog open={isSendPopup} onClose={() => setIsSendPopup(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Resend Code</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        Please enter your email address to resend the code.
                    </DialogContentText>
                    <TextField autoFocus margin="dense" id="name" label="Email Address" type="email" fullWidth
                        value={emailInput}
                        onChange={(e) => setEmailInput(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsSendPopup(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            setIsSendPopup(false)
                            handleResendCode()
                        }} color="primary">
                        Resend
                    </Button>
                </DialogActions>
            </Dialog>


            <form action="" style={{ width: '100%' }} onSubmit={handleSubmit}>

                <MuiOtpInput
                    value={otp}
                    onChange={handleChange}
                    length={6}
                    style={{ width: '100%', borderColor: '#000', marginTop: 20 }}
                />
                {/* {[1, 2, 3, 4, 5, 6].map((index) => (
                        <MyInput
                            key={index}
                            name={`num${index}`}
                            errors={errors}
                            values={values[`num${index}`]}
                            touched={touched}
                            handleChange={handleChangeAndNext}
                            handleKeyDown={handleKeyDown}
                            handleBlur={handleBlur}
                        />
                    ))} */}

                <Button
                    disabled={otp?.length < 6}
                    variant='contained'
                    color='primary'
                    sx={{ width: '100%', minHeight: '50px', mt: 5 }}
                    type="submit"
                >
                    Verify
                </Button>


            </form>


            <Divider sx={{ width: "100%", marginTop: "30px" }} />

            <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"35px"} mb={"30px"}>
                <Typography color={"textSecondary"} textAlign={"center"} variant="h7" > You can request again after {remainingTime} seconds.
                    <Typography variant="h7"
                        color={remainingTime === 0 ? "info.main" : "#bbb"}
                        sx={{ cursor: 'pointer' }}
                        ml={"5px"} onClick={() => remainingTime === 0 && setIsSendPopup(true)}>
                        Resend Code
                    </Typography>
                </Typography>
            </Box>


            <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"35px"} mb={"30px"}>
                <Typography color={"textSecondary"} variant="h7" >Do you want to log in again?<a href="/" style={{ textDecoration: 'none' }}> <Typography variant="h7" color="info.main" ml={"5px"}>
                    Sign In
                </Typography>
                </a>
                </Typography>
            </Box>

        </Grid >


    </AuthLayout >
}

const MyInput = ({ handleChange, name, handleBlur, handleKeyDown, values }) => {

    const [focus, setFocus] = useState(false)

    const handleFocuse = (e) => {
        setFocus(true)
    }

    const handleFocuseOut = (e) => {
        setFocus(false)
        handleBlur(e)
    }

    return (
        <Box sx={{ width: '56px', height: "72px", }} >

            <input
                type="number"
                className='input_verify_code'
                value={values}
                min={0}
                max={9}
                onFocus={handleFocuse}
                onBlur={handleFocuseOut}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                name={name}
                style={{
                    border: focus ? '2px solid #65c6ff' : '1px solid #ccc',
                    borderRadius: "8px",
                    outline: 'none',
                    width: '100%',
                    height: "100%",
                    textAlign: "center",
                    fontFamily: "Causten-Regular",
                    fontSize: "40px",
                }}
            />

        </Box>
    )
}


export default TwoFactorSignin