import { Provider } from "react-redux";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Router from "./Router"
import store from "context/store";
import ThemeProvider from 'minimal/theme';
import SnackbarProvider from 'minimal/components/snackbar/snackbar-provider';


const Wrapper = () => {

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

            <Provider store={store}>
                <ThemeProvider>
                    <SnackbarProvider maxSnack={129}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Router />
                        </LocalizationProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </Provider>

        </GoogleOAuthProvider>
    )
}

export default Wrapper;