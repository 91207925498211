import { useState } from 'react'
import { Box, Button, IconButton, Card, Grid, Avatar, Typography, Tooltip, Chip } from '@mui/material'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Favorite, FavoriteBorder, Public, PublicOff, Paid, Coffee, AccountCircleOutlined } from '@mui/icons-material'
import { getNameOfInternshipArea, getNameOfInternshipStatus, getNameOfInternshipLanguage } from 'utils'
import Label from 'minimal/components/label';
import parse from 'html-react-parser';

export const InternshipCardForCompany = ({ item, onOpenDetail, loading, handleLikeAndDislike }) => {

    const navigate = useNavigate();

    function formattedTimePeriod() {
        switch (item.timePeriodInWeeks) {
            case 'fourWeeks':
                return '4 weeks'
            case 'eightWeeks':
                return '8 weeks'
            default:
                return '4 weeks'
        }
    }
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(item?.description, 'text/html')?.body?.textContent?.slice(0, 50);
    const slicedDescription = parsedDocument + '...';

    console.log(item)

    return <Card
        sx={{
            p: 2, borderRadius: 2, backgroundColor: 'rgba(255,255,255, 0.5)', backdropFilter: 'blur(6px)', cursor: 'pointer',
            '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)" }
        }}
        onClick={() => navigate(`/internship/${item?._id}`)}
    >

        <Grid container spacing={2} alignItems='center' justifyContent={'center'}>
            <Grid item xs={12} justifyContent={'center'} >
                <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} justifyContent={"flex-start"} alignItems={"center"} gap={1} >

                    <Card sx={{ width: "60px", height: "60px", p: 0, display: "flex", justifyContent: 'center', alignItems: 'center', borderRadius: 2, boxShadow: '0px 0px 10px rgba(30, 30, 30, 0.2)' }}>
                        <Avatar
                            alt={item.company?.displayName}
                            src={item.company?.displayImageUrl}
                            sx={{ width: 50, height: 50 }}
                        />
                    </Card>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={{ xs: "center", sm: "flex-start" }} ml={1}>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' }, gap: 1 }}>
                            <Typography variant='h5' color={'#444'} mr={1} >  {item.positionName} </Typography>
                            {item?.salaryType === 'Paid' ?
                                <Tooltip title="Local"><Paid sx={{ color: '#666', cursor: 'pointer' }} fontSize='small' /> </Tooltip> : null}
                            {item?.applicationType === "global" ?
                                <Tooltip title="One Coffee Per Week"> <Coffee sx={{ color: '#666', cursor: 'pointer' }} fontSize='small' /></Tooltip> : null}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={{ xs: 'center', sm: 'flex-start' }} sx={{ flexDirection: { xs: 'column', sm: 'row' } }} >
                            <Typography variant='h7' mr={1} color={'#666'}>Quota : {item.quota} Intern{item.quota > 1 ? 's' : ''}</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >•</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >{getNameOfInternshipLanguage(item.language)}</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >•</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} > {formattedTimePeriod()} </Typography>
                            <Typography variant='h7' mr={1} color={'#666'} >•</Typography>
                            <Typography variant='h7' mr={1} color={'#666'} > {item?.applicationType?.charAt(0).toUpperCase() + item?.applicationType?.slice(1)} </Typography>
                        </Box>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>{slicedDescription}</Typography>
                        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant='body2' color={'#777'}>
                            Posted {moment(item?.createdAt).fromNow()} -
                            {item?.type === "indefinite"
                                ? ` Indefinite`
                                : ` Starts on ${moment(item?.startDate).format("DD.MM.YYYY")}`}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item right={10} top={0} position={'absolute'}>
                <Label color={'secondary'}
                    sx={{ borderRadius: 200, minWidth: 90 }}> {getNameOfInternshipStatus(item?.status)}</Label>
            </Grid>
            <Grid item right={10} bottom={10} position={{ xs: 'auto', sm: 'absolute' }} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <AccountCircleOutlined sx={{ mr: 1 }} fontSize='small' color='secondary' />
                <Typography variant='body2' color={'#666'}> {item?.numberOfAppliedInterns > 0 ? item?.numberOfAppliedInterns : 'No'} intern{item?.numberOfAppliedInterns > 1 ? 's have' : ' has'} applied. </Typography>
            </Grid>

        </Grid>



    </Card >
}
