
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, IconButton, Typography, Grid, Card } from '@mui/material'
import { user_companyFunc } from 'context'
import { DashboardLayout } from 'layouts'
import { AnimatedTitle } from 'components'
import DefaultBackground from 'assets/images/default-background.png';
import userImage from 'assets/images/common/user.png';
import { FactoryOutlined, EmailOutlined, People, FacebookOutlined, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import parse from 'html-react-parser';
import { intern_internshipsFunc, intern_saveInternship, intern_unsaveInternship } from 'context'
import { InternshipCard } from 'components'
import moment from 'moment'
import Iconify from 'minimal/components/iconify/iconify'

const info = [
    "faz 2 bitti."
]


const AboutCompany = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { accessToken } = useSelector(state => state.user);
    const { user_companyData: company } = useSelector(state => state.user_company)
    const { intern_internshipsData: internships } = useSelector(state => state.intern_internships)

    const handleTogleIntenshipDetail = (internshipId) => {
        navigate(`/internship/${internshipId}`)
    }

    const handleLikeAndDislike = async (internship, detail = false) => {
        if (internship?.isSaved) {
            await intern_unsaveInternship({ internshipId: internship?._id, accessToken })
        } else {
            await intern_saveInternship({ internshipId: internship?._id, accessToken })
        }

        dispatch(intern_internshipsFunc({ variables: { filter: { company: { _id: id } } }, accessToken }))
    }


    useEffect(() => {
        dispatch(user_companyFunc({ companyId: id, accessToken }));
        dispatch(intern_internshipsFunc({ variables: { filter: { company: { _id: id } } }, accessToken }))
    }, [dispatch, id, accessToken])


    return (
        <DashboardLayout info={info}>
            <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
                <Grid item xs={12} >
                    <AnimatedTitle title={company?.companyInformation?.companyName} />
                </Grid>
                <Grid item xs={9}>
                    <Card>
                        <img src={company?.companyInformation?.backgroundImageUrl ?? DefaultBackground} alt="company-background" style={{ width: '100%', height: 150, objectFit: 'cover', borderTopLeftRadius: 23, borderTopRightRadius: 23 }} />
                        <img src={company?.companyInformation?.logoImageUrl ?? userImage} alt="company-logo" style={{ width: 120, height: 120, objectFit: 'cover', position: 'absolute', top: 90, left: "30px", borderRadius: 60, border: '5px solid #ffff' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Box sx={{ gap: 1, ml: 5, mt: 10 }}>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{company?.companyInformation?.companyName}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ mr: 5, mt: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <Button variant="contained"
                                    onClick={() => window.open(company?.companyInformation?.companyWebsite?.includes('http') ? company?.companyInformation?.companyWebsite : `http://${company?.companyInformation?.companyWebsite}`, '_blank')}
                                >Visit Website</Button>
                                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                                    {company?.companyInformation?.socialMediaInfo?.facebook && <IconButton
                                        onClick={() => window.open(company?.companyInformation?.socialMediaInfo?.facebook?.includes('http') ? company?.companyInformation?.socialMediaInfo?.facebook : `http://${company?.companyInformation?.socialMediaInfo?.facebook}`, '_blank')}>
                                        <FacebookOutlined sx={{ color: '#1877F2' }} />
                                    </IconButton>}
                                    {company?.companyInformation?.socialMediaInfo?.instagram && <IconButton
                                        onClick={() => window.open(company?.companyInformation?.socialMediaInfo?.instagram?.includes('http') ? company?.companyInformation?.socialMediaInfo?.instagram : `http://${company?.companyInformation?.socialMediaInfo?.instagram}`, '_blank')}>
                                        <Instagram sx={{ color: '#E1306C' }} />
                                    </IconButton>}
                                    {company?.companyInformation?.socialMediaInfo?.linkedIn && <IconButton
                                        onClick={() => window.open(company?.companyInformation?.socialMediaInfo?.linkedIn?.includes('http') ? company?.companyInformation?.socialMediaInfo?.linkedIn : `http://${company?.companyInformation?.socialMediaInfo?.linkedIn}`, '_blank')}>
                                        <LinkedIn sx={{ color: '#2867B2' }} />
                                    </IconButton>}
                                    {company?.companyInformation?.socialMediaInfo?.twitter && <IconButton
                                        onClick={() => window.open(company?.companyInformation?.socialMediaInfo?.twitter?.includes('http') ? company?.companyInformation?.socialMediaInfo?.twitter : `http://${company?.companyInformation?.socialMediaInfo?.twitter}`, '_blank')}
                                    >
                                        <Twitter sx={{ color: '#1DA1F2' }} />
                                    </IconButton>}
                                </Box>
                            </Box>
                        </Box>



                        <Typography variant="h6" sx={{ mt: 0, ml: 5 }}>About Company </Typography>
                        <Typography sx={{ mt: 1, mx: 5 }}>{parse(company?.companyInformation?.detailedDescription ?? '')}</Typography>


                        <Typography variant="h6" sx={{ mt: 2, ml: 5 }}>Open Positions</Typography>

                        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: 5, width: '100%', gap: 3 }}>
                            {internships?.data?.map((item, index) => (
                                <Grid item xs={12} key={index}>
                                    <InternshipCard
                                        key={index}
                                        item={item}
                                        onOpenDetail={() => handleTogleIntenshipDetail(item?._id)}
                                        handleLikeAndDislike={() => handleLikeAndDislike(item)}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                    </Card>
                </Grid >
                <Grid item xs={3}>
                    <Card sx={{ py: 3, px: 2, display: "flex", flexDirection: "column", justifyContent: 'flex-start' }}>
                        <Typography variant="h6" textAlign={"center"}>Company Information</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 3 }}>
                            <Iconify icon="ion:earth-outline" sx={{ color: '#555' }} />
                            <Typography variant="h7" sx={{ color: '#555' }} >{company?.companyInformation?.country?.name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:location-outline" sx={{ color: '#555' }} />
                            <Typography variant="h7" sx={{ color: '#555' }}>{company?.companyInformation?.city?.name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:briefcase-outline" sx={{ color: '#555' }} />
                            <Typography variant="h7" sx={{ color: '#555' }}>{company?.companyInformation?.industry?.name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:people-circle-outline" sx={{ color: '#555' }} />
                            <Typography variant="h7" sx={{ color: '#555' }}>{company?.companyInformation?.numberOfEmployee?.name} Employees</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Iconify icon="ion:calendar-outline" sx={{ color: '#555' }} />
                            <Typography variant="h7" sx={{ color: '#555' }} >Founded in {moment(company?.companyInformation?.foundedIn).format('YYYY')}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, cursor: 'pointer' }}
                            onClick={() => window.open(company?.companyInformation?.companyWebsite?.includes('http') ? company?.companyInformation?.companyWebsite : `http://${company?.companyInformation?.companyWebsite}`, '_blank')}
                        >
                            <Iconify icon="ion:git-commit-outline" sx={{ color: '#555' }} />
                            <Typography variant="h7" sx={{ color: '#555' }}>{company?.companyInformation?.companyWebsite}</Typography>
                        </Box>
                    </Card>
                </Grid>

            </Grid >
        </DashboardLayout >
    )
}

export default AboutCompany