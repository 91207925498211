import { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box, Button, Card, Typography, Stepper, Step, StepContent, Paper, FormGroup, FormControlLabel, Checkbox,
    TextField, CircularProgress, Grid, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Slide, IconButton, Link,
    FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";
import OnboardingLayout from "layouts/OnboardingLayout";
import { imageCompress, imageUpload } from "utils/helpers";
import { Dropdown } from "components";
import {
    user_parametersFunc, anonymous_countriesFunc, anonymous_citiesFunc,
    self_updateUserFunc, intern_addUniversityEducationFunc, intern_addForeignLanguageFunc,
    intern_addSkillFunc, intern_updateInternOnboardingStatusFunc, anonymous_policiesFunc,
    self_updateUserSettingsFunc
} from 'context';
import { useSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";
import userImage from 'assets/images/common/user.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { genderOptions } from 'utils';
import { user_parameters_educationsFunc } from "context";
import { Add, DeleteOutline, Translate, Update } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { format, } from 'date-fns';
import EducationIcon from 'assets/icons/onboarding/upper-text-education-detail-icon.png';
import moment from "moment";
import { Title, StepsLabel } from "page-sections/onboarding/intern";
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import parse from 'html-react-parser';
import AuthorizedIcon from 'assets/icons/dashboard/authorized.png';

const info = [

]
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

console.warn = () => { }


const OnboardingPage = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accessToken } = useSelector((state) => state.user);
    const { t, i18n } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const { anonymous_countriesData: countries, user_parametersData: parameters,
        user_parameters_educationsData: educations } = useSelector((state) => state.parameters);
    const { anonymous_policiesData: policies, anonymousPoliciesLoading: policiesLoading } = useSelector((state) => state.anonymous_policies);

    const [cities, setCities] = useState([]);
    const [compressedImage, setCompressedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [university, setUniversity] = useState("")
    const [universities, setUniversities] = useState([])
    const [selectedUniversities, setSelectedUniversities] = useState([])
    const [openUniversity, setOpenUniversity] = useState(false)
    const [checkStillAttending, setCheckStillAttending] = useState(false)
    const [editableUniversity, setEditableUniversity] = useState(null)
    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [openLanguage, setOpenLanguage] = useState(false)
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [checkOpen, setCheckOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { self_internData: selfIntern } = useSelector((state) => state.self_intern);
    const [openDefaultLanguage, setOpenDefaultLanguage] = useState(true);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState('en');

    const handleSkillChange = (event, value) => {
        if (value?.name) {
            setSelectedSkills([...selectedSkills, value])
        } else {
            setSelectedSkills([...selectedSkills, { name: value }])
        }
    };

    const userValidationSchema = Yup.object().shape({
        name: Yup.string().min(2, "Name must be at least 2 characters").required("Name is required"),
        surname: Yup.string().min(2, "Name must be at least 2 characters").required("Surname is required"),
        gender: Yup.string().required("Gender is required"),
        birthDate: Yup.date().required("BirthDate is required"),
        profileImageUrl: Yup.string().nullable(),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        nationality: Yup.string().required("Nationality is required"),


    })

    const userInitialValues = {
        name: selfIntern?.personalInformation?.name ?? "",
        surname: selfIntern?.personalInformation?.surname ?? "",
        gender: "",
        birthDate: null,
        nationality: "",
        country: "",
        city: "",
        university: "",
        universityName: "",
        department: "",
        startDate: null,
        finishDate: null,
        language: "",
        languageLevel: "",
        skills: [],
        skill: "",
        profileImageUrl: "",
        accept: false
    }

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, } = useFormik({
        enableReinitialize: true,
        validationSchema: userValidationSchema,
        initialValues: userInitialValues,
        onSubmit: (values) => {
            handleUpdateUserInfo({ values });
        }
    })

    const steps = [
        {
            label: t("Personal Information"),
            description: t(`Could you please provide some information about yourself?`),
        },
        {
            label: t("Personal Details"),
            description: t(`Could you please tell us a little more about yourself?`),
        },
        {
            label: t('Educational Information'),
            description:
                t('Could you please provide some information about your education?'),
        },
        {
            label: t('Foreign Languages'),
            description: t(`Could you please provide some information about your foreign languages?`),
        },
        {
            label: t('Skills'),
            description: t(`Finally, we would like to learn about your skills.`),
        },
    ];


    const handleNext = () => {

        if (activeStep === 0) {
            if (values.name && values.surname && values.birthDate && values.gender) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        if (activeStep === 1) {
            if (true) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
        if (activeStep === 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        if (activeStep === 3) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChangeCompanyLogoButton = () => {
        const fileInput = document.getElementById('file-input2');
        fileInput.click();
    };

    const handleCompanyLogoChange = (e) => {
        const file = e.target.files[0];
        imageCompress({ file, setCompressedImage, enqueueSnackbar, ratio: 0.4, maxWidth: 300 });
    };

    const handleAddUniversity = () => {

        setSelectedUniversities([...selectedUniversities, {
            university: {
                "_id": values.university._id,
                "name": values.university.name,
            },
            department: {
                "_id": values.department,
                "name": educations?.universityDepartments?.data?.filter?.(department => department?._id === values.department)?.[0]?.name
            },
            startDate: values.startDate,
            finishDate: values.finishDate
        }])

        setOpenUniversity(false)

        setFieldValue("university", "")
        setFieldValue("department", "")
        setFieldValue("startDate", null)
        setFieldValue("finishDate", null)
        setFieldValue("universityName", "")
        setCheckStillAttending(false)
    }

    const handleUpdateUniversity = () => {

        setSelectedUniversities(selectedUniversities?.map((item, index) => {
            if (index === editableUniversity.index) {
                return {
                    university: {
                        "_id": values.university._id,
                        "name": values.university.name,
                    },
                    department: {
                        "_id": values.department,
                        "name": educations?.universityDepartments?.data?.filter?.(department => department?._id === values.department)?.[0]?.name
                    },
                    startDate: values.startDate,
                    finishDate: values.finishDate
                }
            } else {
                return item
            }
        }))

        setOpenUniversity(false)
        setFieldValue("university", "")
        setFieldValue("department", "")
        setFieldValue("startDate", null)
        setFieldValue("finishDate", null)
        setFieldValue("universityName", "")
        setCheckStillAttending(false)
    }
    console.log(selectedSkills)
    const handleRemoveUniversity = () => {
        setSelectedUniversities(selectedUniversities?.filter((item, index) => index !== editableUniversity.index))
        setOpenUniversity(false)
        setFieldValue("university", "")
        setFieldValue("department", "")
        setFieldValue("startDate", null)
        setFieldValue("finishDate", null)
        setFieldValue("universityName", "")
        setCheckStillAttending(false)
    }

    const handleAddLanguage = () => {
        setSelectedLanguages([...selectedLanguages, {
            language: {
                "_id": values.language,
            },
            level: {
                "_id": values.languageLevel,
            }
        }])
        setFieldValue("language", "")
        setFieldValue("languageLevel", "")
        setOpenLanguage(false)
    }

    const handleRemoveLanguage = (index) => {
        setSelectedLanguages(selectedLanguages?.filter((item, i) => i !== index))
    }

    const handleUpdateUserInfo = async ({ values }) => {
        console.log(values)

        console.log(selectedUniversities)
        console.log(selectedLanguages)
        console.log(selectedSkills)

        try {
            setLoading(true);

            const resProfileImage = compressedImage && await imageUpload({
                presignedType: 'profileImage',
                accessToken: accessToken,
                image: compressedImage?.image,
                imageType: compressedImage?.type
            });

            const selfUserRes = await self_updateUserFunc({ values: values, accessToken: accessToken, profileImage: resProfileImage ?? values.profileImageUrl });
            if (!(selfUserRes._id)) throw new Error(selfUserRes ?? "Company user creation failed.");

            selectedUniversities?.map(async (item, index) => {
                const universityEducationRes = await intern_addUniversityEducationFunc({ selectedUniversity: item, accessToken: accessToken });
                if (!(universityEducationRes?._id)) throw new Error(universityEducationRes ?? "University education creation failed.");
            })

            selectedLanguages?.map(async (item, index) => {
                const languageRes = await intern_addForeignLanguageFunc({ selectedLanguage: item, accessToken: accessToken });
                if (!(languageRes?._id)) throw new Error(languageRes ?? "Language creation failed.");
            })

            selectedSkills?.map(async (item, index) => {
                const skillRes = await intern_addSkillFunc({ selectedSkill: item, accessToken: accessToken });
                if (!(skillRes?._id)) throw new Error(skillRes ?? "Skill creation failed.");
            })

            const statusRes = await intern_updateInternOnboardingStatusFunc({ accessToken: accessToken });
            if (statusRes?.success) {
                enqueueSnackbar(t("Welcome to InternIF!"), { variant: 'success' });
                window.location.reload()
            } else {
                throw new Error(statusRes ?? "User update failed.");
            }

            enqueueSnackbar(t("Welcome to InternIF!"), { variant: 'success' });
            window.location.reload()

        } catch (err) {
            console.log(err);
            enqueueSnackbar(t("An error has been detected. Please try again soon!"), { variant: 'error' });
            return err;
        } finally {
            setLoading(false);
        }

    }

    const handleClickOpen = (type) => () => {

        dispatch(anonymous_policiesFunc({ domain: "app_internif", type: type, target: "sales_intern_package" }))

        if (type) {
            setOpenDialog(true);
        }
    };

    const handleSaveDefaultLanguage = () => {
        self_updateUserSettingsFunc({ accessToken: accessToken, defaultLanguage: selectedDefaultLanguage }).then((res) => {
            if (res?._id) {
                i18n.changeLanguage(selectedDefaultLanguage);
                setOpenDefaultLanguage(false);
            } else {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' });
            }
        })
    }

    useEffect(() => {
        if (compressedImage) {
            setFieldValue("profileImageUrl", compressedImage?.url)
        }
    }, [compressedImage, setFieldValue])

    useEffect(() => {
        dispatch(user_parametersFunc({ accessToken }));
        dispatch(anonymous_countriesFunc({ accessToken }));
    }, [dispatch, accessToken]);


    useEffect(() => {
        accessToken && values?.country && dispatch(anonymous_citiesFunc({ accessToken, countryId: values?.country })).then((res) => {
            setCities(res.payload?.anonymous_cities?.data);
        });
    }, [accessToken, dispatch, values?.country]);


    useEffect(() => {
        // console.log({ university })
        if (university?.length >= 2) {
            const selected = educations?.universities?.data?.filter?.(item => item.name.toLowerCase().includes(university.toLowerCase()))
            // console.log({ selected })
            setUniversities(selected)
        } else (
            setUniversities([])
        )
    }, [university, educations?.universities?.data])

    useEffect(() => {
        accessToken && dispatch(user_parameters_educationsFunc({ accessToken }));
    }, [accessToken, dispatch]);

    const isStepFailed = (step) => {
        return step === 1;
    };

    console.log(values.country)

    return <OnboardingLayout info={info} activeStep={activeStep}>
        <Box display={"flex"} minHeight={"1010px"} flexDirection={"column"} alignItems={"center"} width="100%" sx={{ mt: 2 }}>

            <Title />

            <Card sx={{
                p: 3, mt: 1, mb: 3, borderRadius: '12px', width: { xs: '100%', lg: 800 },
            }} >
                <Box>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => {
                            return (
                                <Step key={step.label} sx={{
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: 'darkseagreen',
                                    },
                                }}>

                                    <StepsLabel
                                        index={index}
                                        step={step}
                                        StepIconComponent
                                    />

                                    <StepContent>
                                        <Box sx={{ mb: 2 }} flexDirection="column">
                                            <Typography>{step.description}</Typography>
                                            {index === 0 && <>
                                                <Grid container spacing={2} sx={{ width: '100%', mt: "10px" }}>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="name"
                                                            label={t("First Name*")}
                                                            value={values?.name}
                                                            onChange={(e => {
                                                                const enteredValue = e.target.value;
                                                                const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                                                setFieldValue("name", capitalizedValue)
                                                            })}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.name && errors.name)}
                                                            helperText={touched.name && errors.name}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="surname"
                                                            label={t("Last Name*")}
                                                            value={values?.surname}
                                                            onChange={(e => {
                                                                const enteredValue = e.target.value;
                                                                const capitalizedValue = enteredValue.charAt(0).toUpperCase() + enteredValue.slice(1);
                                                                setFieldValue("surname", capitalizedValue)
                                                            })}
                                                            onBlur={handleBlur}
                                                            error={Boolean(touched.surname && errors.surname)}
                                                            helperText={touched.surname && errors.surname}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Dropdown
                                                            setFieldValue={setFieldValue}
                                                            errors={errors}
                                                            data={genderOptions}
                                                            label={t("Gender*")}
                                                            labelId={"gender-label"}
                                                            value={values.gender}
                                                            id={"gender"}
                                                            type={"withoutId"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MDPicker
                                                            sx={{ width: '100%' }}
                                                            label={t("Birthdate*")}
                                                            value={values.birthDate}
                                                            openTo="year"
                                                            format="dd MMM yyyy"
                                                            views={['year', 'month', 'day']}
                                                            onChange={(value) => setFieldValue("birthDate", value)}
                                                            name="birthDate"
                                                            maxDate={new Date() - 1000 * 60 * 60 * 24 * 365 * 16}
                                                            minDate={1900}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>}
                                            {index === 1 && <>
                                                <Grid container spacing={2} sx={{ width: '100%', mt: "10px" }}>
                                                    <Grid item xs={12} md={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex", justifyContent: "center",
                                                                alignItems: "center", backgroundColor: '#f6f6f6',
                                                                borderRadius: "8px", cursor: "pointer", width: '100%',
                                                                borderWidth: 1, borderStyle: 'solid', borderColor: "#dedede",
                                                                '&:hover': { borderColor: "#000" }, py: "4px"
                                                            }} mr="4%"
                                                            onClick={handleChangeCompanyLogoButton}>
                                                            <img
                                                                src={compressedImage?.url ? compressedImage?.url : userImage}
                                                                alt="profile" style={{ objectFit: 'cover', borderRadius: "50%", width: 45, height: 45, borderWidth: 1, borderStyle: 'dashed', borderColor: "#bbb", padding: 2, }} />
                                                            <Typography display="flex" sx={{ ml: 2 }}> {t("Profile Image")}<CloudUploadIcon sx={{ ml: 1, color: '#555' }} />   </Typography>

                                                            <input
                                                                id="file-input2"
                                                                type="file"
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={handleCompanyLogoChange}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Dropdown
                                                            setFieldValue={setFieldValue}
                                                            errors={errors}
                                                            data={countries}
                                                            label={t("Nationality*")}
                                                            labelId={"nationality-label"}
                                                            value={values.nationality}
                                                            id={"nationality"}
                                                            type={"withFlag"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Dropdown
                                                            setFieldValue={setFieldValue}
                                                            errors={errors}
                                                            data={countries}
                                                            label={t("Country*")}
                                                            labelId={"country-label"}
                                                            value={values.country}
                                                            id={"country"}
                                                            type={"withFlag"}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Dropdown
                                                            setFieldValue={setFieldValue}
                                                            disabled={!values.country}
                                                            errors={errors}
                                                            data={cities}
                                                            label={t("City*")}
                                                            labelId={"city-label"}
                                                            value={values.city}
                                                            id={"city"}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>}
                                            {index === 2 && <>
                                                {selectedUniversities?.length === 0 && <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                                                    <Grid item xs={12} md={6}>
                                                        <Autocomplete
                                                            name="university"
                                                            noOptionsText={t("No university found")}
                                                            options={universities ?? []}
                                                            autoHighlight
                                                            getOptionLabel={(option) => option?.name ?? ""}
                                                            isOptionEqualToValue={(option, value) => option?._id === value?._id}
                                                            value={values.university}
                                                            onChange={(event, newValue) => { setFieldValue("university", newValue) }}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {option.name}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    name="university"
                                                                    value={university}
                                                                    onChange={(event, newValue) => { setUniversity(event.target.value) }}
                                                                    {...params}
                                                                    label={t("University")}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Dropdown
                                                            setFieldValue={setFieldValue}
                                                            errors={errors}
                                                            data={educations?.universityDepartments?.data}
                                                            label={t("Department")}
                                                            labelId={"department-label"}
                                                            value={values.department}
                                                            id={"department"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MDPicker
                                                            sx={{ width: '100%' }}
                                                            label={t("Start Date")}
                                                            value={values.startDate}
                                                            openTo="month"
                                                            onChange={(value) => setFieldValue("startDate", value)}
                                                            name="startDate"
                                                            views={['month', 'year']}
                                                            maxDate={new Date()}
                                                            minDate={values.birthDate}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MDPicker
                                                            disabled={checkStillAttending || !values.startDate}
                                                            sx={{ width: '100%' }}
                                                            label={!checkStillAttending ? "Finish Date" : "Still attending"}
                                                            value={values.finishDate}
                                                            openTo="month"
                                                            onChange={(value) => setFieldValue("finishDate", value)}
                                                            name={t("finishDate")}
                                                            views={['month', 'year']}
                                                            maxDate={new Date()}
                                                            minDate={values.startDate}
                                                        />
                                                        <FormGroup sx={{ mt: 1 }}>
                                                            <FormControlLabel control={
                                                                <Checkbox
                                                                    checked={checkStillAttending}
                                                                    onChange={(e) => {
                                                                        setCheckStillAttending(e.target.checked)
                                                                        setFieldValue("finishDate", null)
                                                                    }}
                                                                />} label={t("Still attending")} />
                                                        </FormGroup>
                                                    </Grid>
                                                    <Button variant="contained"
                                                        disabled={!(values.university && values.department && values.startDate && (values.finishDate || checkStillAttending))}
                                                        onClick={handleAddUniversity} sx={{ mt: 1 }}>
                                                        <Add sx={{ mr: 1 }} fontSize="small" /> {t('Add University')}
                                                    </Button>
                                                </Grid>}
                                                {selectedUniversities?.length > 0 && <Grid container sx={{ width: '100%', mt: "10px" }} spacing={2}>
                                                    {selectedUniversities?.map((item, index) => (
                                                        <Grid item xs={12} md={6}>
                                                            <Card sx={{
                                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2,
                                                                '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)", cursor: "pointer" }
                                                            }} onClick={() => {
                                                                setOpenUniversity(true)
                                                                setEditableUniversity({ index: index })
                                                                setFieldValue("university", item?.university)
                                                                setFieldValue("department", item?.department?._id)
                                                                setFieldValue("startDate", item?.startDate)
                                                                setFieldValue("finishDate", item?.finishDate)
                                                                setCheckStillAttending(!item?.finishDate)
                                                            }}>
                                                                <img src={EducationIcon} alt="education" style={{ width: 50, height: 50, objectFit: 'cover' }} />
                                                                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {item?.university?.name} </Typography>
                                                                <Typography variant="h7" sx={{ fontWeight: 'bold', textAlign: 'center' }}> {educations?.universityDepartments?.data?.filter?.(department => department?._id === item?.department?._id)?.[0]?.name} </Typography>
                                                                <Typography variant="h8" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                                                    {format(new Date(selectedUniversities[index]?.startDate), 'dd MMM yyyy')} /
                                                                    {selectedUniversities[index]?.finishDate ? format(new Date(selectedUniversities[index]?.finishDate), ' dd MMM yyyy') : ' Still Attending'}
                                                                </Typography>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                    <Grid item xs={12} md={6} >
                                                        {selectedUniversities?.length <= 3 && <Card sx={{
                                                            width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2,
                                                            '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)", cursor: "pointer" }
                                                        }} onClick={() => {
                                                            setOpenUniversity(true)
                                                            setEditableUniversity(null)
                                                            setFieldValue("university", "")
                                                            setFieldValue("department", "")
                                                            setFieldValue("startDate", null)
                                                            setFieldValue("finishDate", null)
                                                            setCheckStillAttending(false)
                                                        }}>
                                                            <Add sx={{ mr: 1 }} fontSize="large" />
                                                            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 }}>   {t('Add University')}</Typography>
                                                        </Card>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                }
                                            </>}
                                            {index === 3 && <>
                                                {selectedLanguages?.length === 0 && <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                                                    <Grid item xs={12} md={5}>
                                                        <Dropdown
                                                            setFieldValue={setFieldValue}
                                                            errors={errors}
                                                            data={parameters?.languages?.data}
                                                            label={t("Language")}
                                                            labelId={"language-label"}
                                                            value={values.language}
                                                            id={"language"}
                                                        // type={"withFlagForLanguage"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={5}>
                                                        <Dropdown
                                                            setFieldValue={setFieldValue}
                                                            errors={errors}
                                                            data={parameters?.languageLevels?.data}
                                                            label={t("Level")}
                                                            labelId={"languageLevel-label"}
                                                            value={values.languageLevel}
                                                            id={"languageLevel"}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" sx={{ height: '98%' }}
                                                            disabled={!(values.language && values.languageLevel)}
                                                            onClick={handleAddLanguage}>
                                                            <Add sx={{ mr: 1 }} fontSize="small" /> {t('Add')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>}
                                                {selectedLanguages?.length > 0 && <Grid container sx={{ width: '100%', mt: "10px" }} spacing={2}>
                                                    {
                                                        selectedLanguages?.map((item, index) => (
                                                            <Grid item xs={12} md={3}>
                                                                <Card sx={{
                                                                    display: "flex", height: '100%', flexDirection: "column", justifyContent: "center", alignItems: "center", p: 2,
                                                                    '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)" }
                                                                }}>
                                                                    {/* <img src={`https://flagcdn.com/${parameters?.languages?.data?.filter?.
                                                                        (language => language?._id === item?.language?._id)?.[0]?.metadata?.code.toLowerCase()}.svg`}
                                                                        alt="language"
                                                                        style={{ width: 40, height: 40, borderRadius: 20, objectFit: 'cover' }} /> */}
                                                                    <Translate mt={1} />
                                                                    <Typography variant="h7" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>   {parameters?.languages?.data?.filter?.(language => language?._id === item?.language?._id)?.[0]?.name} </Typography>
                                                                    <Typography variant="h8" sx={{ fontWeight: 'bold', textAlign: 'center' }}> {parameters?.languageLevels?.data?.filter?.(level => level?._id === item?.level?._id)?.[0]?.name} </Typography>

                                                                    <IconButton onClick={() => handleRemoveLanguage(index)} sx={{ position: 'absolute', right: 3, top: 3 }}>
                                                                        <DeleteOutline color="error" />
                                                                    </IconButton>
                                                                </Card>
                                                            </Grid>
                                                        ))
                                                    }
                                                    <Grid item xs={12} md={3} >
                                                        {selectedLanguages?.length <= 3 && <Card
                                                            onClick={() => setOpenLanguage(true)}
                                                            sx={{
                                                                display: "flex", flexDirection: "column", height: '100%', justifyContent: "center", alignItems: "center", p: 2,
                                                                '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)", cursor: "pointer" }
                                                            }} >
                                                            <Add sx={{ mr: 1 }} fontSize="medium" />
                                                            <Typography variant="h7" sx={{ fontWeight: 'bold', mt: 1 }}>   {t('Add Language')}</Typography>
                                                        </Card>}
                                                    </Grid>
                                                </Grid>
                                                }
                                            </>}

                                            {index === 4 && <>
                                                <Grid container spacing={2} sx={{ width: '100%', mt: "10px" }}>
                                                    <Grid item xs={12} md={12} mb={2}>
                                                        {selectedSkills?.length <= 24 && <Autocomplete
                                                            freeSolo
                                                            key={moment().valueOf()}
                                                            options={parameters?.skills?.data ?? []}
                                                            getOptionLabel={(option) => typeof option === 'object' ? option.name : option}
                                                            onChange={(event, value) => { handleSkillChange(event, value) }}
                                                            renderInput={(params) => <TextField {...params} label={t("Skills")} value={values.skill} />}
                                                        />}
                                                    </Grid>
                                                    {
                                                        selectedSkills?.map((item, index) => (
                                                            <Card
                                                                onClick={() => setSelectedSkills(selectedSkills?.filter((item, i) => i !== index))}
                                                                sx={{
                                                                    display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", pl: 2, py: 0,
                                                                    backgroundColor: 'primary.main', color: 'white', borderRadius: '100px', cursor: "pointer", mb: 2, ml: 2,
                                                                    '&:hover': { boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.2)" }
                                                                }}>
                                                                <Typography variant="h7" sx={{ fontWeight: 'bold' }}>   {item?.name} </Typography>
                                                                <IconButton >
                                                                    <CloseIcon fontSize="small" sx={{ color: 'white' }} />
                                                                </IconButton>
                                                            </Card>
                                                        ))

                                                    }
                                                </Grid>
                                            </>}




                                        </Box>
                                        <Box sx={{ mb: 2, mr: 1, display: 'flex', justifyContent: 'space-between' }}>
                                            <Button
                                                variant="outlined"
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {t('Back')}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                disabled={loading ||
                                                    (index === 0 && (!values.name || !values.surname || !values.gender || !values.birthDate)) ||
                                                    (index === 1 && (!values.nationality || !values.country || !values.city)) ||
                                                    (index === 2 && selectedUniversities?.length === 0) ||
                                                    (index === 3 && selectedLanguages?.length === 0) ||
                                                    (index === 4 && selectedSkills?.length === 0)}
                                                onClick={index === steps.length - 1 ? handleSubmit : handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {loading ? <CircularProgress size="1rem" /> : index === steps.length - 1 ? t('Finish') : t('Continue')}
                                            </Button>
                                        </Box>
                                    </StepContent>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button type="submit" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                Reset
                            </Button>
                        </Paper>
                    )}
                </Box>


            </Card>

            <Box height={40} />

            <Dialog
                open={openUniversity}
                keepMounted
                onClose={() => setOpenUniversity(false)}
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}

            >
                <DialogTitle>{editableUniversity ? 'Update University' : 'Add University'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ width: '100%', mt: "10px", justifyContent: 'flex-end' }}>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                name="university"
                                noOptionsText={t("No university found")}
                                options={universities ?? []}
                                autoHighlight
                                getOptionLabel={(option) => option?.name ?? ""}
                                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                                value={values.university}
                                onChange={(event, newValue) => { setFieldValue("university", newValue) }}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        name="university"
                                        value={university}
                                        onChange={(event, newValue) => { setUniversity(event.target.value) }}
                                        {...params}
                                        label="University"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                setFieldValue={setFieldValue}
                                errors={errors}
                                data={educations?.universityDepartments?.data}
                                label={"Department"}
                                labelId={"department-label"}
                                value={values.department}
                                id={"department"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDPicker
                                sx={{ width: '100%' }}
                                label="Start Date"
                                value={values.startDate}
                                openTo="month"
                                onChange={(value) => setFieldValue("startDate", value)}
                                name="startDate"
                                views={['month', 'year']}
                                maxDate={new Date()}
                                minDate={values.birthDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDPicker
                                disabled={checkStillAttending || !values.startDate}
                                sx={{ width: '100%' }}
                                label={!checkStillAttending ? "Finish Date" : "Still attending"}
                                value={values.finishDate}
                                openTo="month"
                                onChange={(value) => setFieldValue("finishDate", value)}
                                name="finishDate"
                                views={['month', 'year']}
                                maxDate={new Date()}
                                minDate={values.startDate}
                            />
                            <FormGroup sx={{ mt: 1 }}>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={checkStillAttending}
                                        onChange={(e) => {
                                            setCheckStillAttending(e.target.checked)
                                            setFieldValue("finishDate", null)
                                        }}
                                    />} label="Still attending" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={() => setOpenUniversity(false)}>Cancel</Button>
                    {editableUniversity && <Button color="error" variant="outlined" onClick={handleRemoveUniversity}>
                        <DeleteOutline sx={{ mr: 1 }} fontSize="small" />
                        Remove University</Button>}
                    <Button
                        disabled={!(values.university && values.department && values.startDate && (values.finishDate || checkStillAttending))}
                        color="primary" variant="contained" onClick={editableUniversity ? handleUpdateUniversity : handleAddUniversity}><Update sx={{ mr: 1 }} fontSize="small" /> {editableUniversity ? 'Update University' : 'Add University'}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: 500, borderRadius: 2 } }}
                open={openLanguage}
                keepMounted
                onClose={() => setOpenLanguage(false)}
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}
            >
                <DialogTitle>{'Add Language'}</DialogTitle>
                <DialogContent sx={{ width: '100%', paddingRight: 1 }}>
                    <Grid container spacing={2} mt={0} sx={{ width: '100%' }}>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                setFieldValue={setFieldValue}
                                errors={errors}
                                data={parameters?.languages?.data.filter(item => !selectedLanguages?.map(item => item?.language?._id)?.includes(item?._id))}
                                label={"Language"}
                                labelId={"language-label"}
                                value={values.language}
                                id={"language"}
                            // type={"withFlagForLanguage"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                setFieldValue={setFieldValue}
                                errors={errors}
                                data={parameters?.languageLevels?.data}
                                label={"Level"}
                                labelId={"languageLevel-label"}
                                value={values.languageLevel}
                                id={"languageLevel"}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={() => setOpenLanguage(false)}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={handleAddLanguage}>
                        <Add sx={{ mr: 1 }} fontSize="small" />Add Language</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={checkOpen} onClose={() => setCheckOpen(false)} >
                <DialogTitle sx={{ textAlign: 'center' }} >
                    Approvals of Policies <img src={AuthorizedIcon} alt="Check" style={{ width: 20, height: 20, marginLeft: 5, marginBottom: 4 }} />
                </DialogTitle>

                <DialogContent>

                    <Box display="flex" justifyContent="space-between" alignItems={'center'} width="100%" mb={1}>
                        <FormControlLabel
                            control={<Checkbox
                                color="primary"
                                checked={values?.accept}
                                onChange={handleChange}
                                name="accept"
                            />}
                            label={<Typography variant="h8" color="primary.main">
                                I’ve read and accept the <Link component="span" onClick={handleClickOpen("preliminary_information_form")} color="info.main" variant="h8">
                                    Preliminary Information Form
                                </Link> and <Link component="span" onClick={handleClickOpen("sales_agreement")} color="info.main" variant="h8">
                                    Sales Agreement</Link>  of InternIF.
                            </Typography>}
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setCheckOpen(false)} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        disabled={loading || !values?.accept}
                        onClick={() => handleUpdateUserInfo({ values })} color="primary" variant="contained" autoFocus>
                        {loading ? <CircularProgress size={"1rem"} /> : "Approve"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={true} sx={{ minWidth: 'md', width: '500px', mt: 4 }}>
                    {policiesLoading
                        ? <CircularProgress />
                        : parse(policies?.data?.[0]?.content?.tr ?? '')}
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => setOpenDialog(false)}>OK</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDefaultLanguage}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    {t("Choose your language")}
                </DialogTitle>

                <DialogContent sx={{ width: { xs: '100%', sm: "400px" }, p: 2 }}>
                    <Box display="flex" justifyContent="center" width="100%">
                        <FormControl sx={{ width: 200, mt: 1, pt: 1 }}>
                            <InputLabel id="demo-simple-select-label">{t("Language")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedDefaultLanguage}
                                label="Language"
                                onChange={(e) => setSelectedDefaultLanguage(e.target.value)}
                            >
                                <MenuItem value={"en"}>{t('English')}</MenuItem>
                                <MenuItem value={"tr"}>{t('Turkish')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={handleSaveDefaultLanguage} color="primary" variant="contained" size="large" sx={{ px: 2 }}>
                        {t("Save")}
                    </Button>
                </DialogActions>
            </Dialog>




        </Box >

    </OnboardingLayout >
}


export default OnboardingPage;