
import { FormControl, Grid, InputLabel, TextField, MenuItem, Select, Button } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { expectionsList, internshipAreaList, usePackageTimeList } from 'utils';
import { useEffect } from 'react';
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import { t } from 'i18next';

export const FormComponent = ({
    name = [0, 12, 12, 6, 6, 6],
    surname = [0, 12, 12, 6, 6, 6],
    identityNumber = [0, 12, 12, 6, 6, 6],
    email = [0, 12, 12, 6, 6, 6],
    phone = [0, 12, 12, 6, 6, 6],
    country = [0, 12, 12, 6, 6, 6], // dropdown
    country2 = [0, 12, 12, 6, 6, 6], // input
    city = [0, 12, 12, 6, 6, 6], // dropdown
    city2 = [0, 12, 12, 6, 6, 6], // input
    address = [0, 12, 12, 6, 6, 6],
    postcode = [0, 12, 12, 6, 6, 6],
    companyName = [0, 12, 12, 6, 6, 6],
    taxOffice = [0, 12, 12, 6, 6, 6],
    taxNumber = [0, 12, 12, 6, 6, 6],
    expection = [0, 12, 12, 6, 6, 6], // dropdown
    internshipArea = [0, 12, 12, 6, 6, 6], // dropdown
    internshipArea2 = [0, 12, 12, 6, 6, 6], // dropdown - premium
    usePackageTime = [0, 12, 12, 6, 6, 6], // dropdown
    date = [0, 12, 12, 6, 6, 6], // date picker
    extraInfo = [0, 12, 12, 6, 6, 6], // textarea
    weeklyWorkHours = [0, 12, 12, 6, 6, 6], // integer input
    parameters,
    setSelectedCountry,
    handleSubmitForm,
    button,
    defaultValues,
    setValues,
}) => {


    const countries = parameters?.countries;
    const cities = parameters?.cities;
    const internshipArea2List = parameters?.internshipArea2List;

    const HelperText = ({ text }) => <div style={{ color: '#961650', fontWeight: 400, fontSize: "0.75rem", marginLeft: '14px', marginTop: '8px' }}>{text}</div>

    const defaultInitialValues = {
        name: defaultValues?.name ?? '',
        surname: defaultValues?.surname ?? '',
        identityNumber: defaultValues?.identityNumber ?? '',
        email: defaultValues?.email ?? '',
        phone: defaultValues?.phone ?? '',
        country: defaultValues?.country ?? '',
        city: defaultValues?.city ?? '',
        address: defaultValues?.address ?? '',
        postcode: defaultValues?.postcode ?? '',
        country2: defaultValues?.country2 ?? '',
        city2: defaultValues?.city2 ?? '',
        companyName: defaultValues?.companyName ?? '',
        taxOffice: defaultValues?.taxOffice ?? '',
        taxNumber: defaultValues?.taxNumber ?? '',
        expection: defaultValues?.expection ?? '',
        internshipArea: defaultValues?.internshipArea ?? '',
        internshipArea2: defaultValues?.internshipArea2 ?? '',
        usePackageTime: defaultValues?.usePackageTime ?? '',
        date: defaultValues?.date ?? '',
        extraInfo: defaultValues?.extraInfo ?? '',
        weeklyWorkHours: defaultValues?.weeklyWorkHours ?? '',
    }

    const initialValues = Object.keys(defaultInitialValues).reduce((object, key) => {
        if (defaultInitialValues[key]) {
            object[key] = defaultInitialValues[key];
        }
        return object;
    }, {});

    const onSubmit = values => {
        handleSubmitForm(values)
    }

    console.log(internshipAreaList)
    const validationSchema = Yup.object({
        name: name[0] > 0 && Yup.string().required('Required').min(2, 'Minimum 2 characters'),
        surname: surname[0] > 0 && Yup.string().required('Required').min(2, 'Minimum 2 characters'),
        identityNumber: identityNumber[0] > 0 && Yup.string().required('Required').min(5, 'Minimum 5 characters'),
        email: email[0] > 0 && Yup.string().email('Invalid email format').required('Required'),
        phone: phone[0] > 0 && Yup.string().required('Required'),
        country: country[0] > 0 && Yup.string().required('Required'),
        city: city[0] > 0 && Yup.string().required('Required'),
        address: address[0] > 0 && Yup.string().required('Required'),
        postcode: postcode[0] > 0 && Yup.string().required('Required'),
        country2: country2[0] > 0 && Yup.string().required('Required'),
        city2: city2[0] > 0 && Yup.string().required('Required'),
        companyName: companyName[0] > 0 && Yup.string().required('Required'),
        taxOffice: taxOffice[0] > 0 && Yup.string().required('Required'),
        taxNumber: taxNumber[0] > 0 && Yup.string().required('Required'),
        expection: expection[0] > 0 && Yup.string().required('Required'),
        internshipArea: internshipArea[0] > 0 && Yup.string().required('Required'),
        internshipArea2: internshipArea2[0] > 0 && Yup.string().required('Required'),
        usePackageTime: usePackageTime[0] > 0 && Yup.string().required('Required'),
        date: date[0] > 0 && Yup.string().required('Required'),
        extraInfo: extraInfo[0] > 0 && Yup.string(),
        weeklyWorkHours: weeklyWorkHours[0] > 0 && Yup.number().required('Required').min(20, 'Minimum 20').max(30, 'Maximum 30'),
    })

    const { values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit,
        validationSchema
    })

    useEffect(() => {
        !button && values && setValues(values)
    }, [button, values, setValues])


    const nameInput = <Grid item xs={name[1]} sm={name[2]} md={name[3]} lg={name[4]} xl={name[5]} key={Number(name[0])}>
        <TextField
            id="name"
            name="name"
            label="Name"
            fullWidth
            value={values.name}
            onChange={(e => {
                const capitalizedValue = e.target.value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                setFieldValue("name", capitalizedValue);
            })}
            onBlur={handleBlur}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
        />
    </Grid>;

    const surnameInput = <Grid item xs={surname[1]} sm={surname[2]} md={surname[3]} lg={surname[4]} xl={surname[5]} key={surname[0]}>
        <TextField
            id="surname"
            name="surname"
            label="Surname"
            fullWidth
            value={values.surname}
            onChange={(e => {
                const capitalizedValue = e.target.value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                setFieldValue("surname", capitalizedValue);
            })}
            onBlur={handleBlur}
            error={Boolean(touched.surname && errors.surname)}
            helperText={touched.surname && errors.surname}
        />
    </Grid>;

    const identityNumberInput = <Grid item xs={identityNumber[1]} sm={identityNumber[2]} md={identityNumber[3]} lg={identityNumber[4]} xl={identityNumber[5]} key={identityNumber[0]}>
        <TextField
            id="identityNumber"
            name="identityNumber"
            label="Identity Number"
            type='number'
            fullWidth
            value={values.identityNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.identityNumber && errors.identityNumber)}
            helperText={touched.identityNumber && errors.identityNumber}
        />
    </Grid>;

    const emailInput = <Grid item xs={email[1]} sm={email[2]} md={email[3]} lg={email[4]} xl={email[5]} key={email[0]}>
        <TextField
            id="email"
            name="email"
            label="Email"
            value={values.email}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
        />
    </Grid>;

    const phoneInput = <Grid item xs={phone[1]} sm={phone[2]} md={phone[3]} lg={phone[4]} xl={phone[5]} key={phone[0]}>
        <TextField
            id="phone"
            name="phone"
            label="Phone"
            type='tel'
            fullWidth
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
        />
    </Grid>;

    const countryInput = <Grid item xs={country[1]} sm={country[2]} md={country[3]} lg={country[4]} xl={country[5]} key={Number(country[0])}>
        <FormControl fullWidth error={Boolean(touched.country && errors.country)}>
            <InputLabel id="country-label">{t("Country")}</InputLabel>
            <Select
                labelId="country-label"
                id="country"
                value={values.country}
                error={Boolean(touched.country && errors.country)}
                label={t("Country")}
                onChange={event => {
                    setFieldValue("country", event.target.value)
                    setSelectedCountry(event.target.value)
                }}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {countries?.map((option, index) => (
                    <MenuItem key={index} value={option?._id} sx={{ display: "flex", alignItems: "center", }}>
                        <img
                            loading="lazy"
                            style={{ width: "20px", height: "20px", borderRadius: '15px', objectFit: 'cover', marginRight: "10px" }}
                            src={`https://flagcdn.com/${option?.code.toLowerCase()}.svg`}
                            alt={option?.code.toLowerCase()}
                        />
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {/* helper text  */}
            {touched.country && errors.country && <HelperText text={errors.country} />}
        </FormControl>
    </Grid>;

    const cityInput = <Grid item xs={city[1]} sm={city[2]} md={city[3]} lg={city[4]} xl={city[5]} key={city[0]}>
        <FormControl fullWidth error={Boolean(touched.city && errors.city)} disabled={!values.country}>
            <InputLabel id="city-label">{t("City")}</InputLabel>
            <Select
                labelId="city-label"
                id="city"
                value={values.city}
                error={Boolean(touched.city && errors.city)}
                label={t("City")}
                onChange={event => setFieldValue("city", event.target.value)}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {cities?.map((option, index) => (
                    <MenuItem key={index} value={option?._id} sx={{ display: "flex", alignItems: "center", }}>
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {touched.city && errors.city && <HelperText text={errors.city} />}
        </FormControl>
    </Grid>;

    const addressInput = <Grid item xs={address[1]} sm={address[2]} md={address[3]} lg={address[4]} xl={address[5]} key={address[0]}>
        <TextField
            id="address"
            name="address"
            label="Address"
            multiline
            rows={1}
            value={values.address}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
        />
    </Grid>;

    const postcodeInput = <Grid item xs={postcode[1]} sm={postcode[2]} md={postcode[3]} lg={postcode[4]} xl={postcode[5]} key={postcode[0]}>
        <TextField
            id="postcode"
            name="postcode"
            label="Postcode"
            value={values.postcode}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.postcode && errors.postcode)}
            helperText={touched.postcode && errors.postcode}
        />
    </Grid>;

    const countryInput2 = <Grid item xs={country2[1]} sm={country2[2]} md={country2[3]} lg={country2[4]} xl={country2[5]} key={country2[0]}>
        <TextField
            id="country2"
            name="country2"
            label={t("Country")}
            value={values.country2}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.country2 && errors.country2)}
            helperText={touched.country2 && errors.country2}
        />
    </Grid>;

    const cityInput2 = <Grid item xs={city2[1]} sm={city2[2]} md={city2[3]} lg={city2[4]} xl={city2[5]} key={city2[0]}>
        <TextField
            id="city2"
            name="city2"
            label={t("City")}
            value={values.city2}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.city2 && errors.city2)}
            helperText={touched.city2 && errors.city2}
        />
    </Grid>;

    const companyNameInput = <Grid item xs={companyName[1]} sm={companyName[2]} md={companyName[3]} lg={companyName[4]} xl={companyName[5]} key={companyName[0]}>
        <TextField
            id="companyName"
            name="companyName"
            label="Company Name"
            value={values.companyName}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
        />
    </Grid>;

    const taxOfficeInput = <Grid item xs={taxOffice[1]} sm={taxOffice[2]} md={taxOffice[3]} lg={taxOffice[4]} xl={taxOffice[5]} key={taxOffice[0]}>
        <TextField
            id="taxOffice"
            name="taxOffice"
            label="Tax Office"
            value={values.taxOffice}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.taxOffice && errors.taxOffice)}
            helperText={touched.taxOffice && errors.taxOffice}
        />
    </Grid>;

    const taxNumberInput = <Grid item xs={taxNumber[1]} sm={taxNumber[2]} md={taxNumber[3]} lg={taxNumber[4]} xl={taxNumber[5]} key={taxNumber[0]}>
        <TextField
            id="taxNumber"
            name="taxNumber"
            label="Tax Number"
            value={values.taxNumber}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.taxNumber && errors.taxNumber)}
            helperText={touched.taxNumber && errors.taxNumber}
        />
    </Grid>;

    const expectionInput = <Grid item xs={expection[1]} sm={expection[2]} md={expection[3]} lg={expection[4]} xl={expection[5]} key={Number(expection[0])}>
        <FormControl fullWidth error={Boolean(touched.expection && errors.expection)}>
            <InputLabel id="expection-label">Expection</InputLabel>
            <Select
                labelId="expection-label"
                id="expection"
                value={values.expection}
                error={Boolean(touched.expection && errors.expection)}
                label={"Expection"}
                onChange={event => setFieldValue("expection", event.target.value)}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {expectionsList?.map((option, index) => (
                    <MenuItem key={index} value={option?.value} sx={{ display: "flex", alignItems: "center", }}>
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {touched.expection && errors.expection && <HelperText text={errors.expection} />}
        </FormControl>
    </Grid>;

    const internshipAreaInput = <Grid item xs={internshipArea[1]} sm={internshipArea[2]} md={internshipArea[3]} lg={internshipArea[4]} xl={internshipArea[5]} key={Number(internshipArea[0])}>
        <FormControl fullWidth error={Boolean(touched.internshipArea && errors.internshipArea)}>
            <InputLabel id="internshipArea-label">{t("Internship Area")}</InputLabel>
            <Select
                labelId="internshipArea-label"
                id="internshipArea"
                value={values.internshipArea}
                error={Boolean(touched.internshipArea && errors.internshipArea)}
                label={t("Internship Area")}
                onChange={event => setFieldValue("internshipArea", event.target.value)}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {internshipAreaList?.map((option, index) => (
                    <MenuItem key={index} value={option?.value} sx={{ display: "flex", alignItems: "center", }}>
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {touched.internshipArea && errors.internshipArea && <HelperText text={errors.internshipArea} />}
        </FormControl>
    </Grid>;

    const internshipArea2Input = <Grid item xs={internshipArea2[1]} sm={internshipArea2[2]} md={internshipArea2[3]} lg={internshipArea2[4]} xl={internshipArea2[5]} key={Number(internshipArea2[0])}>
        <FormControl fullWidth error={Boolean(touched.internshipArea2 && errors.internshipArea2)}>
            <InputLabel id="internshipArea-label">{t("Internship Area")}</InputLabel>
            <Select
                labelId="internshipArea-label"
                id="internshipArea"
                value={values.internshipArea2}
                error={Boolean(touched.internshipArea2 && errors.internshipArea2)}
                label={t("Internship Area")}
                onChange={event => setFieldValue("internshipArea2", event.target.value)}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {internshipArea2List?.map((option, index) => (
                    <MenuItem key={index} value={option?.name} sx={{ display: "flex", alignItems: "center", }}>
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {touched.internshipArea2 && errors.internshipArea2 && <HelperText text={errors.internshipArea2} />}
        </FormControl>
    </Grid>;

    const usePackageTimeInput = <Grid item xs={usePackageTime[1]} sm={usePackageTime[2]} md={usePackageTime[3]} lg={usePackageTime[4]} xl={usePackageTime[5]} key={Number(usePackageTime[0])}>
        <FormControl fullWidth error={Boolean(touched.usePackageTime && errors.usePackageTime)}>
            <InputLabel id="usePackageTime-label">Time</InputLabel>
            <Select
                labelId="usePackageTime-label"
                id="usePackageTime"
                value={values.usePackageTime}
                error={Boolean(touched.usePackageTime && errors.usePackageTime)}
                label={"Time"}
                onChange={event => setFieldValue("usePackageTime", event.target.value)}
                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}>
                {usePackageTimeList?.map((option, index) => (
                    <MenuItem key={index} value={option?.value} sx={{ display: "flex", alignItems: "center", }}>
                        {option?.name}
                    </MenuItem>
                ))}
            </Select>
            {touched.usePackageTime && errors.usePackageTime && <HelperText text={errors.usePackageTime} />}
        </FormControl>
    </Grid>;

    const dateInput = <Grid item xs={date[1]} sm={date[2]} md={date[3]} lg={date[4]} xl={date[5]} key={date[0]}>
        <MDPicker
            sx={{ width: '100%' }}
            label={t("Start Date")}
            value={values.date}
            format='dd/MM/yyyy'
            minDate={new Date(new Date().getTime() + 8 * 7 * 24 * 60 * 60 * 1000)}
            shouldDisableDate={(date) => date.getDay() !== 1}
            onChange={(date) => setFieldValue("date", date)}
            onBlur={handleBlur}
            error={Boolean(touched.date && errors.date)}
            helperText={touched.date && errors.date}
            fullWidth
        />
    </Grid>;

    const extraInfoInput = <Grid item xs={extraInfo[1]} sm={extraInfo[2]} md={extraInfo[3]} lg={extraInfo[4]} xl={extraInfo[5]} key={extraInfo[0]}>
        <TextField
            id="extraInfo"
            name="extraInfo"
            label="Additional Information"
            multiline
            rows={2}
            value={values.extraInfo}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.extraInfo && errors.extraInfo)}
            helperText={touched.extraInfo && errors.extraInfo}
        />
    </Grid>;

    const weeklyWorkHoursInput = <Grid item xs={weeklyWorkHours[1]} sm={weeklyWorkHours[2]} md={weeklyWorkHours[3]} lg={weeklyWorkHours[4]} xl={weeklyWorkHours[5]} key={weeklyWorkHours[0]}>
        <TextField
            id="weeklyWorkHours"
            name="weeklyWorkHours"
            label={t("Weekly Work Hours")}
            type='number'
            fullWidth
            value={values.weeklyWorkHours}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.weeklyWorkHours && errors.weeklyWorkHours)}
            helperText={touched.weeklyWorkHours && errors.weeklyWorkHours}
        />
    </Grid>;





    const formElements = [
        name[0] > 0 && nameInput,
        surname[0] > 0 && surnameInput,
        identityNumber[0] > 0 && identityNumberInput,
        email[0] > 0 && emailInput,
        phone[0] > 0 && phoneInput,
        country[0] > 0 && countryInput,
        city[0] > 0 && cityInput,
        address[0] > 0 && addressInput,
        postcode[0] > 0 && postcodeInput,
        country2[0] > 0 && countryInput2,
        city2[0] > 0 && cityInput2,
        companyName[0] > 0 && companyNameInput,
        taxOffice[0] > 0 && taxOfficeInput,
        taxNumber[0] > 0 && taxNumberInput,
        expection[0] > 0 && expectionInput,
        internshipArea[0] > 0 && internshipAreaInput,
        internshipArea2[0] > 0 && internshipArea2Input,
        usePackageTime[0] > 0 && usePackageTimeInput,
        date[0] > 0 && dateInput,
        extraInfo[0] > 0 && extraInfoInput,
        weeklyWorkHours[0] > 0 && weeklyWorkHoursInput,
    ].filter(Boolean);

    const sortedFormElements = formElements.sort((a, b) => {
        const keyA = a.key || 0;
        const keyB = b.key || 0;
        return keyA - keyB;
    });

    // Sıralanmış form elementlerini render et
    const renderedFormElements = sortedFormElements.map((formElement) => formElement);

    return <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
            {renderedFormElements}

            {button && <Grid item xs={12} sx={{ display: 'flex', justifyContent: button?.justifyContent ?? 'flex-start' }}>
                <Button variant="contained" color="primary" size={button?.size ?? 'medium'} sx={button?.sx ?? ''}
                    onClick={handleSubmit}
                    disabled={   // if any of the inputs are empty, disable the button
                        (name[0] > 0 && !values.name) ||
                        (surname[0] > 0 && !values.surname) ||
                        (identityNumber[0] > 0 && !values.identityNumber) ||
                        (email[0] > 0 && !values.email) ||
                        (phone[0] > 0 && !values.phone) ||
                        (country[0] > 0 && !values.country) ||
                        (city[0] > 0 && !values.city) ||
                        (address[0] > 0 && !values.address) ||
                        (postcode[0] > 0 && !values.postcode) ||
                        (country2[0] > 0 && !values.country2) ||
                        (city2[0] > 0 && !values.city2) ||
                        (companyName[0] > 0 && !values.companyName) ||
                        (taxOffice[0] > 0 && !values.taxOffice) ||
                        (taxNumber[0] > 0 && !values.taxNumber) ||
                        (expection[0] > 0 && !values.expection) ||
                        (internshipArea[0] > 0 && !values.internshipArea) ||
                        (internshipArea2[0] > 0 && !values.internshipArea2) ||
                        (usePackageTime[0] > 0 && !values.usePackageTime) ||
                        (date[0] > 0 && !values.date) ||
                        (extraInfo[0] > 0 && !values.extraInfo) ||
                        (weeklyWorkHours[0] > 0 && !values.weeklyWorkHours)
                    }
                >
                    {button?.icon}{button?.text}
                </Button>
            </Grid>}
        </Grid>
    </form >
}
