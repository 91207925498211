
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';
import AuthLayout from 'layouts/AuthLayout'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack'
import { BasicInputPassword, BlackButton } from 'components'
import { AuthHead } from "page-sections/auth";
import { useNavigate } from 'react-router';
import { resetPasswordFunc } from 'context';
import regexPattern from 'minimal/utils/regexPattern';



const ValidationSchema = Yup.object({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters!')
        .required('Password is required!')
        .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
        .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
        .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
        .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!"),
    verifyPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match!')
        .required('Password is required!')
})


const ResetPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get("token");

    console.log(token)

    const { handleSubmit, handleChange, handleBlur, errors, values, touched } = useFormik({
        initialValues: {
            verifyPassword: '', password: ''
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            console.log('submit', values)
            dispatch(resetPasswordFunc({
                password: values.password,
                passwordToken: token
            })).then((res) => {
                if (res?.payload?.isSuccess) {
                    enqueueSnackbar('The password has been updated!', { variant: 'success' })
                    navigate('/signin')
                } else if (res?.payload?.response?.data?.message === "PasswordMustBeDifferentFromPrevious") {
                    enqueueSnackbar('Please try again with a different password than your last 3 passwords.', { variant: 'error' })
                } else {
                    enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
                }
            }).catch((err) => {
                enqueueSnackbar('An error has been detected. Please try again soon!', { variant: 'error' })
            })

        }
    })





    return <AuthLayout>
        <Grid item xs={12} justifyContent="center" alignItems="center" flexDirection={"column"}>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
                <AuthHead title="Reset Password" description="You can login to the panel by creating your new password." />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" marginTop="30px">

            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap="10px" width="100%">
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Box flexDirection="column" alignItems="center" gap="10px" width="100%">
                        <BasicInputPassword
                            name="password"
                            type="password"
                            label="Password"
                            placeholder="Enter your password"
                            value={values?.password}
                            error={touched.password && errors.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <BasicInputPassword
                            name="verifyPassword"
                            type="password"
                            label="Verify Password"
                            placeholder="Reenter your password"
                            value={values?.verifyPassword}
                            error={touched.verifyPassword && errors.verifyPassword}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%">
                            <BlackButton
                                name="Reset Password"
                                isActive={values?.verifyPassword && values?.password && !errors?.verifyPassword && !errors?.password}
                                handleSubmit={handleSubmit}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems={'center'} width="100%" mt={"15px"} mb={"30px"}>
                            <Typography variant="h7" color="primary.300">
                                Do you have an account? <a href="/signin" style={{ textDecoration: 'none' }}><Typography variant="h7" color="info.main" ml={"5px"}>
                                    Sign In
                                </Typography>
                                </a>
                            </Typography>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Grid>
    </AuthLayout >
}

export default ResetPassword