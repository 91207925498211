
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Coin from 'assets/images/dashboard/coin.png';

import {
  FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField,
  Typography, Card, Autocomplete, Paper, Box, Divider, Stepper, StepLabel, Step, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { user_parametersFunc, companyAdmin_createInternship, user_internshipPriceFunc, companyAdmin_createIndefiniteInternship, self_organization_billingInfoFunc } from "context";
import { DashboardLayout } from "layouts";
import { MobileDatePicker as MDPicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from "moment";
import { RichTip, AnimatedTitle } from "components";
import debounce from 'lodash/debounce';
import {
  internshipAreaList, internshipApplicationTypeList, internshipCoffeeList, InternshipGoalForCompanyList, internshipFollowUpUserTypeList,
  internshipPreferredCollegeYearList, internshipPreferredGPAList, internshipSalaryTypeList, internshipTimePeriodList, getNumberOfInternshipTimePeriod,
  InternshipFollowUpUserType, InternshipTimePeriod, getMessageOfValidationError, internshipLanguageList, getNameOfInternshipTimePeriod
} from "utils";
import { BackButton } from "page-sections/dashboard/company/internship/_BackButton";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import PropTypes from 'prop-types';
import TermInternship from 'assets/icons/dashboard/term-internship.png';
import IndefiniteInternship from 'assets/icons/dashboard/indefinite-internship.png';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Iconify from "minimal/components/iconify/iconify";
const info = [
  "Faz 2 bitti",
  "tips için metinler bekleniyor - business"
];

const steps = ['Initial', 'Detail', 'Final'];

const CreateInternship = () => {

  const parser = new DOMParser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState("");
  const { accessToken } = useSelector((state) => state.user);
  const { user_internshipPriceData: internshipPrice } = useSelector((state) => state.user_internshipPrice)
  const { self_organization_billingInfoData: billingInfoData } = useSelector(state => state.self_organization_billingInfo)

  const { user_parametersData: parameters } = useSelector((state) => state.parameters);
  const { self_companyUserData: companyData } = useSelector((state) => state.self_companyUser);
  const [isPaid, setIsPaid] = useState(false);
  const [isIndefinite, setIsIndefinite] = useState(true);
  const [tipDescription, setTipDescription] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ipsum dolor sit amet, consectetur adipiscing elit.");
  const [editorKey, setEditorKey] = useState(0);
  const [activeStep, setActiveStep] = useState(0);


  const internshipInitialValues = useMemo(() => ({
    internshipType: "indefinite",
    applicationType: "",
    coffee: "No",
    description: "",
    followUpUserType: isIndefinite ? "internIFUser" : InternshipFollowUpUserType?.[0]?.name,
    internshipArea: "",
    internshipGoal: "",
    internshipLanguage: "",
    positionName: "",
    preferredCollegeYear: "",
    descriptionSummary: "",
    preferredGPA: "",
    preferredLanguages: [],
    preferredSkills: [],
    quota: isIndefinite ? 1 : null,
    salary: "",
    salaryType: "Volunteer",
    startDate: isIndefinite ? new Date(new Date().setFullYear(new Date().getFullYear() + 1)) : null,
    timePeriodInWeeks: InternshipTimePeriod?.[0]?.name,
  }), []);

  const internshipValidationSchema = Yup.object().shape({
    internshipType: Yup.string().required("Internship Type is required"),
    positionName: Yup.string().required("Position Name is required").max(50, "Position Name must be at most 50 characters"),
    internshipArea: Yup.string().required("Internship Area is required"),
    applicationType: Yup.string().required("Application Type is required"),
    quota: Yup.number().required("Quota is required").max(35, "Quota must be at most 35").min(1, "Quota must be at least 1"),
    followUpUserType: Yup.string().required("Follow Up Type is required"),

    startDate: Yup.date().required("Start Date is required"),
    timePeriodInWeeks: isIndefinite ? Yup.string().nullable() : Yup.string().required("Time Period is required"),
    internshipLanguage: Yup.string().required("Internship Language is required"),
    internshipGoal: Yup.string().required("Internship Goal is required"),
    description: Yup.string().required("Description is required"),

    coffee: Yup.string().required("Coffee is required"),
    salary: isPaid ? Yup.number().required("Salary is required") : Yup.number().nullable(),
    salaryType: Yup.string().required("Salary Type is required"),
    preferredCollegeYear: Yup.string().nullable(""),
    preferredGPA: Yup.string().nullable(""),
    preferredLanguages: Yup.array().nullable([]),
    preferredSkills: Yup.array().nullable([]),
  });


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm
  } = useFormik({
    enableReinitialize: true,
    initialValues: internshipInitialValues,
    validationSchema: internshipValidationSchema,
    onSubmit: (values) => {
      handleCreateInternship({ values });
    }
  });



  useEffect(() => {
    dispatch(
      user_internshipPriceFunc({
        followUpUserType: values.followUpUserType ?? "internIFUser",
        quota: Number(values.quota ?? 1),
        timePeriod: values.timePeriodInWeeks ?? InternshipTimePeriod.fourWeeks.value,
        coffee: values.coffee ?? "No",
        creationType: isIndefinite ? "indefinite" : "scheduled",
        accessToken
      })
    );
  }, [
    dispatch,
    accessToken,
    values.followUpUserType,
    values.quota,
    values.timePeriodInWeeks,
    values.coffee,
    isIndefinite
  ]);

  useEffect(() => {
    accessToken && dispatch(self_organization_billingInfoFunc({ accessToken }));
  }, [dispatch, accessToken]);



  const handleCreateInternship = async ({ values }) => {

    console.log(values, "values")
    setFieldValue("descriptionSummary", parser.parseFromString(values.description, 'text/html')?.body?.textContent?.slice(0, 100) + "...");

    const prefferedSkills = values.preferredSkills.map(item => (typeof item === 'object' ? item.name : item));
    const prefferedLanguages = values.preferredLanguages.map(item => (typeof item === 'object' ? item.value : item));


    try {
      const response = isIndefinite ? await companyAdmin_createIndefiniteInternship({
        values: values,
        preferredSkills: prefferedSkills,
        preferredLanguages: prefferedLanguages,
        accessToken: accessToken,
      })
        : await companyAdmin_createInternship({
          values: values,
          preferredSkills: prefferedSkills,
          preferredLanguages: prefferedLanguages,
          accessToken: accessToken,
        });

      if (response?.errorMessage || !response?._id) {
        const message = getMessageOfValidationError(response.errorMessage);
        if (message === "CompanyCreditIsNotSufficient") {
          enqueueSnackbar("Your credit is not sufficient. Please buy credits.", { variant: "error", });
          return;
        }
        if (message === "InsufficientStandardCredit") {
          enqueueSnackbar("You do not have enough credits for coffee!", { variant: "error", });
          return;
        }
        if (message === "CreditAmountToBeSpentCannotBeZero") {
          enqueueSnackbar("Your credit is not sufficient. Please buy credits.", { variant: "error", });
          return;
        }
        if (message === "InsufficientFreeCredit") {
          enqueueSnackbar("Your credit is not sufficient. Please buy credits.", { variant: "error", });
          return;
        }
        enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error", });
        return;
      }
      enqueueSnackbar("The internship has been created. You will be notified when the interns apply. ", { variant: "success" });
      navigate("/internship");
    } catch (error) {
      enqueueSnackbar("An error has been detected. Please try again soon!", { variant: "error" });
    }
  };

  function handleBackButtonClick() {
    navigate("/internship");
  }

  const debouncedSetValue = debounce((e) => {
    setFieldValue('description', e);
  }, 300);

  const handleButton = () => {
    if (activeStep === 0) {
      if (values.internshipType && values.positionName && values.internshipArea && values.timePeriodInWeeks && values.quota && values.followUpUserType) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    else if (activeStep === 1) {
      if (!isIndefinite && values.description.length > 7 && values.internshipGoal && values.internshipLanguage && values.startDate && values.applicationType) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (isIndefinite && values.description.length > 7 && values.internshipGoal && values.internshipLanguage) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    else if (activeStep === 2) {
      handleSubmit();
    }
  }


  useEffect(() => {
    if (values.salaryType === "Paid") {
      setIsPaid(true);
    } else {
      setIsPaid(false);
    }
  }, [values.salaryType]);

  useEffect(() => {
    if (values.internshipType === "scheduled") {
      setIsIndefinite(false);
      setFieldValue("internshipType", "scheduled");
      setFieldValue("followUpUserType", InternshipFollowUpUserType?.[0]?.name);
      setFieldValue("quota", null);
    }
    else {
      setIsIndefinite(true);
      resetForm();
    }
  }, [values.internshipType, resetForm, setFieldValue]);

  let descriptonCharacterCount = values.description?.replace(/<[^>]*>/g, '').replace(/\s/g, '')?.length ?? 0;

  return <DashboardLayout info={info}>
    <Button
      style={{ position: 'absolute', top: 30, left: 20 }}
      onClick={() => {
        navigate("/internship")
      }}
    >
      <ChevronLeft /> Back
    </Button>
    <Grid
      container
      spacing={{ xs: 0, md: 3 }}
      gap={{ xs: 3, md: 0 }}
      sx={{
        py: { xs: 2, sm: 5 },
        pl: { xs: 0, md: 5 },
        pr: { xs: 0 },
        mb: 5,
        mr: 0,
        width: "100%",
      }}
    >
      <Grid item xs={12} >
        <AnimatedTitle title="Create Internship" />
      </Grid>

      <Grid item xs={12} md={8} xl={9} >

        <Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step key={index} onClick={() => { activeStep >= index && setActiveStep(index) }} sx={{ cursor: 'pointer' }} >
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Card sx={{ p: 4, mt: 2, minHeight: '550px' }}>
          {activeStep === 0 ?
            <Grid container spacing={4} >
              <Grid item xs={12} sx={{ width: '100%' }}>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={2} my={2}>
                  <Typography textAlign={"center"} fontWeight={"regular"} fontSize={"0.875rem"} color={"#637381"}>
                    Internship Type* :
                  </Typography>
                  <Card sx={{
                    width: '30%', py: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1,
                    '&:hover': { cursor: 'pointer', boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)" },
                    border: isIndefinite ? "1px solid #FF9737" : null
                  }}
                    onClick={() => setFieldValue("internshipType", "indefinite")} >
                    <img src={IndefiniteInternship} alt="Indefinite Internship" width={40} height={40} />
                    <Typography variant="h7" textAlign={"center"}>Indefinite Internship</Typography>
                  </Card>
                  <Card sx={{
                    width: '30%', py: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1,
                    '&:hover': { cursor: 'pointer', boxShadow: "0px 0px 30px rgba(30, 30, 30, 0.1)" },
                    border: !isIndefinite ? "1px solid #FF9737" : null
                  }}
                    onClick={() => setFieldValue("internshipType", "scheduled")} >
                    <img src={TermInternship} alt="Term Internship" width={40} height={40} />
                    <Typography variant="h7" textAlign={"center"}>Scheduled Internship</Typography>
                  </Card>

                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="positionName"
                  label="Position Name*"
                  value={values?.positionName}
                  onChange={(e => {
                    const enteredValue = e.target.value;
                    const words = enteredValue.split(' ');
                    const capitalizedValue = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                    setFieldValue("positionName", capitalizedValue);
                  })}
                  onBlur={() => setTipDescription("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ipsum dolor sit amet, consectetur adipiscing elit.")}
                  onFocus={() => setTipDescription("Position name detail")}
                  onAbort={() => setTipDescription("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ipsum dolor sit amet, consectetur adipiscing elit.")}
                  error={showErrors === 0 && touched.positionName && errors.positionName}
                  helperText={showErrors === 0 && touched.positionName && errors.positionName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth >
                  <InputLabel id="internshipArea">Internship Area*</InputLabel>
                  <Select
                    name="internshipArea"
                    labelId="internshipArea"
                    onFocus={() => setTipDescription("Internship area detail")}
                    onBlur={() => setTipDescription("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ipsum dolor sit amet, consectetur adipiscing elit.")}
                    label="Internship Area*"
                    value={values.internshipArea}
                    error={showErrors === 0 && !!errors.internshipArea}
                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                    onChange={handleChange}>
                    {internshipAreaList.map((type) => <MenuItem value={type.value} key={type.value}>
                      {type.name}
                    </MenuItem>)}
                  </Select>
                  {errors.internshipArea && showErrors === 0 && <FormHelperText sx={{ color: '#961650' }}>{errors.internshipArea}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="timePeriodInWeeks">Time Period*</InputLabel>
                  <Select
                    error={showErrors === 1 && !!errors.timePeriodInWeeks}
                    name="timePeriodInWeeks"
                    labelId="timePeriodInWeeks"
                    label="Time Period"
                    value={values.timePeriodInWeeks}
                    onChange={handleChange}
                  >
                    {internshipTimePeriodList.map((type) => (
                      <MenuItem value={type.value} key={type.value}>
                        {type.name
                          .split(" ")
                          .map(
                            (name) => name[0].toUpperCase() + name.substring(1)
                          )
                          .join(" ")}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.timePeriodInWeeks && showErrors === 0 && <FormHelperText sx={{ color: '#961650' }}>{errors.timePeriodInWeeks}</FormHelperText>}
                </FormControl>
              </Grid>
              {!isIndefinite && <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="followUpUserType">Follow Up Type*</InputLabel>
                  <Select
                    error={showErrors === 0 && !!errors.followUpUserType}
                    name="followUpUserType"
                    labelId="followUpUserType"
                    label="Follow Up Type*"
                    value={values.followUpUserType}
                    MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                    onChange={handleChange}
                  >
                    {internshipFollowUpUserTypeList.map((type) => (
                      <MenuItem value={type.value} key={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.followUpUserType && showErrors === 0 && <FormHelperText sx={{ color: '#961650' }}>{errors.followUpUserType}</FormHelperText>}
                </FormControl>
              </Grid>}
              {!isIndefinite && <Grid item xs={12} md={6}>
                <TextField
                  name="quota"
                  label="Quota*"
                  type="number"
                  value={values?.quota}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.quota && errors.quota}
                  helperText={touched.quota && errors.quota}
                  fullWidth
                />
              </Grid>}



            </Grid> :
            activeStep === 1 ?
              <Grid container spacing={3} >
                {values?.internshipType === "scheduled" && <Grid item xs={12} md={6}>
                  <MDPicker
                    sx={{ width: '100%', border: errors.startDate && showErrors === 1 && "1px solid #961650", borderRadius: "10px" }}
                    label="Start Date*"
                    value={values.startDate ? new Date(values.startDate) : null}
                    onChange={(newValue) => setFieldValue("startDate", newValue)}
                    name="startDate"
                    format="dd MMM yyyy"
                    minDate={new Date("2024-05-06")}
                  />
                  {errors.startDate && showErrors === 1 && <Typography sx={{ fontFamily: 'Causten-Medium', margin: '8px 14px 0px', fontSize: '12px', color: '#961650' }}>{errors.startDate}</Typography>}
                </Grid>}
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="applicationType">Application Type*</InputLabel>
                    <Select
                      error={showErrors === 0 && !!errors.applicationType}
                      name="applicationType"
                      labelId="applicationType"
                      label="Application Type"
                      value={values.applicationType}
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                      onChange={handleChange}
                    >
                      {internshipApplicationTypeList.map((type) => (
                        <MenuItem value={type.value}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.applicationType && showErrors === 1 && <FormHelperText sx={{ color: '#961650' }}>{errors.applicationType}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="internshipLanguage">Internship Language*</InputLabel>
                    <Select
                      error={showErrors === 1 && !!errors.internshipLanguage}
                      name="internshipLanguage"
                      labelId="internshipLanguage"
                      label="Internship Language*"
                      value={values.internshipLanguage}
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                      onChange={handleChange}
                    >
                      {values?.applicationType === "global" ?
                        <MenuItem value={"en"}>
                          English
                        </MenuItem>
                        : internshipLanguageList.map((type) => (
                          <MenuItem value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {errors.internshipLanguage && showErrors === 1 && <FormHelperText sx={{ color: '#961650' }}>{errors.internshipLanguage}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="internshipGoal">Internship Goal*</InputLabel>
                    <Select
                      error={showErrors === 1 && !!errors.internshipGoal}
                      name="internshipGoal"
                      labelId="internshipGoal"
                      label="Internship Goal"
                      value={values.internshipGoal}
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                      onChange={handleChange}
                    >
                      {InternshipGoalForCompanyList.map((type) => (
                        <MenuItem value={type.value}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.internshipGoal && showErrors === 1 && <FormHelperText sx={{ color: '#961650' }}>{errors.internshipGoal}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} style={{ position: 'relative' }}>
                  <Box style={{ position: 'absolute', left: "34px", top: "15px", backgroundColor: 'white', zIndex: 100, paddingLeft: "5px", paddingRight: "5px" }}>
                    <Typography style={{
                      lineHeight: 1.5, fontSize: "12px", fontFamily: "Causten-Medium,sans-serif",
                      fontWeight: "600", color: "#637381",
                    }}      > Description*</Typography>
                  </Box>
                  <Typography variant="body2" sx={{ color: "#637381", fontSize: "12px", position: 'absolute', bottom: 3, right: 25 }}>
                    {descriptonCharacterCount} - {descriptonCharacterCount < 300 ? ' (min 300)' : '(max 1500)'}
                  </Typography>
                  <RichTip
                    label="Description*"
                    value={values.description}
                    setValue={debouncedSetValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    key={editorKey}
                    name="description"
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    type="description"
                    handleFocus={() => null}
                  />
                </Grid>
              </Grid> :
              activeStep === 2 ?
                <Grid container spacing={3} >
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="coffee">Coffee per intern*</InputLabel>
                      <Select
                        error={showErrors === 2 && errors.coffee}
                        name="coffee"
                        labelId="coffee"
                        label="Coffee per intern*"
                        value={values.coffee}
                        onChange={handleChange}
                      >
                        {internshipCoffeeList.map((type) => (
                          <MenuItem value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.coffee && showErrors === 2 && <FormHelperText sx={{ color: '#961650' }}>{errors.coffee}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={isPaid ? 6 : 12} md={isPaid ? 3 : 6} >
                    <FormControl fullWidth>
                      <InputLabel id="salaryType">Salary Type*</InputLabel>
                      <Select
                        error={showErrors === 2 && !!errors.salaryType}
                        name="salaryType"
                        labelId="salaryType"
                        label="Salary Type*"
                        value={values.salaryType}
                        onChange={handleChange}
                      >
                        {internshipSalaryTypeList.map((type) => (
                          <MenuItem value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.salaryType && showErrors === 2 && <FormHelperText sx={{ color: '#961650' }}>{errors.salaryType}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  {isPaid && <Grid item xs={6} md={3}>
                    <TextField
                      name="salary"
                      label="Salary ($)"
                      value={values?.salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={showErrors === 2 && Boolean(touched.salary && errors.salary)}
                      helperText={touched.salary && errors.salary}
                      fullWidth
                      rows={5}
                    />
                  </Grid>}

                  <Grid item xs={12} display={"flex"} alignItems={"center"} mt={2} >
                    <Typography variant="h8" >Preferences</Typography> <Divider sx={{ width: '100%', ml: 2 }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="preferredCollegeYear">
                        College Year*
                      </InputLabel>
                      <Select
                        error={showErrors === 2 && errors.preferredCollegeYear}
                        name="preferredCollegeYear"
                        labelId="preferredCollegeYear"
                        label="College Year*"
                        value={values.preferredCollegeYear}
                        onChange={handleChange}
                      >
                        {internshipPreferredCollegeYearList.map((type) => (
                          <MenuItem value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.preferredCollegeYear && showErrors === 2 && <FormHelperText sx={{ color: '#961650' }}>{errors.preferredCollegeYear}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="preferredGPA">GPA*</InputLabel>
                      <Select
                        error={showErrors === 2 && errors.preferredGPA}
                        name="preferredGPA"
                        labelId="preferredGPA"
                        label="GPA*"
                        value={values.preferredGPA}
                        onChange={handleChange}
                      >
                        {internshipPreferredGPAList.map((type) => (
                          <MenuItem value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.preferredGPA && showErrors === 2 && <FormHelperText sx={{ color: '#961650' }}>{errors.preferredGPA}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple
                      key={moment().valueOf()}
                      options={internshipLanguageList ?? []}
                      value={values.preferredLanguages}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }

                      onChange={(event, value) => { setFieldValue("preferredLanguages", value) }}
                      renderInput={(params) => <TextField {...params} label="Preferred Languages" />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      freeSolo
                      multiple
                      autoSelect
                      key={moment().valueOf()}
                      options={parameters?.skills?.data ?? []}
                      value={values.preferredSkills}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      PaperComponent={({ children }) => (
                        <Paper style={{ maxHeight: 180, overflow: 'hidden' }}>
                          {children}
                        </Paper>
                      )}
                      getOptionLabel={(option) => typeof option === 'object' ? option.name : option}
                      onChange={(event, value) => { setFieldValue("preferredSkills", value) }}
                      renderInput={(params) => <TextField {...params} label="Preferred Skills" />}
                    />
                  </Grid>
                </Grid> :
                null

          }
        </Card>
      </Grid>

      <Grid item xs={12} md={4} xl={3} >
        <Card sx={{ padding: 3, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box backgroundColor={"#F5F5F5"} borderRadius={2} p={1} mb={2} height={"100%"} >
            <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >Tips</Typography>
            <Typography variant="body1" fontSize={"small"} fontWeight={"regular"}>
              {tipDescription}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box sx={{ display: 'flex', flexDirection: "column" }}>
              {/* base intern management coffee */}
              <Typography fontSize={"small"} color={"gray"}>Base: </Typography>
              <Typography fontSize={"small"} color={"gray"}>Intern: </Typography>
              <Typography fontSize={"small"} color={"gray"}>Management: </Typography>
              <Typography fontSize={"small"} color={"gray"}>Coffee: </Typography>

              {/* {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{values.quota} Intern{values.quota > 1 && 's'} : </Typography> :
                <Typography fontSize={"small"} color={"gray"}>- Intern : </Typography>}
              {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{getNameOfInternshipTimePeriod(values.timePeriodInWeeks)} : </Typography> :
                <Typography fontSize={"small"} color={"gray"}>- Week : </Typography>}
              <Typography variant="h7">Price : </Typography><Typography variant="h7">Discount : </Typography> */}
              <Divider sx={{ width: '1500%', m: 1 }} />
              <Typography variant="h6">Net Price : </Typography>
              <Typography fontSize={'small'} textAlign={"left"} color={"GrayText"} fontWeight={'bold'} width={'100%'} alignSelf={"flex-start"} >
                My IF Credits:
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: "column", ml: 1, alignItems: 'flex-end' }}>


              <Typography fontSize={"small"} color={"gray"}>{!!(values.timePeriodInWeeks && values.quota && values.followUpUserType) ? internshipPrice?.cost?.baseCost + " credits" : "Waiting"}</Typography>
              <Typography fontSize={"small"} color={"gray"}>{!!(values.timePeriodInWeeks && values.quota && values.followUpUserType) ? internshipPrice?.cost?.internCost + " credits" : "Waiting"}</Typography>
              <Typography fontSize={"small"} color={"gray"}>{!!(values.timePeriodInWeeks && values.quota && values.followUpUserType) ? internshipPrice?.cost?.managementCost + " credits" : "Waiting"}</Typography>
              <Typography fontSize={"small"} color={"gray"}>{!!(values.timePeriodInWeeks && values.quota && values.followUpUserType) ? internshipPrice?.cost?.coffeeCost + " credits" : "Waiting"} </Typography>




              {/* {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{values.quota * 5} credits </Typography> :
                <Typography fontSize={"small"} color={"gray"}>Waiting</Typography>}
              {(values.followUpUserType && values.quota && values.timePeriodInWeeks) ? <Typography fontSize={"small"} color={"gray"}>{getNumberOfInternshipTimePeriod(values.timePeriodInWeeks) * 5} credits </Typography> :
                <Typography fontSize={"small"} color={"gray"}>Waiting</Typography>}
              <Typography variant="h7"> {internshipPrice?.price ?? 0} credits</Typography>
              <Typography variant="h7"> {internshipPrice?.discount ?? 0} credits</Typography> */}
              <Box sx={{ m: 1 }} />
              <Typography variant="h6"> {internshipPrice?.netPrice === 0 ? "" : internshipPrice?.netPrice} {internshipPrice?.netPrice === 0 ? "~ Waiting" : " credits"}</Typography>
              <Typography fontSize={'small'} color={"GrayText"} fontWeight={'bold'} >
                {((billingInfoData?.credit?.credit?.usable + billingInfoData?.credit?.freeCredit?.usable).toFixed(2)) || 0} <img src={Coin} alt="Coin" style={{ width: 15, height: 15, marginLeft: 5, marginBottom: 4 }} />
              </Typography>
            </Box>
          </Box>
          <Box width={'100%'} >
            {billingInfoData?.credit?.credit?.usable + billingInfoData?.credit?.freeCredit?.usable < internshipPrice?.netPrice && <Typography fontSize={'12px'} color={"error"} pt={1}>
              Your credit is not sufficient. <Typography fontSize={'12px'} color={"error"} pt={1} onClick={() => navigate("/if-credit")} sx={{ cursor: 'pointer', color: "error", display: "inline", fontWeight: 'bold' }}>Buy credits</Typography>
            </Typography>}
            <LoadingButton sx={{ width: '100%', mt: 2 }} variant="contained" size="large"
              disabled={activeStep === 0 ? !(values.internshipType && values.positionName && values.internshipArea && values.timePeriodInWeeks && values.quota && values.followUpUserType) :

                activeStep === 1 ? !(!isIndefinite ? descriptonCharacterCount > 299 && values.internshipGoal && values.internshipLanguage && values.startDate && values.applicationType :

                  isIndefinite ? values.description && values.internshipGoal && values.internshipLanguage : null)
                  : (billingInfoData?.credit?.credit?.usable + billingInfoData?.credit?.freeCredit?.usable) < internshipPrice?.netPrice}
              onClick={() => {
                setShowErrors(activeStep === 0 ? 0 : activeStep === 1 ? 1 : 2);
                handleButton();
              }}>
              {activeStep !== 2 ? 'Next Step' : 'Create Internship'}
            </LoadingButton>
          </Box>

        </Card>
      </Grid>
    </Grid >

    <Dialog open={companyData?.company?.companyInformation?.detailedDescription?.length < 8} onClose={() => null} fullWidth >
      <DialogTitle>
        Missing Company Information
      </DialogTitle>
      <DialogContent  >
        We noticed that you did not complete your company information. Please complete your company information first.
      </DialogContent>
      <DialogActions  >
        <Button variant="outlined" onClick={() => null} >
          Cancel
        </Button>
        <Button variant="contained" onClick={() => null} >
          Save
        </Button>
      </DialogActions>
    </Dialog>

  </DashboardLayout >
};

export default CreateInternship;


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(255,151,55) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(255,151,55) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[400],
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(255,101,55) 0%, rgb(255,151,55) 50%, rgb(255,101,55) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(242,113,33) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <VideoLabelIcon />,
    2: <Iconify icon="bx:detail" width={23} />,
    3: <Iconify icon="charm:square-tick" width={23} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};
