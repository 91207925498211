import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Typography } from '@mui/material'
import { GoogleAuthIcon } from '../../assets'
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { googleRegisterFunc } from '../../context/auth/googleRegister';
import { googleLoginFunc } from 'context';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { setAccessToken, setUserRole, setUserId, setEmail } from 'context/common/user';

export const delay = ms => new Promise((res) => setTimeout(res, ms));

export const GoogleButton = ({ type, role }) => {

    const dispatch = useDispatch();
    const downXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [isSignedIn, setIsSignedIn] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);





    const signin = useGoogleLogin({
        scope: "profile email",
        flow: 'auth-code',
        onSuccess: async (response) => {
            console.log(response)
            setLoading(true)
            try {
                const data = await dispatch(googleLoginFunc({ token: response.code }));
                console.log(data, "data")
                if (data.payload.isSuccess) {
                    const userId = data.payload?.data?.user?.userId;
                    const role = data.payload?.data?.user?.role;
                    const token = data.payload?.data?.accessToken;
                    const email = data.payload?.data?.user?.email;

                    if (role === 'companyAdmin' || role === 'intern') {
                        localStorage.setItem('accessToken', token);
                        localStorage.setItem('userRole', role);
                        localStorage.setItem('userId', userId);
                        localStorage.setItem('email', email);
                        dispatch(setAccessToken(token));
                        dispatch(setUserRole(role));
                        dispatch(setUserId(userId));
                        dispatch(setEmail(email));
                        setLoading(false)
                        navigate('/');
                        enqueueSnackbar('Welcome to InternIF!', { variant: 'success' })
                    }
                    if (role === 'b2bSalesRep' || role === 'b2bCompanyRep' || role === 'b2bInternRep' || role === 'b2bClient') {
                        enqueueSnackbar("You need to login from the B2B Website!", { variant: 'warning', autoHideDuration: 2500 })
                        setTimeout(() => { window.location.href = 'https://biz.internif.com' }, 2500)
                        localStorage.clear()
                    }
                    if (role === 'internIFManager' || role === 'internIFFinance') {
                        enqueueSnackbar("You need to login from the Management Website!", { variant: 'warning', autoHideDuration: 2500 })
                        setTimeout(() => { window.location.href = 'https://manage.internif.com' }, 2500)
                        localStorage.clear()
                    }
                    if (role === 'universityAdmin') {
                        enqueueSnackbar("You need to login from the University Website!", { variant: 'warning', autoHideDuration: 2500 })
                        setTimeout(() => { window.location.href = 'https://uni.internif.com' }, 2500)
                        localStorage.clear()
                    }
                } else {
                    enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
                setLoading(false)
            }
        }
    });



    const signup = useGoogleLogin({

        scope: "profile email",
        flow: 'auth-code',
        onSuccess: async (response) => {
            console.log(response)
            setLoading(true)
            try {
                const data = await dispatch(googleRegisterFunc({ token: response.code, role: role }));
                console.log(data, "data")
                await delay(5000);
                
                if (data.payload.isSuccess) {
                    
                    enqueueSnackbar('Registration Successful. You can log in now.', { variant: 'success' });

                    setLoading(false)
                    navigate('/signin')
                } else if (data.payload?.response?.data?.message === "AlreadyRegistered") {
                    enqueueSnackbar('You are already registered. Please login.', { variant: 'warning' });
                    setLoading(false)
                } else {
                    enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
                enqueueSnackbar("An error has been detected. Please try again soon!", { variant: 'error' });
                setLoading(false)
            }
        },
    });



    return <Button
        onClick={() => {
            type === "signup" && signup();
            type === "signin" && signin();
        }}

        sx={{
            width: '100%',
            background: "#fff",
            borderRadius: '12px',
            color: '#0E1436',
            textTransform: 'none',
            border: "1px solid #D1D5D8",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            height: '48px',
            ':hover': {
                background: "#fff",
            }
        }}
        disabled={loading}
    >
        <img src={GoogleAuthIcon} alt="" width="32" height="32" />
        <Typography variant='h6' textAlign={'center'}>
            {downXs ? "Continue with Google" : 'Continue with Google'}
        </Typography>
        {loading && <CircularProgress size={"1rem"} />}
    </Button>
}
