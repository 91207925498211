import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialConfig from "../../../initialConfig";


const initialState = {
  intern_internshipData: null,
  intern_internshipLoading: false,
  intern_internshipError: null,
};

export const intern_internshipFunc = createAsyncThunk("intern_internship/intern_internshipFunc", async (values) => {

  const data1 = JSON.stringify({
    query: `
        query Intern_internship($internshipId: ID!) {
            intern_internship(internshipId: $internshipId) {
              _id
              application {
                _id
                status
              }
              applicationType
              descriptionSummary
              viewCount
              coffee
              company {
                _id
                city {
                  name
                  _id
                }
                country {
                  flagUrl
                  name
                  code
                  _id
                }
                displayImageUrl
                displayName
                industry {
                  name
                  _id
                }
                numberOfEmployee {
                  name
                  _id
                }
              
              }
              createdAt
              description
              descriptionSummary
              dueDate
              internshipArea
              internshipGoal
              isSaved
              numberOfAcceptedInterns
              numberOfAppliedInterns
              positionName
              preferredCollegeYear
              preferredGPA
              preferredLanguages
              preferredSkills
              quota
              salary
              salaryType
              startDate
              status
              timePeriodInWeeks
              updatedAt
            }
          }
        `,
    variables: {
      "internshipId": values?.internshipId,
    }

  });


  const config = initialConfig(process.env.REACT_APP_BASE_URL + '/internship/graphql', data1, values?.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const intern_internship = createSlice({
  name: "intern_internship",
  initialState,
  reducers: {
    intern_setInternshipNull: (state, action) => {
      state.intern_internshipData = null
      state.intern_internshipLoading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(intern_internshipFunc.pending, (state) => {
      state.intern_internshipLoading = true;
    });
    builder.addCase(intern_internshipFunc.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.intern_internshipLoading = false;
      state.intern_internshipData = action.payload?.intern_internship;
      state.onboardedCompany = action.payload?.intern_internship?.data;
      state.intern_internshipError = action.payload.message;
    });
    builder.addCase(intern_internshipFunc.rejected, (state, action) => {
      state.intern_internshipLoading = false;
      state.intern_internshipError = action.error.message;
    });
  }
});

export const { intern_setInternshipNull } = intern_internship.actions

export default intern_internship.reducer;
