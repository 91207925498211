import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography, useMediaQuery, Card, Divider, Button, Stack } from '@mui/material'
import { AnimatedTitle } from 'components'
import { DashboardLayout } from 'layouts'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { East } from '@mui/icons-material'
import Iconify from 'minimal/components/iconify'
import { intern_applicationsFunc, intern_packagesFunc } from 'context'


const MyInternships = () => {


    const dispatch = useDispatch()
    const { userRole } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const upLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const bottomRef = useRef(null);
    const { intern_applicationsData: applications } = useSelector(state => state.intern_applications)
    const { intern_packagesCount: packagesCount } = useSelector(state => state.intern_packages)
    const { accessToken } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(intern_applicationsFunc({ accessToken }))
        dispatch(intern_packagesFunc({ accessToken }))
    }, [])

    return <DashboardLayout >

        <Grid container spacing={{ xs: 0, sm: 3 }} sx={{ p: { xs: 0, sm: 5 }, width: '100%', }}>
            <Grid item xs={12} >
                <AnimatedTitle title={`${t("My Internships")}`} />
            </Grid>

            <Grid item xs={12} lg={8} xl={9} mt={2} >
                <Grid container spacing={2} mb={5} >
                    <Grid item xs={4} sx={{ cursor: 'pointer' }} >
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="pajamas:work" width="150px" />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                                <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="pajamas:work" fontSize="30px" />
                                </Box>
                                <Typography fontSize={"18px"} fontWeight={"bold"} >
                                    {t("My Internships")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} >
                                0
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} onClick={() => navigate('/applications')} sx={{ cursor: 'pointer' }}>
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="jam:task-list" width="150px" />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                                <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="jam:task-list" fontSize="30px" />
                                </Box>
                                <Typography fontSize={"18px"} fontWeight={"bold"} >
                                    {t("My Applications")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} >
                                {applications?.count}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} onClick={() => navigate('/premium')} sx={{ cursor: 'pointer' }}>
                        <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', height: '100%', border: '1px solid #E0E0E0', borderRadius: 1, padding: 2 }}>
                            <Box sx={{ position: 'absolute', bottom: "-50px", right: "-30px", opacity: '0.1' }}>
                                <Iconify icon="solar:crown-linear" width="150px" />
                            </Box>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                                <Box p={1} bgcolor={"secondary.main"} color={"white"} borderRadius={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Iconify icon="solar:crown-linear" fontSize="30px" />
                                </Box>
                                <Typography fontSize={"18px"} fontWeight={"bold"} >
                                    {t("My Premium Packages")}
                                </Typography>
                            </Stack>
                            <Typography fontSize={"30px"} fontWeight={"bold"} mt={1} >
                                {packagesCount}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>


                <Typography variant="h6" fontWeight={"regular"} mb={1} >
                    {t("You do not have an active internship yet.")}<br /><br />
                </Typography>
                {userRole !== "highSchoolIntern" && <Typography variant="h6" fontWeight={"regular"} mb={2}>
                    <East sx={{ mb: -0.8, mr: 1 }} /> {t("Apply for internships on the ")}<strong><Link to="/internship" style={{ color: "#ff9737", textDecoration: 'underline', textDecorationColor: 'black', borderRadius: 10, padding: 5 }} >{t("internship search page.")}</Link></strong>
                </Typography>}
                <Typography variant="h6" fontWeight={"regular"} mb={2}>
                    <East sx={{ mb: -0.8, mr: 1 }} /> {t("Purchase an instant internship package from the ")}<strong>
                        <Link to='/premium' style={{ color: "#ff9737", textDecoration: 'underline', textDecorationColor: 'black', borderRadius: 10, padding: 5 }} >{t("premium page.")}</Link></strong>
                </Typography>
            </Grid>

            {upLg && <Grid item xs={12} lg={4} xl={3} mt={2}>
                <Card sx={{
                    padding: 3, backgroundColor: "#F8F8F8", border: '1px solid #E0E0E0',
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <Box backgroundColor={"#F8F8F8"} borderRadius={2} mb={2} height={"100%"} >
                        <Typography variant="h6" fontWeight={"regular"} textAlign={"center"} mb={1} >
                            <strong> {t("Tips")}</strong></Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body1" fontSize={"small"} fontWeight={"regular"} mb={2}>
                            🌟    {t("What kind of screen will you encounter when your internship starts?")}
                        </Typography>
                        <Button variant='outlined' color='primary' size='large' fullWidth
                            onClick={() => navigate('/my-internship')}
                        >{t("Demo Internship")}</Button>
                    </Box>
                </Card>
            </Grid>}
        </Grid>
        <div ref={bottomRef}></div>
    </DashboardLayout>
}

export default MyInternships